import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { ORDER_BATCH_UPLOAD_DIALOG } from "@/constants/constants";
import { closeDialog } from "@/store/reducers/dialogReducer";
import { clearOrder } from "@/store/reducers/orderReducer";
import { Button } from "./ui/button";
import { ISupplier } from "@/interfaces/ISupplier";
import { useState } from "react";
import { toast } from "./ui/use-toast";
import LoaderButton from "./LoaderButton";
import { uploadOrderCanvas } from "@/services/OrderCanvasUploadService";
import { DownloadFile } from "@/lib/utils";
import { order_supplier_canvas_url } from "@/constants/urls";
import { IOrderHeader } from "@/interfaces/IOrder";
import { useNavigate } from "react-router-dom";

interface OrderBatchUploadDialogProps {
  supplier: ISupplier;
  orderHeader: IOrderHeader;
}

const OrderBatchUploadDialog: React.FC<OrderBatchUploadDialogProps> = ({
  supplier,
  orderHeader,
}) => {
  const dispatch = useDispatch();
  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialogReducer
  );
  const navigation = useNavigate();
  const isOpened = isOpen && dialogType === ORDER_BATCH_UPLOAD_DIALOG;
  const [orderCanvas, setOrderCanvas] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadFile = async () => {
    setIsDownloading(true);
    const response = await DownloadFile(
      order_supplier_canvas_url + supplier.id,
      "Canvas commande " + supplier.label + ".xlsx"
    );
    if (response.status === 200) {
      setIsDownloading(false);
      toast({
        title: "Succès",
        description: response?.data?.message,
        variant: "success",
      });
    } else {
      setIsDownloading(false);
      toast({
        title: "Erreur",
        description:
          "Une erreur s'est produite lors du téléchargement du fichier",
        variant: "destructive",
      });
    }
  };

  const handleUpload = async (file: File) => {
    setIsUploading(true);

    const response = await uploadOrderCanvas(file, orderHeader.id.toString());

    if (response.status === 200) {
      toast({
        title: "Succès",
        description: response.data.message,
        variant: "success",
      });
      setIsUploading(false);
      setOrderCanvas(null);
      navigation(`/orders/${orderHeader.id}/${orderHeader.supplierId}`);

      dispatch(closeDialog());
    } else {
      toast({
        title: "Erreur",
        description: response.data.message,
        variant: "destructive",
      });
    }
    setIsUploading(false);
  };

  const handleClose = () => {
    dispatch(closeDialog());
    dispatch(clearOrder());
  };

  const handleCancel = () => {
    dispatch(closeDialog());
  };

  return (
    <Dialog open={isOpened} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Commande en masse</DialogTitle>
        </DialogHeader>

        <div className=" relative">
          <Button
            variant="outline"
            className="w-full justify-start hover:bg-white"
            onClick={() => {
              const input = document.createElement("input");
              input.type = "file";
              input.accept = ".xlsx";
              input.onchange = async (e) => {
                const target = e.target as HTMLInputElement;
                if (target.files && target.files.length > 0) {
                  const file = target.files[0];
                  setOrderCanvas(file);
                }
              };
              input.click();
            }}
            disabled={isUploading}
          >
            <div className="flex items-center gap-2">
              <span className="text-sm">
                {orderCanvas?.name || "Importer un fichier"}
              </span>
              <span className="text-xs text-gray-500">.xlsx</span>
            </div>
          </Button>
        </div>

        <div className="flex  flex-1 justify-between mt-4">
          <div className="col-span-3 flex items-center justify-start gap-2">
            <LoaderButton
              handleSubmit={handleDownloadFile}
              isLoading={isDownloading}
              name="Télécharger le modèle"
              outline
            />
          </div>
          <div className="flex gap-2">
            <Button onClick={handleCancel} variant="outline">
              Annuler
            </Button>
            <LoaderButton
              handleSubmit={() => handleUpload(orderCanvas!)}
              isLoading={isUploading}
              name="Importer"
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default OrderBatchUploadDialog;
