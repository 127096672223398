import axios, { AxiosResponse } from "axios";
import { list_stock_url } from "@/constants/urls";
import { IStockResponse } from "@/interfaces/IStock";

const accessToken = sessionStorage.getItem("token");

export async function getStocks(
  keyword: string,
  pageNumber: number,
  pageSize: number,
  supplierId: string | null
): Promise<IStockResponse> {
  try {
    const response: AxiosResponse<IStockResponse> = await axios({
      url: list_stock_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        keyword: keyword,
        pageNumber: pageNumber,
        pageSize: pageSize,
        orderBy: [],
        supplierId: supplierId,
      },
      method: "POST",
    });
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
}
