import { ISupplier } from "@/interfaces/ISupplier";
import React, { useState } from "react";
import { TableCell, TableRow } from "./ui/table";
import { useDispatch, useSelector } from "react-redux";
import { setSupplier } from "@/store/reducers/supplierReducer";
import { useNavigate } from "react-router-dom";
import { RootState } from "@/store/store";
import Highlighter from "react-highlight-words";
import SupplierStatusTag from "./SupplierStatusTag";

interface SupplierProps {
  supplier: ISupplier;
  forOrderCreation?: boolean;
}

const Supplier: React.FC<SupplierProps> = ({ supplier, forOrderCreation }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [products, setProducts] = useState<ISupplierProduct[]>([]);
  // const [openc, setOpenc] = useState(false);
  const [selected, setSelected] = useState(false);
  const searchKeyword = useSelector(
    (state: RootState) => state.searchReduer.searchText
  );
  {
    /*
  useEffect(() => {
    fetchProducts();
  }, []);
  const fetchProducts = async () => {
    const response = await getSupplierProducts(supplier.id);
    if (response) {
      console.log("response", response);
      setProducts(response.data);
    }
  };
  */
  }

  const handleSupplierClick = () => {
    if (!forOrderCreation) {
      console.log("supplier supplier", supplier);
      dispatch(setSupplier(supplier));
      navigate(`/supplier/${supplier.id}`);
    } else {
      setSelected(!selected);
      dispatch(setSupplier(supplier));
    }
  };

  return (
    <>
      <TableRow
        onClick={handleSupplierClick}
        className={`cursor-pointer hover:bg-gray-100 ${
          selected ? "bg-gray-100" : ""
        }`}
      >
        <TableCell className="sm:table-cell font-semibold">
          <Highlighter
            highlightClassName="bg-yellow-200 dark:bg-yellow-500"
            searchWords={[searchKeyword]}
            autoEscape={true}
            textToHighlight={supplier.providerCode.toString()}
          />
        </TableCell>
        <TableCell className=" sm:table-cell font-semibold">
          <Highlighter
            highlightClassName="bg-yellow-200 dark:bg-yellow-500"
            searchWords={[searchKeyword]}
            autoEscape={true}
            textToHighlight={supplier.label}
          />
        </TableCell>
        <TableCell className="hidden md:table-cell line ">
          {supplier.socialreason}
        </TableCell>
        <TableCell className="hidden 2xl:table-cell">
          {" "}
          {supplier.firstName}
        </TableCell>
        <TableCell className="hidden 2xl:table-cell">
          {supplier.lastName}
        </TableCell>
        <TableCell className="hidden md:table-cell">{supplier.ice}</TableCell>

        <TableCell className=" hidden md:table-cell font-semibold text-center">
          {supplier.productCount}
        </TableCell>
        <TableCell className="sm:table-cell text-center justify-center">
          <SupplierStatusTag status={supplier.valid} />
        </TableCell>
      </TableRow>
      {/*}
      <ProductListDialog
        products={products}
        isOpen={openc}
        setOpen={setOpenc}
      />
    */}
    </>
  );
};

export default Supplier;
