import axios, { AxiosResponse } from "axios";
import { update_assigned_product_price } from "@/constants/urls";
import { IAssignementItem_ } from "@/interfaces/IProduct";

const accessToken = sessionStorage.getItem("token");

export async function updateProductPrices(
  supplierId: number,
  assignements: IAssignementItem_[]
): Promise<AxiosResponse> {
  try {
    const response = await axios({
      url: update_assigned_product_price,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        idSupplier: supplierId,
        supplierProducts: assignements,
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
