import { IOrder } from "@/interfaces/IOrder";
import React from "react";
import { TableCell, TableRow } from "./ui/table";
import { ISupplier } from "@/interfaces/ISupplier";
import { formateDate } from "@/lib/utils";
import { useDispatch, useSelector } from "react-redux";
import { setOrder } from "@/store/reducers/orderReducer";
import { setSupplier } from "@/store/reducers/supplierReducer";
import { useNavigate } from "react-router-dom";
import { RootState } from "@/store/store";
import Highlighter from "react-highlight-words";
import { closeDialog } from "@/store/reducers/dialogReducer";
import OrderStatusTag from "./OrderStatusTag";

interface OrderProps {
  order: IOrder;
  supplier: ISupplier;
  forOrderCreation?: boolean;
}

const Order: React.FC<OrderProps> = ({ order, supplier, forOrderCreation }) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const searchKeyword = useSelector(
    (state: RootState) => state.searchReduer.searchText
  );

  const handleShowDetails = () => {
    dispatch(setOrder(order));
    dispatch(setSupplier(supplier));
    navigation("/orders/" + order.id + "/" + order.supplierId + "");
  };

  const handleSelectOrder = () => {
    dispatch(setOrder(order));
    dispatch(setSupplier(supplier));
    dispatch(closeDialog());
  };

  return (
    <>
      <TableRow
        onClick={forOrderCreation ? handleSelectOrder : handleShowDetails}
        key={order.id}
        className="cursor-pointer hover:bg-gray-100"
      >
        <TableCell className=" sm:table-cell font-semibold">
          <Highlighter
            highlightClassName="bg-yellow-200 dark:bg-yellow-500"
            searchWords={[searchKeyword]}
            autoEscape={true}
            textToHighlight={order.orderNumber.toString()}
          />
        </TableCell>
        <TableCell className="sm:table-cell">{order.supplierLabel}</TableCell>
        <TableCell className="sm:table-cell text-center">
          {formateDate(order.orderDate)}
        </TableCell>
        <TableCell className="sm:table-cell text-center">
          {order.nombreProduct}
        </TableCell>
        <TableCell className="sm:table-cell">
          {order.totalPricTTC} MAD
        </TableCell>
        <TableCell className="sm:table-cell text-center justify-center">
          <OrderStatusTag status={order.statusId} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default Order;
