import React from "react";

interface PriceDetailsBarProps {
  totalPrice: number;
  invoiceTotal?: number;
  totalBRV?: number;
}

// Helper function to format number with spaces
const formatNumber = (num: number): string => {
  return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const PriceDetailsBar_: React.FC<PriceDetailsBarProps> = ({
  totalPrice,
  invoiceTotal,
  totalBRV,
}) => {
  const tva = totalPrice * 0.2;
  const totalTTC = totalPrice + tva;

  return (
    <div className="sticky bottom-0 w-full bg-white py-4 px-6 flex flex-wrap items-center justify-between gap-4 drop-shadow-2xl border-t">
      <div className="flex gap-4 sm:justify-start">
        <div className="flex gap-4">
          <span className="font-medium text-sm">Total BRV:</span>
          <span className="font-bold text-sm">
            {formatNumber(totalBRV || 0)} MAD
          </span>
        </div>
      </div>
      <div className="flex gap-4 sm:justify-end">
        <div className="justify-end flex gap-4">
          <span className="font-medium text-sm">Total facture HT:</span>
          <span className="font-bold text-sm">
            {formatNumber(invoiceTotal || 0)} MAD
          </span>
          <span className="font-medium text-sm">Total Validé HT:</span>
          <span
            className={`font-bold text-sm ${
              invoiceTotal !== totalPrice ? "text-red-500" : "text-green-500"
            }`}
          >
            {formatNumber(totalPrice)} MAD
          </span>
          <span className="font-medium text-sm">TVA :</span>
          <span className="font-bold text-sm">{formatNumber(tva)} MAD</span>
          <span className="font-medium text-sm">Total TTC:</span>
          <span className="font-bold text-sm">
            {formatNumber(totalTTC)} MAD
          </span>
        </div>
      </div>
    </div>
  );
};

export default PriceDetailsBar_;
