import { IAssignProof } from "@/interfaces/ISupplier";
import React from "react";
import { TableCell, TableRow } from "./ui/table";
import { formatDateTime } from "@/lib/utils";
import { Button } from "./ui/button";
import { File } from "lucide-react";

interface IAssignProofProps {
  proof: IAssignProof;
}

const AssignProof: React.FC<IAssignProofProps> = ({ proof }) => {
  return (
    <TableRow>
      <TableCell className="sm:table-cell text-center">{proof.name}</TableCell>
      <TableCell className="sm:table-cell text-center">
        {formatDateTime(proof.createdOn)}
      </TableCell>
      <TableCell className="sm:table-cell text-center">
        <Button
          size="sm"
          variant="outline"
          onClick={() => {
            window.open(proof.nameBlobStorageFile);
          }}
        >
          <File className="h-4 w-4" />
        </Button>
      </TableCell>
      <TableCell className="sm:table-cell text-center">
        <Button
          size="sm"
          variant="outline"
          onClick={() => {
            window.open(proof.nameBlobStoragePreuve);
          }}
        >
          <File className="h-4 w-4" />
        </Button>
      </TableCell>
      <TableCell className="sm:table-cell text-center">
        <Button
          size="sm"
          variant="outline"
          onClick={() => {
            window.open(proof.result);
          }}
        >
          <File className="h-4 w-4" />
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default AssignProof;
