import axios, { AxiosResponse } from "axios";
import { upload_invoice_doc_url } from "@/constants/urls";

const accessToken = sessionStorage.getItem("token");

export async function uploadInvoiceDoc(
  invoices: File[],
  invoiceId: number
): Promise<AxiosResponse<number>> {
  const formData = new FormData();
  invoices.forEach((file) => {
    if (file) {
      formData.append("file", file, file.name);
    }
  });
  try {
    const response: AxiosResponse<number> = await axios({
      url: upload_invoice_doc_url + invoiceId,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/octet-stream",
      },
      data: formData,
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
