import { IProduct } from "@/interfaces/IProduct";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ProductState {
  product: IProduct;
}

const initialState: ProductState = {
  product: {
    id: 0,
    productId: "",
    gtin: "",
    title: "",
    imagePath: "",
    brand: "",
    niveau1: "",
    niveau1Id: "",
    niveau2: "",
    niveau2Id: "",
    niveau3: "",
    niveau3Id: "",
    isActive: false,
  },
};

const productEditSlice = createSlice({
  name: "productEdit",
  initialState,
  reducers: {
    setProduct: (state, action: PayloadAction<IProduct>) => {
      state.product = action.payload;
    },
    resetProduct: (state) => {
      state.product = initialState.product;
    },
  },
});

export const { setProduct } = productEditSlice.actions;
export default productEditSlice.reducer;
