import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { ISupplier } from "@/interfaces/ISupplier";
import Supplier from "./Supplier";

interface SupplierTableProps {
  suppliers: ISupplier[];
  forOrderCreation?: boolean;
}

const SupplierTable: React.FC<SupplierTableProps> = ({
  suppliers,
  forOrderCreation,
}) => {
  return (
    <Table>
      <TableHeader>
        <TableRow className="bg-gray-100">
          <TableHead className="sm:table-cell">Identifiant</TableHead>
          <TableHead className="sm:table-cell">Libellé fournisseur</TableHead>
          <TableHead className="hidden md:table-cell">Raison Social</TableHead>
          <TableHead className="hidden 2xl:table-cell">Prénom</TableHead>
          <TableHead className="hidden 2xl:table-cell">Nom</TableHead>
          <TableHead className="hidden md:table-cell">ICE</TableHead>

          <TableHead className="sm:table-cell text-center">
            Produits assignés
          </TableHead>
          <TableHead className="hidden md:table-cell">Statut</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {suppliers && suppliers.length > 0 ? (
          suppliers.map((supplier) => (
            <Supplier
              key={supplier.id}
              supplier={supplier}
              forOrderCreation={forOrderCreation}
            />
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={8} className="text-center">
              Aucun fournisseur trouvé
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default SupplierTable;
