import axios, { AxiosResponse } from "axios";
import { invoice_details_url } from "@/constants/urls";
import { IInvoiceDetails } from "@/interfaces/IInvoice";

const accessToken = sessionStorage.getItem("token");

export async function getInvoiceDetails(
  invoieId: number
): Promise<AxiosResponse<IInvoiceDetails>> {
  try {
    const response: AxiosResponse<IInvoiceDetails> = await axios({
      url: invoice_details_url + invoieId,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
