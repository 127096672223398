import axios, { AxiosResponse } from "axios";
import { create_supplier_url } from "@/constants/urls";
import { ISupplier } from "@/interfaces/ISupplier";
import { IServerResponse_ } from "@/interfaces/IRequestResponse";

const accessToken = sessionStorage.getItem("token");

export async function createSupplier(
  supplier: ISupplier,
  type: number
): Promise<AxiosResponse<IServerResponse_<string>>> {
  try {
    const response: AxiosResponse<IServerResponse_<string>> = await axios({
      url: create_supplier_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        id: type === 0 ? undefined : supplier.id,
        label: supplier.label,
        socialreason: supplier.socialreason,
        firstName: supplier.firstName,
        lastName: supplier.lastName,
        rib: supplier.rib,
        bankName: supplier.bankName,
        ice: supplier.ice,
        pattente: supplier.pattente,
        rc: supplier.rc,
        email: supplier.email,
        address: supplier.address,
        billingAddress: supplier.billingAddress,
        providerCode: supplier.providerCode,
        Type: type,
        dureeEcheancesEnJours: supplier.dureeEcheancesEnJours,
        TypePaiment: supplier.typePaiment,
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}
