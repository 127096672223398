import axios, { AxiosResponse } from "axios";
import { update_order_url } from "@/constants/urls";
import { IOrderItemRequest } from "@/interfaces/IOrder";

const accessToken = sessionStorage.getItem("token");

export async function updateOrder(
  orderItems: IOrderItemRequest[],
  // providerId: number,
  orderId: number
): Promise<AxiosResponse> {
  try {
    const response: AxiosResponse = await axios({
      url: update_order_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        OrderId: orderId,
        // providerId: providerId,
        OrderDetailCreate: orderItems,
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
