import StocksTable from "@/components/StocksTable";
import TablePagination from "@/components/TablePagination";
import TableSkeleton from "@/components/TableSkeleton";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Toaster } from "@/components/ui/toaster";
import UtilityBar from "@/components/UtilityBar";
import { IPageData } from "@/interfaces/IPageData";
import { IStockItem } from "@/interfaces/IStock";
import { getStocks } from "@/services/StocksListService";
import { setFilterDisabled } from "@/store/reducers/filterDisableReducer";
import { RootState } from "@/store/store";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const StocksPage: React.FC = () => {
  const dispatch = useDispatch();
  const [stocks, setStocks] = useState<IStockItem[]>([]);
  const [pageData, setPageData] = useState<IPageData>();
  const currentPage = useSelector(
    (state: RootState) => state.pageReducer.pageNumber
  );
  const searchKeyword = useSelector(
    (state: RootState) => state.searchReduer.searchText
  );
  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );
  const [isLoading, setIsLoading] = useState(false);
  const supplier = useSelector(
    (state: RootState) => state.supplierReducer.supplier
  );

  useEffect(() => {
    setIsLoading(true);
    fetchStocks();
  }, [currentPage, searchKeyword, isRefreshing, supplier]);

  const fetchStocks = async () => {
    const data = await getStocks(
      searchKeyword,
      currentPage,
      10,
      supplier.id !== 0 ? supplier.id.toString() : null
    );
    if (data) {
      setStocks(data.data);
      setPageData({
        pageSize: data.pageSize,
        hasNextPage: data.hasNextPage,
        hasPreviousPage: data.hasPreviousPage,
        currentPage: data.currentPage,
        totalCount: data.totalCount,
        totalPages: data.totalPages,
      });
      console.log(data.data);
      dispatch(setFilterDisabled(false));
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full px-12 py-3">
      <h1 className="text-2xl font-bold mb-4">Stocks</h1>
      <UtilityBar
        hasCreateButton={false}
        hasSupplierFilter
        hasFilterButton
        hasProductExportButton={false}
      />
      {isLoading ? (
        <TableSkeleton rows={10} cols={4} />
      ) : (
        <>
          <ScrollArea className="h-[60vh]">
            <StocksTable stocks={stocks} />
          </ScrollArea>
          {pageData && <TablePagination pageData={pageData} />}
        </>
      )}
      <Toaster />
    </div>
  );
};

export default StocksPage;
