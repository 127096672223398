import axios, { AxiosResponse } from "axios";
import { supplier_validation_url } from "@/constants/urls";
import { IServerResponse__ } from "@/interfaces/IRequestResponse";

const accessToken = sessionStorage.getItem("token");

export async function validateSupplier(
  id: number
): Promise<AxiosResponse<IServerResponse__>> {
  try {
    const response: AxiosResponse<IServerResponse__> = await axios({
      url: supplier_validation_url + id,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}
