import axios, { AxiosResponse } from "axios";
import { list_seller_url, list_suppliers_order_url } from "@/constants/urls";
import { ISupplierResponse } from "@/interfaces/ISupplier";

const accessToken = sessionStorage.getItem("token");

export async function getSuppliers(
  keyword: string,
  pageNumber?: number
): Promise<AxiosResponse<ISupplierResponse>> {
  try {
    const response: AxiosResponse<ISupplierResponse> = await axios({
      url: list_seller_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        keyword: keyword,
        pageNumber: pageNumber || 1,
        pageSize: 1000,
        orderBy: [],
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}

export async function getSuppliersOrder(): Promise<
  AxiosResponse<ISupplierResponse>
> {
  try {
    // TODO: change type
    const response: AxiosResponse<ISupplierResponse> = await axios({
      url: list_suppliers_order_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
