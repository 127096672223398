import axios, { AxiosResponse } from "axios";
import {
  litige_item_invoiced_update_url,
  litige_item_valid_update_url,
} from "@/constants/urls";
import {} from "@/interfaces/IOrder";

const accessToken = sessionStorage.getItem("token");

export async function updateLitigeItem(
  id: number,
  QuantityFacture: number,
  UnitPriceFacture: number
): Promise<AxiosResponse> {
  try {
    const response: AxiosResponse = await axios({
      url: litige_item_invoiced_update_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        id: id,
        QuantityFacture: QuantityFacture,
        UnitPriceFacture: UnitPriceFacture,
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}

export async function updateValidLitigeItem(
  id: number,
  QuantityFacture: number,
  UnitPriceFacture: number
): Promise<AxiosResponse> {
  try {
    const response: AxiosResponse = await axios({
      url: litige_item_valid_update_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        id: id,
        QuantityValid: QuantityFacture,
        UnitPriceValid: UnitPriceFacture,
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
