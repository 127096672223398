import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { IDelivery } from "@/interfaces/IDelivery";
import Delivery from "./Delivery";

interface DeliveryTableProps {
  deliveries: IDelivery[];
}

const DeliveryTable: React.FC<DeliveryTableProps> = ({ deliveries }) => {
  return (
    <Table>
      <TableHeader>
        <TableRow className="bg-gray-100">
          <TableHead className="sm:table-cell">N° Commande</TableHead>
          <TableHead className="sm:table-cell">N° Réception</TableHead>
          <TableHead className="sm:table-cell">Fournisseur</TableHead>
          <TableHead className="sm:table-cell text-center">
            Date de commande
          </TableHead>
          <TableHead className="sm:table-cell text-center">
            Date de réception
          </TableHead>
          <TableHead className="sm:table-cell text-center">
            Nombre de produit
          </TableHead>
          <TableHead className="sm:table-cell text-center">
            Total (TTC)
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {deliveries && deliveries.length > 0 ? (
          deliveries.map((delivery) => (
            <Delivery key={delivery.cdaNumber} delivery={delivery} />
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={7} className="text-center">
              Aucune réception trouvée
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default DeliveryTable;
