import axios, { AxiosResponse } from "axios";
import { invoice_delete_url } from "@/constants/urls";

const accessToken = sessionStorage.getItem("token");

export async function deleteInvoice(id: number): Promise<AxiosResponse> {
  try {
    const response = await axios({
      url: invoice_delete_url + id,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "DELETE",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
