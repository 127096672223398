import axios, { AxiosResponse } from "axios";
import { order_check_url } from "@/constants/urls";
import { IServerResponse } from "@/interfaces/IRequestResponse";

const accessToken = sessionStorage.getItem("token");

export async function InvoiceOrderCheck(
  orderNumber: string
): Promise<AxiosResponse<IServerResponse>> {
  try {
    const response: AxiosResponse<IServerResponse> = await axios({
      url: order_check_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        OrderNumber: orderNumber,
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}
