import axios, { AxiosResponse } from "axios";
import { update_user_url } from "@/constants/urls";
import { accessToken } from "@/constants/constants";
import { IUser } from "@/interfaces/IUser";

export async function updateUser(user: IUser): Promise<AxiosResponse> {
  try {
    const response = await axios({
      url: update_user_url + user.id,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        firstname: user.firstName,
        lastname: user.lastName,
        email: user.email,
        role: user.role,
        actions: user.actions.map((action) => action.id),
      },
      method: "PUT",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
