import { formatDateTime, formateDate } from "@/lib/utils";
import React from "react";
import { IInvoiceDetails } from "@/interfaces/IInvoice";

interface InvoiceDetailsBarProps {
  invoiceDetails: IInvoiceDetails;
  invoiceTotal: number;
}

const InvoiceDetailsBar: React.FC<InvoiceDetailsBarProps> = ({
  invoiceDetails,
  invoiceTotal,
}) => {
  console.log(formateDate(invoiceDetails.createdOn));

  return (
    <div className="flex flex-col sm:flex-row sm:flex-wrap sm:justify-between sm:gap-6 w-full">
      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          Fournisseur
        </span>
        <span className="font-medium">{invoiceDetails.supplierLabel}</span>
      </div>
      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          Commande N°
        </span>
        <span className="font-medium">{invoiceDetails.orderNumber}</span>
      </div>
      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          Réception N°
        </span>
        <span className="font-medium">{invoiceDetails.cdaNumber}</span>
      </div>
      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          Crée le
        </span>
        <span className="font-medium">
          {formatDateTime(invoiceDetails.createdOn)}
        </span>
      </div>

      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          Commandée le
        </span>
        <span className="font-medium">
          {formatDateTime(invoiceDetails.orderDate)}
        </span>
      </div>
      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          Réceptionnée le
        </span>
        <span className="font-medium">
          {formatDateTime(invoiceDetails.receptionDate)}
        </span>
      </div>

      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          Montant facture (TTC)
        </span>
        <span className="font-medium">{invoiceTotal} MAD</span>
      </div>
      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          Montant BRV (TTC)
        </span>
        <span className="font-medium">{invoiceDetails.amountTTCBRV} MAD</span>
      </div>
    </div>
  );
};

export default InvoiceDetailsBar;
