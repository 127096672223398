import React, { useEffect } from "react";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { Switch } from "./ui/switch";

interface UnitPriceSelectProps {
  disabled?: boolean;
  unitPrice: number;
  setUnitPrice: React.Dispatch<React.SetStateAction<number>>;
  label: string;
  hadSwitch?: boolean;
  setChecked?: React.Dispatch<React.SetStateAction<boolean>>;
  checked?: boolean;
  placeholder?: string;
}

const UnitPriceSelect: React.FC<UnitPriceSelectProps> = ({
  disabled,
  unitPrice,
  setUnitPrice,
  label,
  checked,
  setChecked,
  hadSwitch,
  placeholder,
}) => {
  const [isDisabled, setIsDisabled] = React.useState<boolean>(
    disabled || false
  );

  const handleSwitch = () => {
    setChecked && setChecked(!checked);
    // setUnitPrice(0);
  };
  useEffect(() => {
    setIsDisabled(disabled || false);
  }, [disabled]);
  return (
    <>
      <Label className="text-right" htmlFor="unit-price">
        {label}
      </Label>
      <Input
        className="col-span-1"
        id="unit-price"
        type="number"
        disabled={isDisabled && !checked}
        placeholder={placeholder || "Entrez le prix unitaire"}
        value={unitPrice === 0 ? "" : unitPrice}
        min={1}
        onChange={(e) => setUnitPrice(parseInt(e.target.value))}
      />
      {hadSwitch && disabled && (
        <Switch
          className="col-span-1"
          checked={checked}
          onCheckedChange={handleSwitch}
        />
      )}
    </>
  );
};

export default UnitPriceSelect;
