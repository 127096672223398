import {
  IOrderCreateItem,
  IOrderDetails,
  IOrderItem,
  IOrderUpdateItem,
} from "@/interfaces/IOrder";
import React, { useEffect } from "react";
import { Input } from "./ui/input";
import {
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Table,
} from "./ui/table";
import { Button } from "./ui/button";
import { TrashIcon } from "lucide-react";
import { updateOrder } from "@/services/OrderUpdateService";
import { setRefreshing } from "@/store/reducers/refreshReducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { toast } from "./ui/use-toast";
import { IErrorResponse } from "@/interfaces/IErrorResponse";
import { useNavigate, useParams } from "react-router-dom";
import { ISupplier } from "@/interfaces/ISupplier";
import LoaderButton from "./LoaderButton";
import { updateOrderDetails } from "@/services/OrderDetailsUpdateService";
import { IOrderHeader, IOrderDeleteLine } from "@/interfaces/IOrder";
import { deleteOrderLine } from "@/services/OrderDeleteService";
import TableSkeleton from "./TableSkeleton";

interface OrderItemsEditTableProps {
  selectedProducts: IOrderItem[];
  setSelectedProducts: React.Dispatch<React.SetStateAction<IOrderItem[]>>;
  handleEdit?: () => void;
  selectedSupplier?: ISupplier;
  receptionOrderId?: number;
  remove?: boolean;
  orderDetails?: IOrderDetails;
  orderHeaderInfo: IOrderHeader;
  isLoadingDrafts: boolean;
}

const OrderItemsEditTable: React.FC<OrderItemsEditTableProps> = ({
  selectedProducts,
  setSelectedProducts,
  handleEdit,
  selectedSupplier,
  remove,
  orderHeaderInfo,
  isLoadingDrafts,
}) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { orderId, supplierId } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );
  const handleRemoveProduct = async (product: IOrderDeleteLine) => {
    const products = selectedProducts.filter(
      (p) => p.productId !== product.productId
    );
    setSelectedProducts(products);
    const response = await deleteOrderLine(product.id);
    if (response.status === 200) {
      toast({
        title: "Commande supprimée",
        description: response.data.data,
        variant: "success",
      });
    } else {
      toast({
        title: "Erreur",
        description:
          "Une erreur s'est produite lors de la suppression de la commande",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    console.log("selectedProducts", selectedProducts);
  }, [selectedProducts]);

  const handleUpdateOrder = async () => {
    const orderItems: IOrderUpdateItem[] = selectedProducts.map((product) => ({
      IdLigne: product.id,
      productSupplierId: product.productSupplierId,
      quantity: product.quantity,
    }));
    if (!supplierId || !orderId) {
      return;
    }

    setIsLoading(true);
    const response = await updateOrder(orderItems, parseInt(orderId));
    if (response.status === 200) {
      dispatch(setRefreshing(!isRefreshing));
      toast({
        title: "Commande modifiée",
        description: "La commande a été modifiée avec succès",
        variant: "success",
      });
      handleEdit && handleEdit();
    } else {
      const error = response as unknown as IErrorResponse;
      const message = error.messages[0] || error.exception;
      toast({
        title: "Erreur",
        description: message,
        variant: "destructive",
      });
    }
    setIsLoading(false);
  };

  const handleSave = async () => {
    const orderDetailCreate: IOrderCreateItem[] = selectedProducts.map(
      (product) => ({
        productSupplierId: product.productSupplierId,
        quantity: product.quantity,
        IdLigne: product.id,
      })
    );

    if (!selectedSupplier) {
      return;
    }
    setIsLoading(true);

    const response = await updateOrderDetails(
      orderDetailCreate,
      orderHeaderInfo?.id as number
    );
    if (response.status === 200) {
      dispatch(setRefreshing(!isRefreshing));
      toast({
        title: "Commande placée",
        description: "Commande placée avec succès",
        variant: "success",
      });
      navigation("/orders");
    } else {
      const error = response as unknown as IErrorResponse;
      toast({
        title: "Erreur",
        description: error.messages[0],
        variant: "destructive",
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="grid gap-6 md:gap-8">
      <div>
        <div className="overflow-auto">
          {isLoadingDrafts ? (
            <TableSkeleton rows={2} cols={5} />
          ) : (
            <Table>
              <TableHeader>
                <TableRow className="bg-gray-100">
                  <TableHead>SKU</TableHead>
                  <TableHead>GTIN</TableHead>
                  <TableHead>Titre</TableHead>
                  <TableHead>Quantité</TableHead>
                  <TableHead>Prix d'achat (MAD)</TableHead>
                  {!remove && (
                    <TableHead className="text-center">Actions</TableHead>
                  )}
                </TableRow>
              </TableHeader>
              <TableBody>
                {selectedProducts?.map((product) => (
                  <TableRow key={product.productId}>
                    <TableCell>{product.productId}</TableCell>
                    <TableCell>{product.gtin}</TableCell>
                    <TableCell>{product.title}</TableCell>

                    <TableCell>
                      <Input
                        key={product.productId}
                        type="number"
                        value={product.quantity === 0 ? "" : product.quantity}
                        disabled={false}
                        min={1}
                        onChange={(e) => {
                          const products = selectedProducts.map((p) => {
                            if (p.productId === product.productId) {
                              return {
                                ...p,
                                quantity: parseInt(e.target.value),
                              };
                            }
                            return p;
                          });
                          setSelectedProducts(products);
                        }}
                      />
                    </TableCell>

                    <TableCell>
                      <Input
                        key={product.productId}
                        type="number"
                        value={product.unitPriceHT}
                        disabled={true}
                      />
                    </TableCell>

                    {!remove && (
                      <TableCell className="text-center">
                        <Button
                          onClick={() =>
                            handleRemoveProduct(product as IOrderDeleteLine)
                          }
                          variant="outline"
                          size="sm"
                        >
                          <TrashIcon className="h-4 w-4" />
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </div>
        {selectedProducts?.length > 0 && (
          <div className="flex justify-end gap-2 mt-3 ">
            {handleEdit && (
              <Button onClick={handleEdit} variant="outline">
                Annuler
              </Button>
            )}
            {orderId && handleEdit ? (
              <LoaderButton
                isLoading={isLoading}
                handleSubmit={handleUpdateOrder}
                name="Modifier"
              />
            ) : (
              <LoaderButton
                isLoading={isLoading}
                handleSubmit={handleSave}
                name="Créer"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderItemsEditTable;
