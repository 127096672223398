import React from "react";

interface SideItemProps {
  icon: React.ReactNode;
  title: string;
  to: string;
  path: string;
}

const SideItem: React.FC<SideItemProps> = ({ icon, title, to, path }) => {
  const location = window.location.pathname;
  const isActive = location === to || location.includes(path || "");

  const activeClasses = isActive
    ? "bg-[#089588] text-white"
    : "text-gray-700 hover:bg-[#089588] hover:text-white transition-colors duration-500";
  return (
    <a
      className={
        "flex flex-col items-center gap-1  px-2 py-2 text-md font-medium rounded-md " +
        activeClasses
      }
      href={to}
    >
      {icon}
      {title}
    </a>
  );
};

export default SideItem;
