import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FilterDisableState {
  filterDisabled: boolean;
}

const initialState: FilterDisableState = {
  filterDisabled: false,
};

const filterDisableSlice = createSlice({
  name: "filterDisable",
  initialState,
  reducers: {
    setFilterDisabled: (state, action: PayloadAction<boolean>) => {
      state.filterDisabled = action.payload;
    },
  },
});

export const { setFilterDisabled } = filterDisableSlice.actions;
export default filterDisableSlice.reducer;
