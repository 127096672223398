import React from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./ui/alert-dialog";
import { RootState } from "@/store/store";
import { useDispatch, useSelector } from "react-redux";
import { DELETE_USER_DIALOG } from "@/constants/constants";
import { closeDialog } from "@/store/reducers/dialogReducer";
import { deleteUser } from "@/services/UserDeleteService";
import { toast } from "./ui/use-toast";
import { setRefreshing } from "@/store/reducers/refreshReducer";

interface UserDeleteDialogProps {
  // Add any props here
}

const UserDeleteDialog: React.FC<UserDeleteDialogProps> = () => {
  const dispatch = useDispatch();
  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialogReducer
  );
  const [, setIsLoading] = React.useState(false);
  const user = useSelector((state: RootState) => state.userReducer.user);
  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );
  const isOpened = isOpen && dialogType === DELETE_USER_DIALOG;
  const handleClose = () => {
    dispatch(closeDialog());
  };

  const handleDeleteUser = async () => {
    if (user.id) {
      setIsLoading(true);
      const response = await deleteUser(user.id);
      if (response.status === 200) {
        toast({
          title: "Succès",
          description: "Utilisateur supprimé avec succès",
          variant: "success",
        });
        dispatch(closeDialog());
        dispatch(setRefreshing(!isRefreshing));
      } else {
        toast({
          title: "Erreur",
          description: "Erreur lors de la suppression de l'utilisateur",
          variant: "destructive",
        });
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  };

  return (
    <AlertDialog open={isOpened} onOpenChange={handleClose}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Supprimer l'utilisateur</AlertDialogTitle>
          <AlertDialogDescription>
            Etes vous sûr de vouloir supprimer cet utilisateur ?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={handleClose}>Annuler</AlertDialogCancel>
          <AlertDialogAction onClick={handleDeleteUser}>
            Supprimer
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default UserDeleteDialog;
