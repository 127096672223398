import React from "react";

interface StatusTagProps {
  status: boolean;
  forOrder?: boolean;
}

const StatusTag: React.FC<StatusTagProps> = ({ status, forOrder }) => {
  return (
    <div className="flex items-center space-x-2">
      {status ? (
        <div className="rounded-md bg-green-100 px-3 py-1 text-sm font-semibold text-green-500">
          {!forOrder ? "Actif" : "Terminé"}
        </div>
      ) : (
        <div className="rounded-md bg-red-100 px-3 py-1 text-sm font-semibold text-red-500">
          {!forOrder ? "Inactif" : "En Cours"}
        </div>
      )}
    </div>
  );
};

export default StatusTag;
