import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RefreshState {
    isRefreshing: boolean;
}

const initialState: RefreshState = {
    isRefreshing: false,
};

const refreshSlice = createSlice({
    name: 'refresh',
    initialState,
    reducers: {
        setRefreshing: (state, action: PayloadAction<boolean>) => {
            state.isRefreshing = action.payload;
        },
    },
});

export const { setRefreshing } = refreshSlice.actions;
export default refreshSlice.reducer;