import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SupplierProductTableState {
    isOpen: boolean;
    supplierId: number 
}

const initialState: SupplierProductTableState = {
    isOpen: false,
    supplierId: 0
};

const supplierProductTableSlice = createSlice({
    name: 'supplierProductTable',
    initialState,
    reducers: {
        setIsOpenAndSupplierId: (state, action: PayloadAction<{ isOpen: boolean, supplierId: number }>) => {
            state.isOpen = action.payload.isOpen;
            state.supplierId = action.payload.supplierId;
        }
       
        
    },
});

export const { setIsOpenAndSupplierId } = supplierProductTableSlice.actions;

export default supplierProductTableSlice.reducer;