import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRayon } from '../../interfaces/IRayon';

interface RayonState {
    rayon: IRayon 
}

const initialState: RayonState = {
    rayon: {
      id: '',
        name: '',
        description: '',

    },
};

const rayonSlice = createSlice({
    name: 'rayon',
    initialState,
    reducers: {
        setRayon: (state, action: PayloadAction<IRayon>) => {
            state.rayon = action.payload;
        },
        clearRayon: (state) => {
            state.rayon = initialState.rayon;
        },
    },
});

export const { setRayon, clearRayon } = rayonSlice.actions;

export default rayonSlice.reducer;