import React from "react";
import { IStockDetails } from "@/interfaces/IStock";

interface StockDetailsBarProps {
  stockDetails: IStockDetails;
}

const StockDetailsBar: React.FC<StockDetailsBarProps> = ({ stockDetails }) => {
  console.log("invoice total", stockDetails);
  return (
    <div className="flex flex-col sm:flex-row sm:flex-wrap sm:justify-between sm:gap-6 w-full">
      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">SKU</span>
        <span className="font-medium">{stockDetails.productId}</span>
      </div>
      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">GTIN</span>
        <span className="font-medium">{stockDetails.gtin || "N/A"}</span>
      </div>
      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          Libellé
        </span>
        <span className="font-medium">{stockDetails.productLabel}</span>
      </div>

      {/*       <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          P.U (MAD)
        </span>
        <span className="font-medium">{stockDetails.unitPriceValide}</span>
      </div>
      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          Quantité
        </span>
        <span className="font-medium">{stockDetails.quantityAvailable}</span>
      </div> */}
    </div>
  );
};

export default StockDetailsBar;
