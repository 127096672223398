import { configureStore } from "@reduxjs/toolkit";
import pageReducer from "./reducers/pageReducer";
import searchReduer from "./reducers/searchReduer";
import dialogReducer from "./reducers/dialogReducer";
import productReducer from "./reducers/productReducer";
import refreshReducer from "./reducers/refreshReducer";
import rayonReducer from "./reducers/rayonReducer";
import supplierProductsTableRedcuer from "./reducers/supplierProductsTableRedcuer";
import supplierReducer from "./reducers/supplierReducer";
import orderReducer from "./reducers/orderReducer";
import deliveryReducer from "./reducers/deliveryReducer";
import userReducer from "./reducers/userReducer";
import filterReducer from "./reducers/filterReducer";
import filterDisableReducer from "./reducers/filterDisableReducer";
import supplierProductReducer from "./reducers/supplierProductReducer";
import invoiceReducer from "./reducers/invoiceReducer";

export const store = configureStore({
  reducer: {
    pageReducer: pageReducer,
    searchReduer: searchReduer,
    productReducer: productReducer,
    dialogReducer: dialogReducer,
    refreshReducer: refreshReducer,
    rayonReducer: rayonReducer,
    supplierProductsTableRedcuer: supplierProductsTableRedcuer,
    supplierReducer: supplierReducer,
    orderReducer: orderReducer,
    deliveryReducer: deliveryReducer,
    userReducer: userReducer,
    filterReducer: filterReducer,
    filterDisableReducer: filterDisableReducer,
    supplierProductReducer: supplierProductReducer,
    invoiceReducer: invoiceReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
