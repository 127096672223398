import axios, { AxiosResponse } from "axios";
import { list_delivery_url } from "@/constants/urls";

import { IDeliveryResponse } from "@/interfaces/IDelivery";

const accessToken = sessionStorage.getItem("token");

export async function getDeliveries(
  keyword: string,
  pageNumber: number,
  pageSize: number
): Promise<AxiosResponse<IDeliveryResponse>> {
  try {
    const response: AxiosResponse<IDeliveryResponse> = await axios({
      url: list_delivery_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        keyword: keyword,
        pageNumber: pageNumber,
        pageSize: pageSize,
        orderBy: [],
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
