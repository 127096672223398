import {
  Check,
  CircleX,
  DownloadIcon,
  File,
  ReceiptText,
  Upload,
  TrashIcon,
} from "lucide-react";
import React from "react";
import { Button } from "./ui/button";
import { Separator } from "./ui/Separator";
import { Switch } from "./ui/switch";
import { Label } from "./ui/label";
import { handleDownload as handledownload_ } from "@/lib/utils";

interface ActionBarProps {
  checked?: boolean;
  handleEdit?(): void;
  handleDelete?(): void;
  handleDownload?(): void;
  handleUpload?(): void;
  hasEdit?: boolean;
  hasDelete?: boolean;
  hasDownload?: boolean;
  hasUpload?: boolean;
  hasView?: boolean;
  handleView?(): void;
  hasValidate?: boolean;
  handleValidate?(): void;
  hasInvoice?: boolean;
  handleInvoice?(): void;
  statusId?: number;
  hasUrl?: string;
  hasUrlBrv?: string;
}

const ActionBar: React.FC<ActionBarProps> = ({
  checked,
  handleEdit,
  hasDelete,
  hasDownload,
  hasEdit,
  handleDelete,
  handleDownload,
  handleUpload,
  hasView,
  handleView,
  hasValidate,
  handleValidate,
  hasInvoice,
  handleInvoice,
  statusId,
  hasUrl,
  hasUrlBrv,
}) => {
  return (
    <div className="flex items-center space-x-2">
      {handleUpload &&
        (hasUrl !== "" ? (
          <Button
            variant="ghost"
            onClick={() => {
              if (hasUrl) {
                handledownload_(hasUrl, "BL");
              }
            }}
          >
            <DownloadIcon className="w-6 h-6 mr-2" />
            Télécharger BL
          </Button>
        ) : (
          <Button
            variant="ghost"
            onClick={() => {
              handleUpload && handleUpload();
            }}
          >
            <Upload className="w-6 h-6 mr-2" />
            Importer {handleInvoice ? "BL" : ""}
          </Button>
        ))}

      {hasValidate && (
        <Button
          variant="ghost"
          onClick={() => {
            handleValidate && handleValidate();
          }}
        >
          <Check className="w-6 h-6 mr-2" color="green" />
          Valider
        </Button>
      )}
      {hasDownload &&
        (hasUrlBrv ? (
          <Button
            variant="ghost"
            onClick={() => {
              handledownload_(hasUrlBrv, "BRV");
            }}
          >
            <DownloadIcon className="w-6 h-6 mr-2" />
            Télécharger {handleInvoice ? "BRV" : ""}
          </Button>
        ) : (
          <Button
            variant="ghost"
            onClick={() => {
              handleDownload && handleDownload();
            }}
          >
            <DownloadIcon className="w-6 h-6 mr-2" />
            Télécharger {handleInvoice ? "BRV" : ""}
          </Button>
        ))}
      {hasView && (
        <Button
          variant="ghost"
          onClick={() => {
            handleView && handleView();
          }}
        >
          <File className="w-6 h-6 mr-2" />
          Documents
        </Button>
      )}
      {hasDelete && (
        <Button
          variant="ghost"
          onClick={() => {
            handleDelete && handleDelete();
          }}
        >
          {statusId === 0 ? (
            <>
              <TrashIcon className="w-6 h-6 mr-2" color="red" />
              Supprimer
            </>
          ) : (
            <>
              <CircleX className="w-6 h-6 mr-2" color="red" />
              Annuler
            </>
          )}
        </Button>
      )}

      {hasEdit && (
        <div className="flex items-center space-x-2">
          <Separator orientation="vertical" className="h-6 mr-2 " />
          <Switch
            aria-label="Edit supplier information"
            className="relative inline-flex h-6 w-11 items-center rounded-full"
            id="editSupplier"
            checked={checked}
            onCheckedChange={() => {
              handleEdit && handleEdit();
            }}
          ></Switch>
          <Label className="text-sm font-medium" htmlFor="editSupplier">
            Modifier
          </Label>
        </div>
      )}
      {hasInvoice && (
        <Button
          onClick={() => {
            handleInvoice && handleInvoice();
          }}
        >
          <ReceiptText className="w-6 h-6 mr-2" />
          Facture
        </Button>
      )}
    </div>
  );
};

export default ActionBar;
