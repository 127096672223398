import { ILitige } from "@/interfaces/ILitige";
import React from "react";
import { TableCell, TableRow } from "./ui/table";
import { formateDate } from "@/lib/utils";
import InvoiceStatusTag from "./InvoiceStatusTag";
import { useNavigate } from "react-router-dom";

interface LitigeProps {
  litige: ILitige;
}

const Litige: React.FC<LitigeProps> = ({ litige }) => {
  const navigation = useNavigate();

  const handleLitigeClick = () => {
    navigation(`/litiges/${litige.id}`);
  };
  return (
    <TableRow
      className="hover:bg-gray-100 cursor-pointer"
      onClick={handleLitigeClick}
    >
      <TableCell className="sm:table-cell">{litige.orderNumber}</TableCell>
      <TableCell className="sm:table-cell">{litige.invoiceNumber}</TableCell>
      <TableCell className="sm:table-cell">{litige.supplierLabel}</TableCell>
      <TableCell className="sm:table-cell text-center">
        {formateDate(litige.orderDate)}
      </TableCell>
      <TableCell className="sm:table-cell text-center">
        {formateDate(litige.receptionDate)}
      </TableCell>
      <TableCell className="sm:table-cell text-center">
        {litige.amountTTC} MAD
      </TableCell>
      <TableCell className="sm:table-cell text-center">
        {litige.amountTTCBRV} MAD
      </TableCell>
      <TableCell className="sm:table-cell ">
        <InvoiceStatusTag status={litige.statut} />
      </TableCell>
    </TableRow>
  );
};

export default Litige;
