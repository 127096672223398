import {
  ArrowRightIcon,
  LoaderCircle,
  PlusIcon,
  SearchIcon,
  SlidersHorizontal,
  FileUp,
} from "lucide-react";
import React, { useEffect } from "react";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { useDispatch, useSelector } from "react-redux";
import { setSearchText } from "@/store/reducers/searchReduer";
import { openDialog } from "@/store/reducers/dialogReducer";
import {
  CREATE_RAYON_DIALOG,
  CREATE_USER_DIALOG,
  INVOICE_PAGE,
  ORDER_CREATE_PAGE,
  ORDER_PAGE,
  RAYON_PAGE,
  RECEPTION_CREATE_PAGE,
  RECEPTION_PAGE,
  SUPPLIER_DETAILS_PAGE,
  SUPPLIER_PAGE,
  USER_PAGE,
  EXTRACTION_MESSAGE,
  INVOICE_CREATE_DIALOG,
  SUPPLIER_CREATE_DIALOG,
  PRODUCT_PAGE,
  PRODUCT_CREATE_DIALOG,
} from "@/constants/constants";
import { RootState } from "@/store/store";
import { useNavigate } from "react-router-dom";
import { ISupplier } from "@/interfaces/ISupplier";
import FilterList from "./FilterList";
import { ICategory } from "@/interfaces/ICategory";
import { setDefaultFilter, setExportMsg } from "@/store/reducers/filterReducer";
import { getCategories } from "@/services/CategoyListService";
import { handleDownload } from "@/lib/utils";
import { toast } from "./ui/use-toast";
import SupplierSelect_ from "./SupplierSelect_";
import { setSupplier } from "@/store/reducers/supplierReducer";
import {
  exportProducts,
  pingExportProducts,
} from "@/services/ProductExportService";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";

interface UtilityBarProps {
  hasCreateButton?: boolean;
  supplers?: ISupplier;
  hasSearchButton?: boolean;
  hasFilterButton?: boolean;
  hasProductExportButton?: boolean;
  exportMsg?: string | null;
  fileLink?: string | null;
  hasSupplierFilter?: boolean;
  hasCategoryFilter?: boolean;
}

const UtilityBar: React.FC<UtilityBarProps> = ({
  hasCreateButton,
  hasSearchButton,
  hasFilterButton,
  hasProductExportButton,
  hasCategoryFilter,
  hasSupplierFilter,
  exportMsg,
  fileLink,
}) => {
  const navigation = useNavigate();
  const disabled = useSelector(
    (state: RootState) => state.filterDisableReducer.filterDisabled
  );
  const location = window.location.pathname;
  const [selectedSupplier, setSelectedSupplier] = React.useState<ISupplier>(
    {} as ISupplier
  );
  const [buttonText, setButtonText] = React.useState<string>("");
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [filterVisible, setFilterVisible] = React.useState<boolean>(false);
  const [categories, setCategories] = React.useState<ICategory[]>(
    [] as ICategory[]
  );
  const supplier = useSelector(
    (state: RootState) => state.supplierReducer.supplier
  );
  const filter = useSelector((state: RootState) => state.filterReducer.filter);
  const filterDisabled = useSelector(
    (state: RootState) => state.filterDisableReducer.filterDisabled
  );
  const [exportDisabled, setExportDisabled] = React.useState(false);

  const dispatch = useDispatch();

  const handleSearch = () => {
    dispatch(setSearchText(searchInput));
  };

  useEffect(() => {
    if (hasFilterButton) {
      fetchCategories();
    }
  }, []);

  const fetchCategories = async () => {
    const response = await getCategories();
    if (response.status === 200) {
      setCategories(response.data as ICategory[]);
    } else {
      toast({
        title: "Erreur",
        description:
          "Une erreur s'est produite lors de la récupération des catégories",
        variant: "destructive",
      });
    }
  };

  const handleFilterVisibility = () => {
    setFilterVisible(!filterVisible);
    if (filterVisible === true) {
      console.log("filterVisible", filterVisible);
      dispatch(setDefaultFilter());
      dispatch(setSupplier({} as ISupplier));
    }
  };

  const handleCreateRayon = () => {
    switch (location) {
      case RAYON_PAGE:
        dispatch(openDialog(CREATE_RAYON_DIALOG));
        break;
      case SUPPLIER_PAGE:
        dispatch(openDialog(SUPPLIER_CREATE_DIALOG));
        break;
      case SUPPLIER_DETAILS_PAGE + `/${supplier.id}`:
        navigation("/suppliers/" + supplier.id + "/assign");
        break;
      case ORDER_PAGE:
        navigation(ORDER_CREATE_PAGE);
        break;
      case RECEPTION_PAGE:
        navigation(RECEPTION_CREATE_PAGE);
        break;
      case INVOICE_PAGE:
        dispatch(openDialog(INVOICE_CREATE_DIALOG));
        break;
      case USER_PAGE:
        dispatch(openDialog(CREATE_USER_DIALOG));
        break;
      case PRODUCT_PAGE:
        dispatch(openDialog(PRODUCT_CREATE_DIALOG));
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    switch (location) {
      case RAYON_PAGE:
        setButtonText("Créer un rayon");
        break;
      case SUPPLIER_PAGE:
        setButtonText("Créer un fournisseur");
        break;
      case SUPPLIER_DETAILS_PAGE + `/${supplier.id}`:
        setButtonText("Assigner des produits");
        break;
      case ORDER_PAGE:
        setButtonText("Créer une commande");
        break;
      case RECEPTION_PAGE:
        setButtonText("Créer une réception");
        break;
      case INVOICE_PAGE:
        setButtonText("Saisir une facture");
        break;
      case USER_PAGE:
        setButtonText("Créer un utilisateur");
        break;
      case PRODUCT_PAGE:
        setButtonText("");
        break;
      default:
        break;
    }
  }, [location, supplier.id]);

  const handleExportProducts = async () => {
    setExportDisabled(true);

    const response = await exportProducts(
      filter,
      supplier.id !== 0 ? supplier.id : null
    );
    if (response.status === 200) {
      dispatch(setExportMsg(response.data.message));
      toast({
        title: "Export demandé",
        description: response.data.message,
        variant: "success",
      });
    } else {
      console.log("Error exporting products", response);
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de l'exportation",
        variant: "destructive",
      });
    }
    setExportDisabled(false);
  };

  const disabledButton = exportMsg === EXTRACTION_MESSAGE;

  return (
    <section
      className={`flex gap-2 items-center mb-4 ${
        hasFilterButton ? "justify-between" : ""
      } ${hasCreateButton ? "justify-between" : "justify-end"}`}
    >
      <div
        className={`flex gap-2 items-center ${
          location !== PRODUCT_PAGE ? "w-full justify-between" : ""
        }`}
      >
        {hasCreateButton && (
          <div className="flex gap-2 ">
            <Button onClick={handleCreateRayon}>
              <PlusIcon
                className={` h-4 w-4 text-white ${buttonText ? "mr-2" : ""}`}
              />
              {buttonText}
            </Button>
          </div>
        )}
        <div
          className={`flex  items-center ${
            location !== PRODUCT_PAGE ? "" : "gap-2"
          }`}
        >
          <div className="relative w-60">
            <SearchIcon className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400 dark:text-gray-500" />
            <Input
              className="w-full rounded-md border border-gray-200 bg-gray-100 py-2 pl-10 pr-4 text-sm focus:border-gray-400 focus:bg-white focus:outline-none dark:border-gray-800 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-gray-700 dark:focus:bg-gray-800"
              placeholder="Rechercher..."
              type="search"
              onChange={(e) => {
                if (hasSearchButton) {
                  setSearchInput(e.target.value);
                } else {
                  dispatch(setSearchText(e.target.value));
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") handleSearch();
              }}
            />
          </div>
          {hasSearchButton && (
            <Button onClick={handleSearch}>
              <ArrowRightIcon className=" h-4 w-4 text-white" />
            </Button>
          )}
          <div className="flex gap-2 items-center">
            {hasProductExportButton && (
              <TooltipProvider>
                <Tooltip>
                  <TooltipContent>{exportMsg}</TooltipContent>
                  <TooltipTrigger>
                    <Button
                      variant="outline"
                      onClick={() =>
                        fileLink &&
                        exportMsg &&
                        handleDownload(fileLink, exportMsg)
                      }
                      disabled={disabledButton || !fileLink}
                    >
                      {!disabledButton ? (
                        <FileUp className=" h-4 w-4 text-gray-500" />
                      ) : (
                        <LoaderCircle className=" h-4 w-4 text-gray-500 animate-spin" />
                      )}
                    </Button>
                  </TooltipTrigger>
                </Tooltip>
              </TooltipProvider>
            )}
          </div>
        </div>
      </div>

      {hasFilterButton && (
        <div className="flex gap-2">
          <Button onClick={handleFilterVisibility} variant="outline">
            {!disabled ? (
              <SlidersHorizontal className=" h-4 w-4 text-gray-500" />
            ) : (
              <LoaderCircle className=" h-4 w-4 text-gray-500 animate-spin" />
            )}
          </Button>

          {filterVisible && (
            <>
              {hasCategoryFilter && (
                <FilterList
                  categories={categories || []}
                  visible={filterVisible}
                  hasExportButton={hasProductExportButton}
                />
              )}
              {hasSupplierFilter && (
                <SupplierSelect_
                  selectedSupplier={selectedSupplier}
                  setSelectedSupplier={setSelectedSupplier}
                  visible={filterVisible}
                />
              )}
              {(filter.category1.id !== "" || supplier.id !== 0) &&
                hasProductExportButton && (
                  <div className=" flex items-center ">
                    <Button
                      onClick={() =>
                        handleExportProducts().then(() => {
                          pingExportProducts();
                        })
                      }
                      variant="outline"
                      disabled={filterDisabled}
                    >
                      {!exportDisabled ? (
                        <FileUp className=" h-4 w-4 text-gray-500" />
                      ) : (
                        <LoaderCircle className=" h-4 w-4 text-gray-500 animate-spin" />
                      )}
                    </Button>
                  </div>
                )}
            </>
          )}
        </div>
      )}
    </section>
  );
};

export default UtilityBar;
