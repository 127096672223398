import axios, { AxiosResponse } from "axios";
import { list_litige_url } from "@/constants/urls";
import { ILitigeResponse } from "@/interfaces/ILitige";

const accessToken = sessionStorage.getItem("token");

export async function getLitiges(
  keyword: string,
  pageNumber: number,
  pageSize: number
): Promise<AxiosResponse<ILitigeResponse>> {
  try {
    const response: AxiosResponse<ILitigeResponse> = await axios({
      url: list_litige_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        keyword: keyword,
        pageNumber: pageNumber,
        pageSize: pageSize,
        orderBy: [],
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
