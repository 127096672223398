import Header from "@/components/Header";
import Sidebar from "@/components/SideBar";
import React, { ReactNode } from "react";

type LayoutProps = {
  children: ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div className="flex flex-col h-screen">
      <Header setOpen={setIsOpen} open={isOpen} />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar open={isOpen} hasBorder />
        <main className="flex-1 overflow-y-auto">{children}</main>
      </div>
    </div>
  );
};

export default Layout;
