import axios, { AxiosResponse } from "axios";

import { create_invoice_url } from "@/constants/urls";
import { IServerResponse_ } from "@/interfaces/IRequestResponse";

const accessToken = sessionStorage.getItem("token");

export async function createInvoice(
  invoiceNumber: string,
  orderId: number,
  amountTTC: number
): Promise<AxiosResponse<IServerResponse_<string>>> {
  try {
    const response: AxiosResponse<IServerResponse_<string>> = await axios({
      url: create_invoice_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        InvoiceNumber: invoiceNumber,
        OrderId: orderId,
        AmountTTC: amountTTC,
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
