import React, { useEffect } from "react";
import { Button } from "./ui/button";
import { Dialog, DialogContent, DialogTitle } from "./ui/dialog";
import LoaderButton from "./LoaderButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { PRODUCT_CREATE_DIALOG } from "@/constants/constants";
import { closeDialog } from "@/store/reducers/dialogReducer";
import { Input } from "./ui/input";
import { LoaderCircle, QrCode, Upload, X } from "lucide-react";
import { truncateFilename } from "@/lib/utils";
import { v4 as uuidv4 } from "uuid";
import { generateProductEAN } from "@/services/ProductEanGenerationService";
import { toast } from "./ui/use-toast";
import { createProduct } from "@/services/ProductCreateService";

interface ProductCreateDialogProps {}

const ProductCreateDialog: React.FC<ProductCreateDialogProps> = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState(true);
  const [productLabel, setProductLabel] = React.useState("");
  const [productSKU, setProductSKU] = React.useState("");
  const [productGTIN, setProductGTIN] = React.useState("");
  const [eanGenerationLoading, setEanGenerationLoading] = React.useState(false);
  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialogReducer
  );
  const [productFiles, setProductFiles] = React.useState<File[]>([]);
  const isOpened = isOpen && dialogType === PRODUCT_CREATE_DIALOG;

  const handleClose = () => {
    dispatch(closeDialog());
    setProductLabel("");
    setProductSKU("");
    setProductGTIN("");
    setProductFiles([]);
  };

  const isFormValid = () => {
    return (
      productLabel.length > 0 && productSKU.length > 0 && productGTIN.length > 0
    );
  };
  const handleFileChange = (files: File[]) => {
    setProductFiles([...productFiles, ...Array.from(files)]);
  };

  const handleCreateProduct = async () => {
    setIsLoading(true);
    const response = await createProduct(
      {
        Title: productLabel,
        ProductId: productSKU,
        Gtin: productGTIN,
      },
      productFiles
    );

    if (response.status === 200) {
      toast({
        title: "Succès",
        description: "Le produit a été créé avec succès",
        variant: "success",
      });
      handleClose();
    } else {
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de la création du produit",
        variant: "destructive",
      });
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const handleGenerateEAN = async () => {
    setEanGenerationLoading(true);
    const response = await generateProductEAN();
    if (response.status === 200) {
      setProductGTIN(response.data.data);
    } else {
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de la génération du GTIN",
        variant: "destructive",
      });
      setEanGenerationLoading(false);
    }
    setEanGenerationLoading(false);
  };

  useEffect(() => {
    setIsSubmitDisabled(!isFormValid());
  }, [productLabel, productSKU, productGTIN]);

  return (
    <Dialog open={isOpened} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[70vh]" hasClose={true}>
        <DialogTitle>Créer un produit</DialogTitle>
        <div className="grid gap-4">
          <div className="flex flex-col items-start space-y-1 gap-1">
            <span className="text-sm font-medium">GTIN *</span>
            <div className="flex items-center w-full flex-row gap-2">
              <div className="flex items-center w-full gap-4">
                <Input
                  className="flex-grow"
                  id="name"
                  placeholder="GTIN du produit"
                  value={productGTIN}
                  onChange={(e) => setProductGTIN(e.target.value)}
                  disabled={isLoading}
                />
              </div>
              <div className="flex items-center space-x-2">
                <Button
                  type="button"
                  variant="outline"
                  onClick={handleGenerateEAN}
                >
                  {eanGenerationLoading ? (
                    <LoaderCircle className="w-5 h-5 animate-spin" />
                  ) : (
                    <QrCode className="w-5 h-5" />
                  )}
                </Button>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start space-y-1 gap-1">
            <span className="text-sm font-medium">SKU *</span>

            <div className="flex items-center w-full gap-4">
              <Input
                className="flex-grow"
                id="name"
                placeholder="SKU du produit"
                value={productSKU}
                onChange={(e) => setProductSKU(e.target.value)}
                disabled={isLoading}
              />
            </div>
          </div>
          <div className="flex flex-col items-start space-y-1 gap-1">
            <span className="text-sm font-medium">Libellé *</span>

            <div className="flex items-center w-full gap-4">
              <Input
                className="flex-grow"
                id="name"
                placeholder="Libellé du produit"
                value={productLabel}
                onChange={(e) => setProductLabel(e.target.value)}
                disabled={isLoading}
              />
            </div>
          </div>
          {productFiles.length > 0 && (
            <div className="space-y-2">
              <span className="text-sm font-medium ">Pièces jointes</span>
              {productFiles.map((file) => (
                <div className="flex items-center ">
                  <Input
                    key={uuidv4()}
                    value={truncateFilename(file.name, 15, 15)}
                    disabled
                  />
                  <Button
                    variant="outline"
                    className="hover:bg-white border-none"
                    onClick={() =>
                      setProductFiles(productFiles.filter((f) => f !== file))
                    }
                  >
                    <X className="w-4 h-4" />
                  </Button>
                </div>
              ))}
            </div>
          )}

          <div className="flex  flex-1 justify-between mt-4">
            <div className="col-span-3 flex items-center justify-start gap-2">
              <Button
                variant="outline"
                className="gap-2"
                disabled={productFiles.length >= 1}
                onClick={() => {
                  const input = document.createElement("input");
                  input.type = "file";
                  input.multiple = true;
                  input.accept = [".png", ".jpg", ".jpeg"].join(",");
                  input.onchange = (e) => {
                    const target = e.target as HTMLInputElement;
                    if (target.files && target.files.length > 0) {
                      handleFileChange(Array.from(target.files));
                    }
                  };
                  input.click();
                }}
              >
                <Upload className="w-4 h-4" />
                <span className="text-sm">Importer</span>
              </Button>
            </div>
            <div className="flex flex-1 items-center space-x-2 justify-end">
              <Button variant="outline" onClick={handleClose}>
                Annuler
              </Button>
              <LoaderButton
                isLoading={isLoading}
                handleSubmit={handleCreateProduct}
                name="Créer"
                disabled={isSubmitDisabled}
              />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ProductCreateDialog;
