import axios, { AxiosResponse } from "axios";
import { invoice_status_update_url } from "@/constants/urls";
import { IServerResponse } from "@/interfaces/IRequestResponse";

const accessToken = sessionStorage.getItem("token");

export async function updateInvoiceStatus(
  invoiceId: number,
  statusId: number
): Promise<AxiosResponse<IServerResponse>> {
  try {
    const response: AxiosResponse<IServerResponse> = await axios({
      url: invoice_status_update_url,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        InvoiceId: invoiceId,
        statut: statusId,
      },
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
