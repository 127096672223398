import React from "react";
import { Input } from "./ui/input";
import {
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Table,
} from "./ui/table";
import { Button } from "./ui/button";
import { setRefreshing } from "@/store/reducers/refreshReducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { toast } from "./ui/use-toast";
import { IErrorResponse } from "@/interfaces/IErrorResponse";
import { useNavigate } from "react-router-dom";
import { createDelivery } from "@/services/DeliveryCreateService";
import {
  IDeliveryItemDetails,
  IDeliveryItemRequest,
} from "@/interfaces/IDelivery";
import { updateDelivery } from "@/services/DeliveryUpdateService";
import LoaderButton from "./LoaderButton";

import { IOrderDetailsDTO } from "@/interfaces/IInvoice";

interface DeliveryItemsEditTableProps {
  selectedProducts: IDeliveryItemDetails[];
  setSelectedProducts: React.Dispatch<
    React.SetStateAction<IDeliveryItemDetails[]>
  >;
  handleEdit?: () => void;
  orderId?: number;
  receptionOrderId?: number;
  edit?: boolean;
  orderDetails?: IOrderDetailsDTO[];
}

const DeliveryItemsEditTable: React.FC<DeliveryItemsEditTableProps> = ({
  selectedProducts,
  setSelectedProducts,
  handleEdit,
  orderId,
  receptionOrderId,
  edit,
  orderDetails,
}) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );
  const handleValidate = async () => {
    console.log("selectedProducts", selectedProducts);
    const orderItems: IDeliveryItemRequest[] = selectedProducts.map(
      (product) => ({
        id: product.id,
        productId: product.productId,
        quantity: product.newQuantity ?? product.quantity,
        unitPrice: product.unitPriceHT,
      })
    );
    if (!receptionOrderId) {
      return;
    }
    setIsLoading(true);
    const response = await createDelivery(orderItems, receptionOrderId);
    if (response.status === 200) {
      dispatch(setRefreshing(!isRefreshing));
      toast({
        title: "Réception crée",
        description: "Réception crée avec succès",
        variant: "success",
      });

      navigation("/deliveries");
    } else {
      const error = response as unknown as IErrorResponse;
      const message = error.messages[0] || error.exception;
      toast({
        title: "Erreur",
        description: message,
        variant: "destructive",
      });
    }
    setIsLoading(false);
  };

  const handleUpdateDelivery = async () => {
    const deliveryItems: IDeliveryItemRequest[] = selectedProducts.map(
      (product) => ({
        id: product.id,
        productId: product.productId,
        quantity: product.newQuantity ?? product.quantity,
        unitPrice: product.unitPriceHT,
      })
    );
    if (!orderId) {
      return;
    }
    setIsLoading(true);
    const response = await updateDelivery(
      receptionOrderId ?? 0,
      deliveryItems,
      orderId
    );
    setIsLoading(false);
    if (response.status === 200) {
      dispatch(setRefreshing(!isRefreshing));
      toast({
        title: "Commande modifiée",
        description: "La commande a été modifiée avec succès",
        variant: "success",
      });
      handleEdit && handleEdit();
    } else {
      const error = response as unknown as IErrorResponse;
      const message = error.messages[0] || error.exception;
      toast({
        title: "Erreur",
        description: message,
        variant: "destructive",
      });
    }
  };

  return (
    <div className="grid gap-6 md:gap-8">
      <div>
        <div className="overflow-auto">
          <Table>
            <TableHeader>
              <TableRow className="bg-gray-100">
                <TableHead>SKU</TableHead>
                <TableHead>GTIN</TableHead>
                <TableHead>Titre</TableHead>
                <TableHead>Qté commandée</TableHead>
                {edit && <TableHead>Qté réceptionnée</TableHead>}
                <TableHead>Prix d'achat (MAD)</TableHead>
                <TableHead>
                  {edit ? "Nouvelle quantité" : "Qté réceptionnée"}
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {selectedProducts.map((product) => (
                <TableRow key={product.productId}>
                  <TableCell>{product.productId}</TableCell>
                  <TableCell>{product.gtin}</TableCell>
                  <TableCell>{product.productLabel}</TableCell>
                  {edit && orderDetails && (
                    <TableCell>
                      <Input
                        key={product.productId}
                        type="number"
                        value={
                          orderDetails.find(
                            (orderItem: IOrderDetailsDTO) =>
                              orderItem.productId === product.productId
                          )?.quantity
                        }
                        disabled={true}
                      />
                    </TableCell>
                  )}
                  <TableCell>
                    <Input
                      key={product.productId}
                      type="number"
                      value={product.quantity}
                      disabled={true}
                    />
                  </TableCell>
                  <TableCell>
                    <Input
                      key={product.productId}
                      type="number"
                      placeholder={product.unitPriceHT.toString()}
                      disabled={true}
                    />
                  </TableCell>
                  <TableCell>
                    <Input
                      key={product.productId}
                      type="number"
                      value={
                        product.newQuantity === 0 ? "" : product.newQuantity
                      }
                      placeholder="Quantité"
                      disabled={false}
                      min={1}
                      onChange={(e) => {
                        const products = selectedProducts.map((p) => {
                          if (p.productId === product.productId) {
                            return {
                              ...p,
                              newQuantity: parseInt(e.target.value),
                            };
                          }
                          return p;
                        });
                        setSelectedProducts(products);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        {selectedProducts.length > 0 && (
          <div className="flex justify-end gap-2 mt-3 ">
            {edit ? (
              <>
                <Button onClick={handleEdit} variant="outline">
                  Annuler
                </Button>
                <LoaderButton
                  handleSubmit={handleUpdateDelivery}
                  isLoading={isLoading}
                  name="Modifier"
                />
              </>
            ) : (
              <LoaderButton
                handleSubmit={handleValidate}
                isLoading={isLoading}
                name="Valider"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DeliveryItemsEditTable;
