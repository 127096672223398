import { IUser } from "@/interfaces/IUser";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface UserState {
  user: IUser;
}

const initialState: UserState = {
  user: {
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    role: "",
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    setDefault: (state) => {
      state.user = initialState.user;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
