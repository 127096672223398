import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { Input } from "./ui/input";
import { Item } from "@/interfaces/Item";
import { IOrderDetailsDTO } from "@/interfaces/IInvoice";

interface ItemsTableProps {
  items: Item[];
  delivery?: boolean;
  orderDetails?: IOrderDetailsDTO[];
}

const ItemsTable: React.FC<ItemsTableProps> = ({
  items,
  delivery,
  orderDetails,
}) => {
  return (
    <div className="grid gap-6 md:gap-8">
      <div>
        <div className="overflow-auto">
          <Table>
            <TableHeader>
              <TableRow className="bg-gray-100">
                <TableHead>SKU</TableHead>
                <TableHead>GTIN</TableHead>
                <TableHead>Titre</TableHead>
                {!delivery && (
                  <>
                    <TableHead>Quantité</TableHead>
                    <TableHead>Prix d'achat (MAD)</TableHead>
                  </>
                )}
                {delivery && (
                  <>
                    <TableHead>Qté commandée</TableHead>
                    <TableHead>Qté réceptionnée</TableHead>
                    <TableHead>Prix d'achat (MAD)</TableHead>
                  </>
                )}
              </TableRow>
            </TableHeader>
            <TableBody>
              {items &&
                items &&
                items.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.productId}</TableCell>
                    <TableCell>{item.gtin}</TableCell>
                    <TableCell>{item.title}</TableCell>
                    {delivery && orderDetails && (
                      <>
                        <TableCell>
                          <Input
                            key={item.id}
                            type="number"
                            placeholder={String(
                              orderDetails.find(
                                (orderItem: IOrderDetailsDTO) =>
                                  orderItem.productId === item.productId
                              )?.quantity
                            )}
                            disabled={true}
                          />
                        </TableCell>
                        <TableCell>
                          <Input
                            key={item.id}
                            type="number"
                            placeholder={item.receivedQuantity?.toString()}
                            disabled={true}
                          />
                        </TableCell>
                        <TableCell>
                          <Input
                            key={item.id}
                            type="number"
                            placeholder={item.unitPriceHT?.toString()}
                            disabled={true}
                          />
                        </TableCell>
                      </>
                    )}
                    {!delivery && (
                      <>
                        <TableCell>
                          <Input
                            key={item.id}
                            type="number"
                            placeholder={item.quantity?.toString()}
                            disabled={true}
                          />
                        </TableCell>
                        <TableCell>
                          <Input
                            key={item.id}
                            type="number"
                            placeholder={item.unitPriceHT?.toString()}
                            disabled={true}
                          />
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ItemsTable;
