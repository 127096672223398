import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getInvoiceStatus } from "@/services/InvoiceStatusService";
import { RootState } from "@/store/store";
// Adjust this import based on your actual store structure

interface InvoiceStatusTagProps {
  status?: number;
  invoiceId?: number;
}

interface StatusStyle {
  text: string;
  bgColor: string;
  textColor: string;
}

const statusStyles: Record<number, StatusStyle> = {
  0: { text: "BAP", bgColor: "bg-green-100", textColor: "text-green-600" },
  1: {
    text: "A rapprocher",
    bgColor: "bg-red-100",
    textColor: "text-red-600",
  },
  2: {
    text: "Saisie en cours",
    bgColor: "bg-yellow-100",
    textColor: "text-yellow-600",
  },
  3: {
    text: "Saisie terminée",
    bgColor: "bg-green-100",
    textColor: "text-green-600",
  },
  4: {
    text: "Rapprochement en cours",
    bgColor: "bg-yellow-100",
    textColor: "text-yellow-600",
  },
  5: {
    text: "Rapprochée",
    bgColor: "bg-green-100",
    textColor: "text-green-600",
  },
};

const defaultStyle: StatusStyle = {
  text: "Inconnu",
  bgColor: "bg-gray-100",
  textColor: "text-gray-600",
};

const InvoiceStatusTag: React.FC<InvoiceStatusTagProps> = ({
  invoiceId,
  status: initialStatus,
}) => {
  const [status, setStatus] = useState<number | undefined>(initialStatus);
  const [isLoading, setIsLoading] = useState(false);
  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );

  const fetchStatus = async () => {
    if (invoiceId === undefined) return;

    setIsLoading(true);
    try {
      const response = await getInvoiceStatus(invoiceId.toString());
      if (response.status === 200) {
        setStatus(parseInt(response.data.data));
      } else {
        console.error("Error fetching invoice status", response);
      }
    } catch (error) {
      console.error("Failed to fetch invoice status", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log("status changed", status);
    if (invoiceId !== undefined) {
      fetchStatus();
    }
  }, [invoiceId, status, isRefreshing]);

  useEffect(() => {
    if (initialStatus !== undefined) {
      setStatus(initialStatus);
    }
  }, [initialStatus]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const statusStyle =
    status !== undefined ? statusStyles[status] || defaultStyle : defaultStyle;

  return (
    <div className="flex items-center space-x-2">
      <div
        className={`rounded-md px-3 py-1 text-sm font-semibold ${statusStyle.bgColor} ${statusStyle.textColor}`}
      >
        {statusStyle.text}
      </div>
    </div>
  );
};

export default InvoiceStatusTag;
