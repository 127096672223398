import axios, { AxiosResponse } from "axios";
import { create_order_url } from "@/constants/urls";

const accessToken = sessionStorage.getItem("token");

export async function createOrder(providerId: number): Promise<AxiosResponse> {
  try {
    const response = await axios({
      url: create_order_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        supplierID: providerId,
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
