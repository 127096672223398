import axios, { AxiosResponse } from "axios";
import { stock_details_url } from "@/constants/urls";
import { IStockDetails } from "@/interfaces/IStock";

const accessToken = sessionStorage.getItem("token");

export async function getStockDetails(
  id: number
): Promise<AxiosResponse<IStockDetails>> {
  try {
    const response: AxiosResponse<IStockDetails> = await axios({
      url: stock_details_url + id,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
