import React, { useEffect, useState } from "react";
import { Table, TableBody, TableHead, TableHeader, TableRow } from "./ui/table";
import LitigeItem from "./LitigeItem";
import { ILitigeItem } from "@/interfaces/ILitige";
import LoaderButton from "./LoaderButton";
import { updateInvoiceStatus } from "@/services/LitigeStatusUpdateService";
import { toast } from "./ui/use-toast";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog, openDialog } from "@/store/reducers/dialogReducer";
import LitigeConfirmDialog from "./LitigeConfirmDialog";
import { LITIGE_CONFIRM_DIALOG } from "@/constants/constants";
import { useNavigate } from "react-router-dom";
import { Button } from "./ui/button";
import { Check, X } from "lucide-react";
import { setRefreshing } from "@/store/reducers/refreshReducer";
import { RootState } from "@/store/store";
import { massUpdateInvoiceItems } from "@/services/InvoiceItemsMassUpdateService";
import {
  checkSaisieDetailDesFacturesLitige,
  checkValidationDesLitiges,
  checkValidationLitigePrix,
  checkValidationLitigeQte,
} from "@/lib/roleHelper";

interface LitigeItemsTableProps {
  litigeItems: ILitigeItem[];
  setTotal: (sum: number) => void;
  setLitigeItems: React.Dispatch<React.SetStateAction<ILitigeItem[]>>;
  total: number;
  invoiceTotal: number;
  litigeStatus: number;
  brvTotal: number;
  invoiceId: number;
}

const LitigeItemsTable: React.FC<LitigeItemsTableProps> = ({
  litigeItems,
  setTotal,
  setLitigeItems,
  invoiceTotal,
  litigeStatus,
  invoiceId,
}) => {
  const [disabledItems, setDisabledItems] = useState<Set<number>>(new Set());
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [difference, setDifference] = useState(0);
  const [isValidButtonDisabled, setIsValidButtonDisabled] = useState(true);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );

  const handleUpdateInvoiceStatus = async () => {
    setLoading(true);
    const statusId = [1, 2].includes(litigeStatus)
      ? 3
      : [3, 4].includes(litigeStatus)
      ? 5
      : 0;
    const response = await updateInvoiceStatus(
      litigeItems[0].invoiceId,
      statusId
    );
    if (response.status === 200) {
      navigation("/litige");
      toast({
        title: "Success",
        description: "Facture mis à jour avec succès",
        variant: "success",
      });
    } else {
      toast({
        title: "Erreur",
        description: "Erreur lors de la mise à jour du facture",
        variant: "destructive",
      });
    }
    dispatch(closeDialog());
    setLoading(false);
  };

  const handleConfirm = () => {
    dispatch(openDialog(LITIGE_CONFIRM_DIALOG));
  };

  const handleValidateDisabled = () => {
    if (litigeStatus === 1 || litigeStatus === 2) {
      console.log(difference);
      if (disabledItems.size === litigeItems.length && difference === 0) {
        setIsValidButtonDisabled(false);
      } else {
        setIsValidButtonDisabled(true);
      }
    } else {
      if (disabledItems.size === litigeItems.length) {
        setIsValidButtonDisabled(false);
      } else {
        setIsValidButtonDisabled(true);
      }
    }
  };

  const updateInvoiceItems = async (id: number, status: number) => {
    const response = await massUpdateInvoiceItems(id, status);
    if (response.status === 200) {
      if (status === 0) {
        toast({
          title: "Succès",
          description: "Tous les articles ont été désélectionnés",
          variant: "success",
        });
      } else {
        toast({
          title: "Succès",
          description: "Tous les articles ont été sélectionnés",
          variant: "success",
        });
      }
      return true;
    } else {
      toast({
        title: "Erreur",
        description: "Erreur lors de la désélection de tous les articles",
        variant: "destructive",
      });
      return false;
    }
  };
  const handleSelectAll = async () => {
    if (isAllSelected) {
      const response = await updateInvoiceItems(invoiceId, 0);
      if (!response) return;
      setDisabledItems(new Set());
      setIsAllSelected(false);
    } else {
      const response = await updateInvoiceItems(invoiceId, 1);
      if (!response) return;
      const newDisabledItems = new Set<number>();
      litigeItems.forEach((item) => {
        newDisabledItems.add(item.id);
      });
      setDisabledItems(newDisabledItems);
      setIsAllSelected(true);
      dispatch(setRefreshing(!isRefreshing));
    }
  };

  useEffect(() => {
    if (litigeItems.length === disabledItems.size) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  }, [disabledItems]);

  useEffect(() => {
    handleValidateDisabled();
  }, [disabledItems, litigeStatus, difference]);

  useEffect(() => {
    if (litigeItems === null) {
      return;
    } else {
      if (litigeStatus === 3 || litigeStatus === 4 || litigeStatus === 5) {
        const sum = litigeItems
          .filter((item) => disabledItems.has(item.id))
          .reduce((acc, item) => {
            const quantity = item.quantityValid;
            const price = item.unitPriceValid;
            return acc + quantity * price;
          }, 0);
        setTotal(sum);
        setDifference(invoiceTotal - sum);
      } else {
        const sum = litigeItems
          .filter((item) => disabledItems.has(item.id))
          .reduce((acc, item) => {
            const quantity = item.quantityFacture;
            const price = item.unitPriceFacture;
            return acc + quantity * price;
          }, 0);
        setTotal(sum);
        setDifference(invoiceTotal - sum);
      }
    }
  }, [disabledItems]);

  const handleShowActions = () => {
    if (litigeStatus === 1 || litigeStatus === 2) {
      if (checkSaisieDetailDesFacturesLitige()) {
        return true;
      } else {
        return false;
      }
    } else if (litigeStatus === 3 || litigeStatus === 4) {
      if (checkValidationLitigeQte() || checkValidationLitigePrix()) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    const newDisabledItems = new Set<number>();
    litigeItems.forEach((item) => {
      if (
        item.statut === 1 ||
        item.statut === 3 ||
        item.statut === 4 ||
        item.statut === 5
      ) {
        newDisabledItems.add(item.id);
      } else if (!checkSaisieDetailDesFacturesLitige()) {
        newDisabledItems.add(item.id);
      }
      console.log("disabled items", newDisabledItems);
    });
    setDisabledItems(newDisabledItems);
    console.log("disabledItems", newDisabledItems);
  }, []);

  return (
    <div className="grid gap-6 md:gap-8">
      <div>
        <div className="overflow-auto">
          {(litigeStatus === 1 || litigeStatus === 2) &&
            checkSaisieDetailDesFacturesLitige() && (
              <div className="flex flex-1items-center justify-end mb-4">
                <Button onClick={handleSelectAll} variant="outline">
                  {isAllSelected ? (
                    <>
                      <X className="h-4 w-4 mr-2" color="red" />
                      Tout déselectionner
                    </>
                  ) : (
                    <>
                      <Check className="h-4 w-4 mr-2" color="Green" />
                      Tout sélectionner
                    </>
                  )}
                </Button>
              </div>
            )}
          <Table>
            <TableHeader>
              <TableRow className="bg-gray-100">
                <TableHead className="sm:table-cell">GTIN</TableHead>
                <TableHead className="sm:table-cell">Titre</TableHead>
                {(litigeStatus === 1 || litigeStatus === 2) && (
                  <TableHead className="sm:table-cell text-center">
                    Qté (Commandé)
                  </TableHead>
                )}
                <TableHead className="sm:table-cell text-center">
                  Qté (Reçu)
                </TableHead>
                <TableHead className="sm:table-cell text-center">
                  Prix d'achat (Commande) MAD
                </TableHead>
                <TableHead className="sm:table-cell text-center">
                  Qté (Facture)
                </TableHead>
                <TableHead className="sm:table-cell text-center">
                  Prix d'achat (Facture) MAD
                </TableHead>
                {(litigeStatus === 4 ||
                  litigeStatus === 3 ||
                  litigeStatus === 5) && (
                  <>
                    <TableHead className="sm:table-cell text-center">
                      Qté Validée
                    </TableHead>
                    <TableHead className="sm:table-cell text-center">
                      Prix d'achat (Validé) MAD
                    </TableHead>
                  </>
                )}
                {handleShowActions() && (
                  <TableHead className="sm:table-cell text-center">
                    Actions
                  </TableHead>
                )}
              </TableRow>
            </TableHeader>
            <TableBody>
              {litigeItems &&
                litigeItems.length > 0 &&
                litigeItems.map((item) => (
                  <LitigeItem
                    item={item}
                    key={item.id}
                    disabledItems={disabledItems}
                    setDisabledItems={setDisabledItems}
                    setLitigeItems={setLitigeItems}
                    litigeItems={litigeItems}
                    litigeStatus={litigeStatus}
                  />
                ))}
            </TableBody>
          </Table>
          {litigeStatus !== 5 && checkValidationDesLitiges() && (
            <div className="flex justify-end gap-2 mt-3 ">
              <div className="flex items-center gap-2">
                <LoaderButton
                  isLoading={loading}
                  disabled={isValidButtonDisabled}
                  handleSubmit={handleConfirm}
                  name="Valider"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <LitigeConfirmDialog
        handleSubmit={handleUpdateInvoiceStatus}
        isLoading={loading}
        diff={difference}
      />
    </div>
  );
};

export default LitigeItemsTable;
