import { IUser } from "@/interfaces/IUser";
import React from "react";
import { TableCell, TableRow } from "./ui/table";
import { Button } from "./ui/button";
import { Edit, Trash2 } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "@/store/reducers/userReducer";
import { openDialog } from "@/store/reducers/dialogReducer";
import { DELETE_USER_DIALOG, UPDATE_USER_DIALOG } from "@/constants/constants";
import { Tooltip, TooltipTrigger } from "@radix-ui/react-tooltip";
import { TooltipContent, TooltipProvider } from "./ui/tooltip";
import { RootState } from "@/store/store";
import Highlighter from "react-highlight-words";

interface UserProps {
  user: IUser;
}

const User: React.FC<UserProps> = ({ user }) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(setUser(user));
    dispatch(openDialog(DELETE_USER_DIALOG));
  };
  const handleEdit = () => {
    dispatch(setUser(user));
    dispatch(openDialog(UPDATE_USER_DIALOG));
  };
  const searchKeyword = useSelector(
    (state: RootState) => state.searchReduer.searchText
  );

  return (
    <TableRow>
      <TableCell>
        <TooltipProvider>
          <Tooltip>
            <TooltipContent className="truncate line-clamp-2 ">
              {user.id}
            </TooltipContent>
            <TooltipTrigger>
              <span>{user.id}</span>
            </TooltipTrigger>
          </Tooltip>
        </TooltipProvider>
      </TableCell>
      <TableCell className="hidden lg:table-cell">{user.firstName}</TableCell>
      <TableCell className="hidden lg:table-cell">{user.lastName}</TableCell>
      <TableCell>
        <Highlighter
          highlightClassName="bg-yellow-200 dark:bg-yellow-500"
          searchWords={[searchKeyword]}
          autoEscape={true}
          textToHighlight={user.email}
        />
      </TableCell>
      <TableCell>{user.role}</TableCell>
      <TableCell className="sm:table-cell text-center space-x-2">
        <div className="flex justify-center space-x-2 flex-row">
          <Button
            className="h-8 w-8"
            size="icon"
            variant="outline"
            onClick={handleDelete}
          >
            <Trash2 className="h-4 w-4" />
            <span className="sr-only">Supprimer</span>
          </Button>
          <Button
            className="h-8 w-8"
            size="icon"
            variant="outline"
            onClick={handleEdit}
          >
            <Edit className="h-4 w-4" />
            <span className="sr-only">Modifier</span>
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default User;
