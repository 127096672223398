import axios, { AxiosResponse } from "axios";
import { order_mass_create_url } from "@/constants/urls";
import { IOrderBatchItem } from "@/interfaces/IOrder";
import { IServerResponse_ } from "@/interfaces/IRequestResponse";

const accessToken = sessionStorage.getItem("token");

export async function uploadOrderCanvas(
  file: File,
  orderId: string
): Promise<AxiosResponse<IServerResponse_<IOrderBatchItem[]>>> {
  const formData = new FormData();
  formData.append("file", file, file.name);

  try {
    const response: AxiosResponse<IServerResponse_<IOrderBatchItem[]>> =
      await axios({
        url: order_mass_create_url + orderId,
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });
    return response;
  } catch (error: any) {
    return error.response;
  }
}
