import axios, { AxiosResponse } from "axios";
import { list_assign_proofs_url } from "@/constants/urls";
import { IServerResponse_ } from "@/interfaces/IRequestResponse";
import { IAssignProof } from "@/interfaces/ISupplier";

const accessToken = sessionStorage.getItem("token");

export async function getAssignProofs(
  supplierId: number
): Promise<AxiosResponse<IServerResponse_<IAssignProof[]>>> {
  try {
    const response: AxiosResponse<IServerResponse_<IAssignProof[]>> =
      await axios({
        url: list_assign_proofs_url + supplierId,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        method: "GET",
      });
    return response;
  } catch (error: any) {
    return error.response;
  }
}
