import React, { useEffect } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { cn } from "@/lib/utils";
import {
  CommandInput,
  CommandEmpty,
  CommandList,
  CommandGroup,
  CommandItem,
  Command,
} from "./ui/command";
import { ChevronsUpDownIcon, Check } from "lucide-react";
import { Button } from "./ui/button";
import { ISupplier } from "@/interfaces/ISupplier";
import { useDispatch, useSelector } from "react-redux";
import { setSupplier } from "@/store/reducers/supplierReducer";
import { getSuppliers } from "@/services/SupplierListService";
import { toast } from "./ui/use-toast";
import { RootState } from "@/store/store";
import { setFilterDisabled } from "@/store/reducers/filterDisableReducer";

interface SupplierSelectProps_ {
  selectedSupplier: ISupplier;
  setSelectedSupplier: (supplier: ISupplier) => void;
  visible?: boolean;
}

const SupplierSelect_: React.FC<SupplierSelectProps_> = ({
  selectedSupplier,
  setSelectedSupplier,
  visible,
}) => {
  const dispatch = useDispatch();
  const disabled_ = useSelector(
    (state: RootState) => state.filterDisableReducer.filterDisabled
  );
  const [suppliers, setSuppliers] = React.useState<ISupplier[]>([]);
  const [disabled] = React.useState(false);
  const [supplierOpen, setSupplierOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchSuppliers = async () => {
    setIsLoading(true);
    const response = await getSuppliers("");
    if (response.status === 200) {
      setSuppliers(response.data.data as ISupplier[]);
    } else {
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors du téléchargement",
        variant: "destructive",
      });
      setIsLoading(false);
      setSuppliers([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchSuppliers();
  }, []);

  useEffect(() => {
    if (visible) {
      setSelectedSupplier({} as ISupplier);
    }
  }, [visible]);

  return (
    <Popover open={supplierOpen} onOpenChange={setSupplierOpen}>
      <PopoverTrigger asChild>
        <Button
          className="w-full justify-between "
          role="combobox"
          variant="outline"
          disabled={disabled || disabled_}
        >
          {selectedSupplier.label && selectedSupplier
            ? selectedSupplier.label
            : "Fournisseur"}
          <ChevronsUpDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        <Command>
          <CommandInput className="h-9" placeholder="Chercher un fournisseur" />
          <CommandEmpty>
            {isLoading ? "Chargement..." : "Aucun résultat"}
          </CommandEmpty>
          <CommandList>
            <CommandGroup>
              {suppliers &&
                suppliers.map((supplier) => (
                  <CommandItem
                    key={supplier.id}
                    disabled={disabled}
                    value={supplier.label}
                    onSelect={() => {
                      dispatch(setFilterDisabled(true));
                      setSelectedSupplier(supplier);
                      dispatch(setSupplier(supplier));
                      setSupplierOpen(false);
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        selectedSupplier.id === supplier.id
                          ? "opacity-100"
                          : "opacity-0"
                      )}
                    />
                    {supplier.label}
                  </CommandItem>
                ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default SupplierSelect_;
