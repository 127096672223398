import axios from "axios";
import { batch_assign_url } from "@/constants/urls";
import { IServerResponse__ } from "@/interfaces/IRequestResponse";

const accessToken = sessionStorage.getItem("token");
export async function uploadProductAssignCanvas(
  file: File,
  proof: File,
  supplierId: number
): Promise<IServerResponse__> {
  const formData = new FormData();
  formData.append("file", file, file.name);
  formData.append("preuve", proof, proof.name);

  try {
    const response: IServerResponse__ = await axios({
      url: batch_assign_url + supplierId,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/octet-stream",
      },
      data: formData,
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}
