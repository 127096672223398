import axios, { AxiosResponse } from "axios";
import { list_order_url } from "@/constants/urls";
import { IOrderResponse } from "@/interfaces/IOrder";

const accessToken = sessionStorage.getItem("token");

export async function getOrders(
  keyword: string,
  pageNumber: number
): Promise<AxiosResponse<IOrderResponse>> {
  try {
    const response: AxiosResponse<IOrderResponse> = await axios({
      url: list_order_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        // keyword: keyword,
        pageNumber: pageNumber,
        pageSize: 10,
        // orderBy: [],
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
