import { Button } from "@/components/ui/button";
import { TriangleAlert } from "lucide-react";

export default function NotFoundPage() {
  return (
    <div className="flex min-h-[100dvh] flex-col items-center justify-center bg-gradient-to-br from-teal-500 to-teal-700 px-4 py-12 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-md text-center">
        <div className="mx-auto flex h-24 w-24 items-center justify-center rounded-full bg-white">
          <TriangleAlert className="h-12 w-12 text-teal-500" />
        </div>
        <h1 className="mt-4 text-[72px] font-bold tracking-tight text-white sm:text-[96px]">
          404
        </h1>
        <p className="mt-4 text-white/80">
          Oops, the page you are looking for could not be found.
        </p>
        <div className="mt-6">
          <Button onClick={() => window.history.back()} variant="outline">
            Go back
          </Button>
        </div>
      </div>
    </div>
  );
}
