import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDelivery } from "../../interfaces/IDelivery";

interface DeliveryState {
  delivery: IDelivery;
}

const initialState: DeliveryState = {
  delivery: {
    orderNumber: "",
    receptionNumber: "",
    supplierLibelle: "",
    creationDate: "",
    receptionDate: "",
    productCountCommander: 0,
    productCountReceptionner: 0,
    totalPricTtc: 0,
    productCount: 0,
  },
};

const deliverySlice = createSlice({
  name: "delivery",
  initialState,
  reducers: {
    setDelivery: (state, action: PayloadAction<IDelivery>) => {
      state.delivery = action.payload;
    },
    clearDelivery: (state) => {
      state.delivery = initialState.delivery;
    },
  },
});

export const { setDelivery, clearDelivery } = deliverySlice.actions;

export default deliverySlice.reducer;
