import React, { useEffect, useMemo, useState } from "react";
import { TabsContent } from "./ui/tabs";
import AssignProofsTable from "./AssignProofsTable";
import { IAssignProof } from "@/interfaces/ISupplier";
import { getAssignProofs } from "@/services/AssignProofList";
import { toast } from "./ui/use-toast";
import DateSelect from "./DateSelect";
import { Button } from "./ui/button";
import { SlidersHorizontal } from "lucide-react";
import TableSkeleton from "./TableSkeleton";

interface ProofsTabProps {
  supplierId: number;
}

const ProofsTab: React.FC<ProofsTabProps> = ({ supplierId }) => {
  const [proofs, setProofs] = useState<IAssignProof[]>([]);
  const [selectedStartDate, setSelectedStartDate] = useState<
    Date | undefined
  >();
  const [selectedEndDate, setSelectedEndDate] = useState<Date | undefined>();
  const [showFilters, setShowFilters] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFetchProofs = async () => {
    setLoading(true);
    const response = await getAssignProofs(supplierId);
    if (response.status === 200) {
      setProofs(response.data.data);
    } else {
      toast({
        title: "Erreur",
        description:
          "Une erreur s'est produite lors de la récupération des preuves",
        variant: "destructive",
      });
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleFetchProofs();
  }, []);

  const filteredProofs = useMemo(() => {
    return proofs.filter((proof) => {
      const proofDate = new Date(proof.createdOn);
      const isAfterStart = !selectedStartDate || proofDate >= selectedStartDate;
      const isBeforeEnd = !selectedEndDate || proofDate <= selectedEndDate;
      return isAfterStart && isBeforeEnd;
    });
  }, [proofs, selectedStartDate, selectedEndDate]);

  const handleToggleFilters = () => {
    if (showFilters) {
      // Reset filters when hiding
      setSelectedStartDate(undefined);
      setSelectedEndDate(undefined);
    }
    setShowFilters(!showFilters);
  };

  return (
    <TabsContent value="proof" className="mt-4">
      <div className="flex gap-4 flex-col">
        <div className="flex flex-1 justify-end gap-4 items-end">
          {showFilters && (
            <>
              <DateSelect
                label="Date de début"
                selectedDate={selectedStartDate}
                setSelectedDate={setSelectedStartDate}
              />
              <DateSelect
                label="Date de fin"
                selectedDate={selectedEndDate}
                setSelectedDate={setSelectedEndDate}
              />
            </>
          )}
          <Button onClick={handleToggleFilters} variant="outline">
            <SlidersHorizontal className=" h-4 w-4" />
          </Button>
        </div>
        {loading ? (
          <TableSkeleton cols={4} rows={5} />
        ) : (
          <AssignProofsTable assignProofs={filteredProofs} />
        )}
      </div>
    </TabsContent>
  );
};

export default ProofsTab;
