import axios, { AxiosResponse } from "axios";
import { accessToken } from "@/constants/constants";
import { create_user_url } from "@/constants/urls";
import { IUser } from "@/interfaces/IUser";

export async function createUser(user: IUser): Promise<AxiosResponse> {
  try {
    const response = await axios({
      url: create_user_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
        actions: user.actions.map((action) => action.id),
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}
