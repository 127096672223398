import axios, { AxiosResponse } from "axios";

import { ILitigeDetails } from "@/interfaces/ILitige";
import { litige_details_url } from "@/constants/urls";
import { IServerResponse_ } from "@/interfaces/IRequestResponse";

const accessToken = sessionStorage.getItem("token");

export async function getLitigeDetails(
  litigeId: number
): Promise<AxiosResponse<IServerResponse_<ILitigeDetails>>> {
  try {
    const response: AxiosResponse<IServerResponse_<ILitigeDetails>> =
      await axios({
        url: litige_details_url + litigeId,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        method: "POST",
      });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
