import React, { useEffect } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { cn } from "@/lib/utils";
import {
  CommandInput,
  CommandEmpty,
  CommandList,
  CommandGroup,
  CommandItem,
  Command,
} from "./ui/command";
import { ChevronsUpDownIcon, Check } from "lucide-react";
import { Button } from "./ui/button";
import { ISupplier, ISupplierProduct } from "@/interfaces/ISupplier";

import { useParams } from "react-router-dom";
import { getSupplierProducts_ } from "@/services/SupplierOrderProductList";
import { toast } from "./ui/use-toast";

interface ProductSelectProps {
  selectedProduct: ISupplierProduct;
  setSelectedProduct: (product: ISupplierProduct) => void;
  supplier: ISupplier;
}

const ProductSelect: React.FC<ProductSelectProps> = ({
  selectedProduct,
  setSelectedProduct,
  supplier,
}) => {
  const { supplierId } = useParams();
  const [productOpen, setProductOpen] = React.useState(false);
  const [supplierProducts, setSupplierProducts] = React.useState<
    ISupplierProduct[]
  >([]);
  const [productButtonText, setProductButtonText] = React.useState<string>("");

  const fetchProducts = async () => {
    const response = await getSupplierProducts_(
      parseInt(supplierId ?? "") || supplier.id
    );
    if (response.status === 200) {
      const filteredProducts = response.data.filter(
        (product) => product.priceUnitHT > 0
      );
      setSupplierProducts(filteredProducts);
    } else {
      toast({
        title: "Erreur",
        description:
          "Une erreur s'est produite lors de la récupération des produits",
        variant: "destructive",
      });
      setSupplierProducts([]);
    }
  };
  useEffect(() => {
    fetchProducts();
  }, [supplierId]);

  return (
    <div className="grid gap-2">
      <label className="text-sm font-medium flex w-44" htmlFor="product">
        Produit{" "}
      </label>
      <Popover open={productOpen} onOpenChange={setProductOpen}>
        <PopoverTrigger asChild>
          <Button
            className="w-full justify-between col-span-3 truncate"
            role="combobox"
            variant="outline"
          >
            <span className="truncate">
              {productButtonText && selectedProduct.productId
                ? productButtonText
                : "Choisir un produit"}
            </span>

            <ChevronsUpDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[300px] p-0">
          <Command>
            <CommandInput className="h-9" placeholder="Rechercher produit..." />
            <CommandEmpty>Aucun produit trouvé.</CommandEmpty>
            <CommandList>
              <CommandGroup>
                {supplierProducts?.map((product) => (
                  <CommandItem
                    aria-label="product"
                    key={product.productId}
                    value={product.productId}
                    keywords={[product.title || product.gtin]}
                    onSelect={() => {
                      setSelectedProduct(product);
                      setProductButtonText(product.title || product.gtin);
                      setProductOpen(false);
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        selectedProduct.productId === product.productId
                          ? "opacity-100"
                          : "opacity-0"
                      )}
                    />
                    {product.title || product.gtin}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default ProductSelect;
