import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { IInvoice } from "@/interfaces/IInvoice";
import Invoice from "./Invoice";

interface InvoiceTableProps {
  invoices: IInvoice[];
}

const InvoiceTable: React.FC<InvoiceTableProps> = ({ invoices }) => {
  return (
    <Table>
      <TableHeader>
        <TableRow className="bg-gray-100">
          <TableHead className="sm:table-cell">N° Facture </TableHead>
          <TableHead className="sm:table-cell">N° Commande</TableHead>
          <TableHead className="sm:table-cell text-center">
            Fournisseur
          </TableHead>
          <TableHead className="sm:table-cell text-center">
            Date de Commande
          </TableHead>
          <TableHead className="sm:table-cell text-center">
            Date de réception
          </TableHead>

          <TableHead className="sm:table-cell text-center">
            Montant facture (TTC)
          </TableHead>
          <TableHead className="sm:table-cell">Statut</TableHead>

          <TableHead className="sm:table-cell text-center">Fichiers</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {invoices && invoices.length > 0 ? (
          invoices.map((invoice) => (
            <Invoice key={invoice.id} invoice={invoice} />
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={9} className="text-center">
              Aucune facture trouvée
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default InvoiceTable;
