import axios, { AxiosResponse } from "axios";
import { new_product_assign_url } from "@/constants/urls";
import { IAssignementItem_ } from "@/interfaces/IProduct";
import { IServerResponse__ } from "@/interfaces/IRequestResponse";

const accessToken = sessionStorage.getItem("token");

export async function assignProductsToSupplier_(
  supplierId: number,
  assignements: IAssignementItem_[]
): Promise<AxiosResponse<IServerResponse__>> {
  try {
    const response: AxiosResponse<IServerResponse__> = await axios({
      url: new_product_assign_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        idSupplier: supplierId,
        supplierProducts: assignements,
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}
