import axios, { AxiosResponse } from "axios";
import { supplier_documents_upload_url } from "@/constants/urls";
import { ISupplierDocument } from "@/interfaces/ISupplier";
import { IServerResponse__ } from "@/interfaces/IRequestResponse";

const accessToken = sessionStorage.getItem("token");

const FILE_TYPE_MAP: Record<number, string> = {
  1: "fileRc",
  2: "fileICE",
  3: "fileRIB",
  4: "fileRF",
};

export async function uploadSupplierDocs(
  docs: ISupplierDocument[],
  supplierId: number
): Promise<AxiosResponse<IServerResponse__>> {
  const formData = new FormData();

  docs.forEach((doc) => {
    const fileKey = FILE_TYPE_MAP[doc.type];
    if (fileKey && doc.file instanceof File) {
      formData.append(fileKey, doc.file, doc.file.name);
    }
  });

  try {
    const response: AxiosResponse<IServerResponse__> = await axios({
      url: supplier_documents_upload_url + supplierId,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}
