import axios, { AxiosResponse } from "axios";
import { invoice_items_mass_update_url } from "@/constants/urls";
import { IServerResponse__ } from "@/interfaces/IRequestResponse";

const accessToken = sessionStorage.getItem("token");

export async function massUpdateInvoiceItems(
  invoiceId: number,
  statusId: number
): Promise<AxiosResponse<IServerResponse__>> {
  try {
    const response: AxiosResponse<IServerResponse__> = await axios({
      url: invoice_items_mass_update_url + invoiceId + "/" + statusId,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}
