import axios, { AxiosResponse } from "axios";
import { accessToken } from "@/constants/constants";
import { delete_user_url } from "@/constants/urls";

export async function deleteUser(id: number): Promise<AxiosResponse> {
  try {
    const response = await axios({
      url: delete_user_url + id,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "DELETE",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
