import { LITIGE_CONFIRM_DIALOG } from "@/constants/constants";
import { closeDialog } from "@/store/reducers/dialogReducer";
import { RootState } from "@/store/store";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LoaderButton from "./LoaderButton";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";

interface LitigeConfirmDialogProps {
  handleSubmit: () => Promise<void>;
  isLoading: boolean;
  diff: number;
}

const LitigeConfirmDialog: React.FC<LitigeConfirmDialogProps> = ({
  handleSubmit,
  isLoading,
  diff,
}) => {
  const dispatch = useDispatch();
  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialogReducer
  );
  console.log("diff", diff);
  const isOpened = isOpen && dialogType === LITIGE_CONFIRM_DIALOG;
  const handleClose = () => {
    dispatch(closeDialog());
  };

  return (
    <Dialog open={isOpened} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Confirmation</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          {diff !== 0
            ? `Il ya toujours un ecart de ${diff} MAD, êtes-vous sûr de vouloir valider ?`
            : "Êtes-vous sûr de vouloir valider ?"}
        </DialogDescription>
        <DialogFooter>
          <LoaderButton
            name="Valider"
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default LitigeConfirmDialog;
