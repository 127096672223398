import LitigeTable from "@/components/LitigeTable";
import TablePagination from "@/components/TablePagination";
import TableSkeleton from "@/components/TableSkeleton";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Toaster } from "@/components/ui/toaster";
import { toast } from "@/components/ui/use-toast";
import UtilityBar from "@/components/UtilityBar";
import { ILitige } from "@/interfaces/ILitige";
import { IPageData } from "@/interfaces/IPageData";
import { getLitiges } from "@/services/LitigeListService";
import { RootState } from "@/store/store";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const LitigePage: React.FC = () => {
  const [litiges, setLitiges] = useState<ILitige[]>([] as []);
  const [pageData, setPageData] = useState<IPageData>();
  const currentPage = useSelector(
    (state: RootState) => state.pageReducer.pageNumber
  );
  const searchKeyword = useSelector(
    (state: RootState) => state.searchReduer.searchText
  );
  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );
  const [isLoading, setIsLoading] = useState(false);
  const fetchLitiges = async () => {
    const response = await getLitiges(searchKeyword, currentPage, 10);
    if (response.status === 200) {
      setLitiges(response.data.data as ILitige[]);
      setPageData({
        pageSize: response.data.pageSize,
        hasNextPage: response.data.hasNextPage,
        hasPreviousPage: response.data.hasPreviousPage,
        currentPage: response.data.currentPage,
        totalCount: response.data.totalCount,
        totalPages: response.data.totalPages,
      });
    } else {
      toast({
        title: "Erreur",
        description:
          "Une erreur s'est produite lors de la récupération des litiges",
        variant: "destructive",
      });
      setIsLoading(false);
      setLitiges([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchLitiges();
  }, [currentPage, searchKeyword, isRefreshing]);

  // commment
  return (
    <div className="flex-1 px-12 py-3">
      <h1 className="text-2xl font-bold mb-4">Litiges</h1>
      <UtilityBar />
      {isLoading ? (
        <TableSkeleton rows={10} cols={4} />
      ) : (
        <ScrollArea className="h-[60vh]">
          <LitigeTable litiges={litiges} />
        </ScrollArea>
      )}

      <Toaster />
      {pageData && <TablePagination pageData={pageData} />}
    </div>
  );
};

export default LitigePage;
