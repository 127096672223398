import React from "react";
import { Table } from "./ui/table";

interface PriceDetailsBarProps {
  totalPrice: number;
  invoiceTotal?: number;
  totalBRV?: number;
}

// Helper function to format number with spaces
const formatNumber = (num: number): string => {
  return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const PriceDetailsBar: React.FC<PriceDetailsBarProps> = ({
  totalPrice,
  invoiceTotal,
  totalBRV,
}) => {
  const tva = totalPrice * 0.2;
  const totalTTC = totalPrice + tva;

  return (
    <Table>
      <tbody>
        {invoiceTotal && (
          <tr className="border-b border-muted/20">
            <td className="py-4 text-right">
              <span className="font-medium ">Total (HT)</span>
            </td>
            <td className="py-4 text-muted-foreground font-bold text-right">
              {formatNumber(invoiceTotal)} MAD
            </td>
          </tr>
        )}
        {totalBRV && (
          <tr className="border-b border-muted/20">
            <td className="py-4 text-right">
              <span className="font-medium ">Total BRV(HT)</span>
            </td>
            <td className="py-4 text-muted-foreground font-bold text-right">
              {formatNumber(totalBRV)} MAD
            </td>
          </tr>
        )}
        <tr className="border-b border-muted/20">
          <td className="py-4 text-right">
            <span className="font-medium ">Total (HT)</span>
          </td>
          <td
            className={`py-4 font-bold text-right ${
              invoiceTotal !== undefined
                ? invoiceTotal !== totalPrice
                  ? "text-red-500"
                  : "text-green-500"
                : "text-muted-foreground"
            }`}
          >
            {totalPrice && formatNumber(totalPrice)} MAD
          </td>
        </tr>

        <tr className="border-b border-muted/20">
          <td className="py-4 text-right">
            <span className="font-medium">TVA (20%)</span>
          </td>
          <td className="py-4 text-muted-foreground text-right">
            {formatNumber(tva)} MAD
          </td>
        </tr>
        <tr className="border-b border-muted/20">
          <td className="py-4 text-right">
            <span className="font-medium t">Total (TTC)</span>
          </td>
          <td className="py-4 text-muted-foreground font-bold text-right">
            {formatNumber(totalTTC)} MAD
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default PriceDetailsBar;
