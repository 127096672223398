import React, { useState } from "react";
import { TabsContent } from "./ui/tabs";
import { Label } from "./ui/label";
import { Button } from "./ui/button";
import { DialogFooter } from "./ui/dialog";
import { Input } from "./ui/input";
import { getDownloadLink } from "@/lib/utils";
import { product_assign_canvas_url } from "@/constants/urls";
import { ISupplier } from "@/interfaces/ISupplier";
import { toast } from "./ui/use-toast";
import { uploadProductAssignCanvas } from "@/services/ProductAssignCanvasUploadService";

import axios, { AxiosResponse } from "axios";
import { accessToken } from "@/constants/constants";
import LoaderButton from "./LoaderButton";
import { useNavigate } from "react-router-dom";
import { LoaderCircle } from "lucide-react";

interface ProductBulkAssignTabProps {
  supplier: ISupplier;
}

const ProductBulkAssignTab: React.FC<ProductBulkAssignTabProps> = ({
  supplier,
}) => {
  const [assignCanvas, setAssignCanvas] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [proofFile, setProofFile] = useState<File | null>(null);

  const navigation = useNavigate();

  const handleUpload = async (file: File, preuve: File) => {
    setIsUploading(true);

    const response = await uploadProductAssignCanvas(file, preuve, supplier.id);
    console.log("test response", response.data);
    if (response.data.success === true) {
      const link = document.createElement("a");

      link.href = response.data.data;
      link.download = "result.xlsx";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast({
        title: "Succès",
        description: response.data.message,
        variant: "success",
      });
      navigation(`/supplier/${supplier.id}`);
    } else if (response.data.success === false) {
      toast({
        title: "Erreur",
        description: response.data.message,
        variant: "destructive",
      });
      setIsUploading(false);
    }

    setIsUploading(false);
    setAssignCanvas(null);
  };

  const handleDownloadTemplate = async () => {
    setIsLoading(true);
    const response: AxiosResponse = await axios.get(
      product_assign_canvas_url + supplier.id,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    if (response.status === 200) {
      getDownloadLink(response.data.data);
    } else {
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors du téléchargement",
        variant: "destructive",
      });
    }
    setIsLoading(false);
  };

  return (
    <TabsContent value="bulk">
      <div className="grid gap-6 py-4">
        <div className="grid grid-cols-4 items-center gap-4">
          <Label className="text-right" htmlFor="supplier-id">
            Libellé du fournisseur
          </Label>
          <Input
            className="col-span-2"
            disabled
            id="supplier-id"
            value={supplier.label}
          />
        </div>

        <div className="grid grid-cols-4 items-center gap-4">
          <Label className="text-right" htmlFor="excel-template">
            Modèle
          </Label>
          <div className="col-span-2 flex items-center justify-start gap-2">
            <LoaderButton
              handleSubmit={handleDownloadTemplate}
              isLoading={isLoading}
              outline
              name="Télécharger"
            />
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Téléchargez le modèle Excel pour importer les produits
            </p>
          </div>
        </div>
        <div className="grid grid-cols-4 items-center gap-4">
          <Label className="text-right" htmlFor="excel-file">
            Fichier
          </Label>
          <div className="col-span-2 relative">
            <Button
              variant="outline"
              className="w-full justify-start hover:bg-white"
              onClick={() => {
                const input = document.createElement("input");
                input.type = "file";
                input.accept = ".xlsx";
                input.onchange = async (e) => {
                  const target = e.target as HTMLInputElement;
                  if (target.files && target.files.length > 0) {
                    const file = target.files[0];
                    setAssignCanvas(file);
                  }
                };
                input.click();
              }}
              disabled={isUploading}
            >
              <div className="flex items-center gap-2">
                {isUploading && (
                  <LoaderCircle className=" h-4 w-4 text-gray-500 animate-spin" />
                )}
                <span className="text-sm">
                  {assignCanvas?.name || "Choisir un fichier"}
                </span>
                <span className="text-xs text-gray-500">.xlsx</span>
              </div>
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-4 items-center gap-4">
          <Label className="text-right" htmlFor="excel-file">
            Email preuve
          </Label>
          <div className="col-span-2 relative">
            <Button
              variant="outline"
              className="w-full justify-start hover:bg-white"
              onClick={() => {
                const input = document.createElement("input");
                input.type = "file";
                input.accept = ".msg";
                input.onchange = async (e) => {
                  const target = e.target as HTMLInputElement;
                  if (target.files && target.files.length > 0) {
                    const file = target.files[0];
                    setProofFile(file);
                  }
                };
                input.click();
              }}
              disabled={isUploading}
            >
              <div className="flex items-center gap-2">
                {isUploading && (
                  <LoaderCircle className=" h-4 w-4 text-gray-500 animate-spin" />
                )}
                <span className="text-sm">
                  {proofFile?.name || "Choisir un fichier"}
                </span>
                <span className="text-xs text-gray-500">.msg</span>
              </div>
            </Button>
          </div>
        </div>
      </div>
      <DialogFooter>
        <div>
          <Button
            type="submit"
            onClick={() =>
              assignCanvas && proofFile && handleUpload(assignCanvas, proofFile)
            }
            disabled={!assignCanvas || isUploading || !proofFile}
          >
            {isUploading ? "Traitement..." : "Affecter"}
          </Button>
        </div>
      </DialogFooter>
    </TabsContent>
  );
};

export default ProductBulkAssignTab;
