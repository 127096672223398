import React, { useEffect } from "react";

interface SupplierStatusTagProps {
  status?: boolean;
}

interface StatusStyle {
  text: string;
  bgColor: string;
  textColor: string;
}

const SupplierStatusTag: React.FC<SupplierStatusTagProps> = ({ status }) => {
  const [statusStyle, setStatusStyle] = React.useState<StatusStyle>({
    text: "",
    bgColor: "",
    textColor: "",
  });

  useEffect(() => {
    switch (status) {
      case false:
        setStatusStyle({
          text: "Validation en attente",
          bgColor: "bg-yellow-100",
          textColor: "text-yellow-600",
        });
        break;
      case true:
        setStatusStyle({
          text: "Validé",
          bgColor: "bg-green-100",
          textColor: "text-green-600",
        });
        break;
      default:
        setStatusStyle({
          text: "Inconnu",
          bgColor: "bg-gray-100",
          textColor: "text-gray-600",
        });
        break;
    }
  }, [status]);

  return (
    <div className="flex items-center space-x-2">
      <div
        className={`rounded-md px-3 py-1 text-sm font-semibold ${statusStyle.bgColor} ${statusStyle.textColor}`}
      >
        {statusStyle.text}
      </div>
    </div>
  );
};

export default SupplierStatusTag;
