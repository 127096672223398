import axios, { AxiosResponse } from "axios";
import { list_produit_url } from "@/constants/urls";

import { IProductResponse } from "@/interfaces/IProduct";

const accessToken = sessionStorage.getItem("token");

export async function getProducts(
  keyword: string,
  pageNumber: number,
  pageSize: number,
  category1: string,
  category2: string,
  category3: string
): Promise<AxiosResponse<IProductResponse>> {
  try {
    const response: AxiosResponse<IProductResponse> = await axios({
      url: list_produit_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        keyword: keyword,
        pageNumber: pageNumber,
        pageSize: pageSize,
        orderBy: [],
        idNiveau3: category3,
        idNiveau2: category2,
        idNiveau1: category1,
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
