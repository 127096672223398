import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { ILitige } from "@/interfaces/ILitige";
import Litige from "./Litige";

interface LitigeTableProps {
  litiges: ILitige[];
}

const LitigeTable: React.FC<LitigeTableProps> = ({ litiges }) => {
  return (
    <Table>
      <TableHeader>
        <TableRow className="bg-gray-100">
          <TableHead className="sm:table-cell">N° Commande</TableHead>
          <TableHead className="sm:table-cell">N° Facture</TableHead>
          <TableHead className="sm:table-cell">Fournisseur</TableHead>
          <TableHead className="sm:table-cell text-center">
            Date de commande
          </TableHead>
          <TableHead className="sm:table-cell text-center">
            Date de réception
          </TableHead>
          <TableHead className="sm:table-cell text-center">
            Montant Facture (TTC)
          </TableHead>
          <TableHead className="sm:table-cell text-center">
            Montant BRV (TTC)
          </TableHead>
          <TableHead className="sm:table-cell">Statut</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {litiges && litiges.length > 0 ? (
          litiges.map((litige) => <Litige litige={litige} />)
        ) : (
          <TableRow>
            <TableCell colSpan={7} className="text-center">
              Aucun litige trouvé
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default LitigeTable;
