import DeliveryTable from "@/components/DeliveryTable";
import TablePagination from "@/components/TablePagination";
import TableSkeleton from "@/components/TableSkeleton";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Toaster } from "@/components/ui/toaster";
import { toast } from "@/components/ui/use-toast";
import UtilityBar from "@/components/UtilityBar";
import { IDelivery } from "@/interfaces/IDelivery";
import { IPageData } from "@/interfaces/IPageData";
import { getDeliveries } from "@/services/DeliveryListService";
import { RootState } from "@/store/store";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const DeliveryPage: React.FC = () => {
  const [deliveries, setDeliveries] = useState<IDelivery[]>([] as IDelivery[]);
  const [pageData, setPageData] = useState<IPageData>();
  const currentPage = useSelector(
    (state: RootState) => state.pageReducer.pageNumber
  );
  const searchKeyword = useSelector(
    (state: RootState) => state.searchReduer.searchText
  );
  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchDeliveries();
  }, [currentPage, searchKeyword, isRefreshing]);
  // comment 1
  const fetchDeliveries = async () => {
    const response = await getDeliveries(searchKeyword, currentPage, 10);
    if (response.status === 200) {
      setDeliveries(response.data.data as IDelivery[]);
      setPageData({
        pageSize: response.data.pageSize,
        hasNextPage: response.data.hasNextPage,
        hasPreviousPage: response.data.hasPreviousPage,
        currentPage: response.data.currentPage,
        totalCount: response.data.totalCount,
        totalPages: response.data.totalPages,
      });
    } else {
      toast({
        title: "Erreur",
        description:
          "Une erreur s'est produite lors de la récupération des réceptions",
        variant: "destructive",
      });
    }
    setIsLoading(false);
  };
  return (
    <div className="flex-1 px-12 py-3">
      <h1 className="text-2xl font-bold mb-4">Réceptions</h1>
      <UtilityBar />
      {isLoading ? (
        <TableSkeleton rows={10} cols={4} />
      ) : (
        <ScrollArea className="h-[60vh]">
          <DeliveryTable deliveries={deliveries} />
        </ScrollArea>
      )}
      <Toaster />
      {pageData && <TablePagination pageData={pageData} />}
    </div>
  );
};

export default DeliveryPage;
