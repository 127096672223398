import axios, { AxiosResponse } from "axios";
import { update_supplier_url } from "@/constants/urls";
import { ISupplier } from "@/interfaces/ISupplier";

const accessToken = sessionStorage.getItem("token");

export async function updateSupplier(
  supplier: ISupplier
): Promise<AxiosResponse> {
  try {
    const response = await axios({
      url: update_supplier_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        providerId: supplier.id,
        label: supplier.label,
        socialreason: supplier.socialreason,
        firstName: supplier.firstName,
        lastName: supplier.lastName,
        rib: supplier.rib,
        bankName: supplier.bankName,
        ice: supplier.ice,
        pattente: supplier.pattente,
        rc: supplier.rc,
        email: supplier.email,
        address: supplier.address,
        billingAddress: supplier.billingAddress,
        providerCode: supplier.providerCode,
        dureeEcheancesEnJours: supplier.dureeEcheancesEnJours,
        TypePaiment: supplier.typePaiment,
      },
      method: "PUT",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
