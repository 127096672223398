import { UserRoles_ } from "@/constants/constants";

const actions = sessionStorage.getItem("actions")?.split(",");
const role = sessionStorage.getItem("role");

enum Actions {
  AssignationProduits = "AssignationProduits",
  CreationCommandes = "CreationCommandes",
  CreationFournisseurs = "CreationFournisseurs",
  ImportBonslivraison = "ImportBonslivraison",
  ModificationCommandes = "ModificationCommandes",
  ModificationFournisseurs = "ModificationFournisseurs",
  SaisieDetailFactures = "SaisieDetailFactures",
  SaisieFactures = "SaisieFactures",
  ValidationCommandes = "ValidationCommandes",
  ValidationFournisseurs = "ValidationFournisseurs",
  ValidationLitige = "ValidationLitige",
  ValidationLitigePrix = "ValidationLitigePrix",
  ValidationLitigeQte = "ValidationLitigeQte",
}

function isAdmin(): boolean {
  return role === UserRoles_.ADMIN;
}

export function hasAction(action: Actions): boolean {
  if (isAdmin()) {
    return true;
  }
  return actions?.includes(action) ?? false;
}

export function checkCreationDesFournisseurs(): boolean {
  return isAdmin() || hasAction(Actions.CreationFournisseurs);
}

export function checkModificationDesFournisseurs(): boolean {
  return isAdmin() || hasAction(Actions.ModificationFournisseurs);
}

export function checkValidationDesFournisseurs(): boolean {
  return isAdmin() || hasAction(Actions.ValidationFournisseurs);
}

export function checkAssignationDesProduits(): boolean {
  return isAdmin() || hasAction(Actions.AssignationProduits);
}

export function checkCreationDesCommandes(): boolean {
  return isAdmin() || hasAction(Actions.CreationCommandes);
}

export function checkValidationDesCommandes(): boolean {
  return isAdmin() || hasAction(Actions.ValidationCommandes);
}

export function checkModificationDesCommandes(): boolean {
  return isAdmin() || hasAction(Actions.ModificationCommandes);
}

export function checkSaisieDesFactures(): boolean {
  return isAdmin() || hasAction(Actions.SaisieFactures);
}

export function checkSaisieDetailDesFacturesLitige(): boolean {
  return isAdmin() || hasAction(Actions.SaisieDetailFactures);
}

export function checkValidationLitigeQte(): boolean {
  return isAdmin() || hasAction(Actions.ValidationLitigeQte);
}

export function checkValidationLitigePrix(): boolean {
  return isAdmin() || hasAction(Actions.ValidationLitigePrix);
}

export function checkImportDesBonsDeLivraisonCachetes(): boolean {
  return isAdmin() || hasAction(Actions.ImportBonslivraison);
}

export function checkValidationDesLitiges(): boolean {
  return isAdmin() || hasAction(Actions.ValidationLitige);
}
