import React from 'react';
import { Skeleton } from './ui/skeleton';
import TableSkeleton from './TableSkeleton';

const OrderDetailsSkeleton: React.FC = () => {
    return (
        <div>
           <Skeleton className="w-full h-[30px] rounded-full" />
           <TableSkeleton cols={5} rows={5} />
        </div>
    );
};

export default OrderDetailsSkeleton;