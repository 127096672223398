import { IPriceHistoryItem } from "@/interfaces/IProduct";
import React from "react";
import { TableCell, TableRow } from "./ui/table";
import { formateDate } from "@/lib/utils";

interface ProductPriceHistoryItemProps {
  productHistoryItem: IPriceHistoryItem;
  activePermamentPrice?: boolean;
}
const ProductPriceHistoryItem: React.FC<ProductPriceHistoryItemProps> = ({
  productHistoryItem,
  activePermamentPrice,
}) => {
  const startDate = formateDate(productHistoryItem.startDate);
  const endDate = formateDate(productHistoryItem.endDate);
  const defaultDate = formateDate("0001-01-01T00:00:00");

  return (
    <TableRow className={`${activePermamentPrice ? "bg-gray-100" : ""}`}>
      <TableCell className={`${activePermamentPrice ? "font-semibold" : ""}`}>
        {productHistoryItem.productCode}
      </TableCell>
      <TableCell className={`${activePermamentPrice ? "font-semibold" : ""}`}>
        {productHistoryItem.unitPrice}
      </TableCell>
      <TableCell
        className={`${
          activePermamentPrice ? "font-semibold text-center" : "text-center"
        }`}
      >
        {startDate}
      </TableCell>
      <TableCell
        className={`${
          activePermamentPrice ? "font-semibold text-center" : "text-center"
        }`}
      >
        {endDate === defaultDate ? "-" : endDate}
      </TableCell>
    </TableRow>
  );
};

export default ProductPriceHistoryItem;
