import SupplierCreateDialog from "@/components/SupplierCreateDialog";
import SupplierTable from "@/components/SupplierTable";
import TablePagination from "@/components/TablePagination";
import TableSkeleton from "@/components/TableSkeleton";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Toaster } from "@/components/ui/toaster";
import { toast } from "@/components/ui/use-toast";
import UtilityBar from "@/components/UtilityBar";
import { IPageData } from "@/interfaces/IPageData";
import { ISupplier } from "@/interfaces/ISupplier";
import { checkCreationDesFournisseurs } from "@/lib/roleHelper";
import { getSuppliers } from "@/services/SupplierListService";
import { RootState } from "@/store/store";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const SupplierPage: React.FC = () => {
  const [suppliers, setSuppliers] = useState<ISupplier[]>([] as ISupplier[]);
  const [pageData, setPageData] = useState<IPageData>();
  const currentPage = useSelector(
    (state: RootState) => state.pageReducer.pageNumber
  );
  const searchKeyword = useSelector(
    (state: RootState) => state.searchReduer.searchText
  );
  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchSuppliers();
    console.log("Suppliers fetched", suppliers);
  }, [currentPage, searchKeyword, isRefreshing]);

  const fetchSuppliers = async () => {
    const response = await getSuppliers(searchKeyword, currentPage);
    if (response.status === 200) {
      setSuppliers(response.data.data as ISupplier[]);
      setPageData({
        pageSize: response.data.pageSize,
        hasNextPage: response.data.hasNextPage,
        hasPreviousPage: response.data.hasPreviousPage,
        currentPage: response.data.currentPage,
        totalCount: response.data.totalCount,
        totalPages: response.data.totalPages,
      });
    } else {
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors du téléchargement",
        variant: "destructive",
      });
      setIsLoading(false);
      setSuppliers([]);
    }
    setIsLoading(false);
  };

  return (
    <div className="flex-1 px-12 py-3">
      <h1 className="text-2xl font-bold mb-4">Fournisseurs</h1>
      <UtilityBar hasCreateButton={checkCreationDesFournisseurs()} />
      {isLoading ? (
        <TableSkeleton rows={10} cols={4} />
      ) : (
        <>
          <ScrollArea className="h-[60vh] ">
            <SupplierTable suppliers={suppliers} />
          </ScrollArea>
          {pageData && <TablePagination pageData={pageData} />}
        </>
      )}
      <Toaster />
      <SupplierCreateDialog />
    </div>
  );
};

export default SupplierPage;
