import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "@/store/store";

// Import your page components
import DeliveryDetailsPage from "@/pages/DeliveryDetailsPage";
import OrderDetailsPage from "@/pages/OrderDetailsPage";
import DeliveryPage from "@/pages/DeliveryPage";
import InvoiceDetailsPage from "@/pages/InvoiceDetailsPage";
import InvoicePage from "@/pages/InvoicePage";
import Layout from "@/pages/Layout";
import LoginPage from "@/pages/LoginPage";
import OrderCreatePage from "@/pages/OrderCreatePage";
import OrderPage from "@/pages/OrderPage";
import ProductAssignPage from "@/pages/ProductAssignPage";
import ProductPage from "@/pages/ProductPage";
import StockDetailsPage from "@/pages/StockDetailsPage";
import StocksPage from "@/pages/StocksPage";
import SupplierDetailsPage from "@/pages/SupplierDetailsPage";
import SupplierPage from "@/pages/SupplierPage";
import UserPage from "@/pages/UserPage";
import LitigePage from "@/pages/LitigePage";
import LitigeDetailsPage from "@/pages/LitigeDetailsPage";
import NotFoundPage from "@/pages/NotFoundPage";
import NotAuthorizedPage from "@/pages/NotAuthorizedPage";
import { checkCreationDesCommandes } from "@/lib/roleHelper";
// You need to create this component
// Update this path

interface ProtectedRouteProps {
  children: React.ReactNode;
  checkPermission: () => boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  checkPermission,
}) => {
  if (!checkPermission()) {
    return <Navigate to="/not-authorized" replace />;
  }
  return <>{children}</>;
};

const Root: React.FC = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <ProductPage />
              </Layout>
            }
          />
          <Route
            path="/products"
            element={
              <Layout>
                <ProductPage />
              </Layout>
            }
          />

          <Route
            path="/orders/create"
            element={
              <ProtectedRoute checkPermission={checkCreationDesCommandes}>
                <Layout>
                  <OrderCreatePage />
                </Layout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/supplier/:id"
            element={
              <Layout>
                <SupplierDetailsPage />
              </Layout>
            }
          />

          <Route
            path="/orders/:orderId/:supplierId"
            element={
              <Layout>
                <OrderDetailsPage />
              </Layout>
            }
          />

          <Route
            path="/suppliers"
            element={
              <Layout>
                <SupplierPage />
              </Layout>
            }
          />

          <Route
            path="/suppliers/:id/assign"
            element={
              <Layout>
                <ProductAssignPage />
              </Layout>
            }
          />

          <Route
            path="/orders"
            element={
              <Layout>
                <OrderPage />
              </Layout>
            }
          />

          <Route
            path="/deliveries"
            element={
              <Layout>
                <DeliveryPage />
              </Layout>
            }
          />

          <Route
            path="/deliveries/:id"
            element={
              <Layout>
                <DeliveryDetailsPage />
              </Layout>
            }
          />

          <Route
            path="/invoices"
            element={
              <Layout>
                <InvoicePage />
              </Layout>
            }
          />

          <Route
            path="/invoices/:id"
            element={
              <Layout>
                <InvoiceDetailsPage />
              </Layout>
            }
          />

          <Route
            path="/stock"
            element={
              <Layout>
                <StocksPage />
              </Layout>
            }
          />

          <Route
            path="/litige"
            element={
              <Layout>
                <LitigePage />
              </Layout>
            }
          />

          <Route
            path="/litiges/:id"
            element={
              <Layout>
                <LitigeDetailsPage />
              </Layout>
            }
          />

          <Route
            path="/stocks/:id"
            element={
              <Layout>
                <StockDetailsPage />
              </Layout>
            }
          />

          <Route path="/login" element={<LoginPage />} />

          <Route
            path="/users"
            element={
              <Layout>
                <UserPage />
              </Layout>
            }
          />

          <Route path="/not-authorized" element={<NotAuthorizedPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Provider>
    </BrowserRouter>
  );
};

export default Root;
