import React from "react";
import { IOrderHeader } from "@/interfaces/IOrder";
import { useParams } from "react-router-dom";

interface OrderDetailsBarProps {
  orderHeaderInfo: IOrderHeader;
}

const SupplierDetailsBar: React.FC<OrderDetailsBarProps> = ({
  orderHeaderInfo,
}) => {
  const { orderId } = useParams();

  return (
    <div className="flex flex-col sm:flex-row sm:flex-wrap sm:justify-between sm:gap-6 w-full">
      {!orderId && (
        <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
          <span className="text-sm text-gray-500 dark:text-gray-400">
            Fournisseur
          </span>
          <span className="font-medium">{orderHeaderInfo?.supplierLabel}</span>
        </div>
      )}
    </div>
  );
};

export default SupplierDetailsBar;
