import axios, { AxiosResponse } from "axios";
import { draft_order_url } from "@/constants/urls";
import { IOrderDraft } from "@/interfaces/IOrder";

const accessToken = sessionStorage.getItem("token");

export async function draftOrder(draft: IOrderDraft): Promise<AxiosResponse> {
  try {
    const response: AxiosResponse = await axios({
      url: draft_order_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: draft,

      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
