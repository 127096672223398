import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { IAssignProof } from "@/interfaces/ISupplier";
import AssignProof from "./AssignProof";

interface AssignProofsTableProps {
  assignProofs: IAssignProof[];
}

const AssignProofsTable: React.FC<AssignProofsTableProps> = ({
  assignProofs,
}) => {
  return (
    <Table>
      <TableHeader>
        <TableRow className="bg-gray-100">
          <TableHead className="sm:table-cell text-center">
            Nom du fichier
          </TableHead>
          <TableHead className="sm:table-cell text-center">Date</TableHead>
          <TableHead className="sm:table-cell text-center">
            Fichier d'assignation
          </TableHead>
          <TableHead className="sm:table-cell text-center">
            Email preuve{" "}
          </TableHead>
          <TableHead className="sm:table-cell text-center">Résultat</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {assignProofs && assignProofs.length > 0 ? (
          assignProofs.map((assignProof) => <AssignProof proof={assignProof} />)
        ) : (
          <TableRow>
            <TableCell colSpan={8} className="text-center">
              Aucun historique trouvé
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default AssignProofsTable;
