import { Dialog, DialogContent } from "./ui/dialog";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { INVOICE_DETAILS_DIALOG } from "@/constants/constants";
import { closeDialog } from "@/store/reducers/dialogReducer";
import { clearOrder } from "@/store/reducers/orderReducer";
import { IInvoiceDetails } from "@/interfaces/IInvoice";
import InvoiceDetailsBar from "./InvoiceDetailsBar";
import InvoiceStatusTag from "./InvoiceStatusTag";
import { getInvoiceDetails } from "@/services/InvoiceDetailsService";
import TableSkeleton from "./TableSkeleton";
import { useEffect, useState } from "react";
import { handleDownload } from "@/lib/utils";
import { Download } from "lucide-react";
import LoaderButton from "./LoaderButton";
import { toast } from "./ui/use-toast";

interface InvoiceDetailsDialogProps {}

const InvoiceDetailsDialog: React.FC<InvoiceDetailsDialogProps> = ({}) => {
  const invoice = useSelector(
    (state: RootState) => state.invoiceReducer.invoice
  );
  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialogReducer
  );
  const isOpened = isOpen && dialogType === INVOICE_DETAILS_DIALOG;
  const dispatch = useDispatch();
  const [invoiceDetails, setInvoiceDetails] = useState<IInvoiceDetails>(
    {} as IInvoiceDetails
  );

  const [isLoading, setIsLoading] = useState(false);
  const [invoiceTotal, setInvoiceTotal] = useState<number>(0);

  const fetchInvoiceDetails = async () => {
    if (invoice.id) {
      const response = await getInvoiceDetails(invoice.id);
      if (response.status === 200) {
        setInvoiceDetails(response.data);
        setInvoiceTotal(response.data.amountTTC);
      } else {
        toast({
          title: "Erreur",
          description:
            "Une erreur s'est produite lors de la récupération des détails de la facture",
          variant: "destructive",
        });
      }
    }
    setIsLoading(false);
  };

  const handleClose = () => {
    dispatch(closeDialog());
    dispatch(clearOrder());
  };

  const handleDownloadInvoice = async () => {
    console.log("Downloading file", invoiceDetails.pathPJ);
    if (!invoiceDetails.pathPJ) return;

    handleDownload(invoiceDetails.pathPJ, "facture.png");
  };

  useEffect(() => {
    if (!isOpened) return;
    setIsLoading(true);
    fetchInvoiceDetails();
  }, [isOpened]);

  return (
    <Dialog open={isOpened} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[85em]" hasClose>
        {isLoading ? (
          <TableSkeleton cols={1} rows={2} />
        ) : (
          <div className="grid gap-6 md:gap-8 min-w-full">
            <div className="flex items-center justify-between">
              <div className="h-4 gap-4 flex-row flex align-middle">
                <h1 className="text-2xl font-bold">
                  Facture N°{invoiceDetails.invoiceNumber}
                </h1>
                <InvoiceStatusTag status={invoiceDetails.statut} />
              </div>
              <div className="flex gap-4">
                {invoiceDetails.pathPJ && (
                  <LoaderButton
                    handleSubmit={handleDownloadInvoice}
                    isLoading={isLoading}
                    name="Facture"
                    icon={<Download className="h-5 w-5 mr-2" />}
                  />
                )}
                {invoiceDetails.pathNC && (
                  <LoaderButton
                    handleSubmit={() =>
                      invoiceDetails.pathNC &&
                      window.open(invoiceDetails.pathNC, "_blank")
                    }
                    isLoading={isLoading}
                    name="Note de crédit"
                    icon={<Download className="h-5 w-5 mr-2" />}
                  />
                )}
                {invoiceDetails.pathND && (
                  <LoaderButton
                    handleSubmit={() =>
                      invoiceDetails.pathND &&
                      window.open(invoiceDetails.pathND, "_blank")
                    }
                    isLoading={isLoading}
                    name="Note de débit"
                    icon={<Download className="h-5 w-5 mr-2" />}
                  />
                )}
                {invoiceDetails.pathBap && (
                  <LoaderButton
                    handleSubmit={() =>
                      invoiceDetails.pathBap &&
                      window.open(invoiceDetails.pathBap, "_blank")
                    }
                    isLoading={isLoading}
                    name="Bon à payer"
                    icon={<Download className="h-5 w-5 mr-2" />}
                  />
                )}
              </div>
            </div>
            <InvoiceDetailsBar
              invoiceDetails={invoiceDetails}
              invoiceTotal={invoiceTotal}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default InvoiceDetailsDialog;
