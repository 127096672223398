import axios, { AxiosResponse } from "axios";
import { list_notifications_url } from "@/constants/urls";
import { INotification } from "@/interfaces/INotification";

const accessToken = sessionStorage.getItem("token");

export async function getNotifications(): Promise<
  AxiosResponse<INotification[]>
> {
  try {
    const response: AxiosResponse<INotification[]> = await axios({
      url: list_notifications_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      data: {
        protocol: "json",
        version: 1,
      },
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
