import React from "react";
import { TableCell, TableRow } from "./ui/table";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import Highlighter from "react-highlight-words";
import { IStockItem } from "@/interfaces/IStock";
import { useNavigate } from "react-router-dom";

interface StockItemProps {
  stockItem: IStockItem;
}

const StockItem: React.FC<StockItemProps> = ({ stockItem }) => {
  const navigation = useNavigate();
  const searchKeyword = useSelector(
    (state: RootState) => state.searchReduer.searchText
  );
  const handleShowDetails = () => {
    navigation("/stocks/" + stockItem.id);
  };

  return (
    <TableRow
      className="cursor-pointer hover:bg-gray-100"
      onClick={handleShowDetails}
    >
      <TableCell className="sm:table-cell ">
        <div>
          <span className=" font-semibold">SKU:&nbsp;</span>
          <Highlighter
            activeClassName="bg-yellow-200 dark:bg-yellow-500"
            searchWords={[searchKeyword]}
            autoEscape={true}
            textToHighlight={stockItem.productId}
          />
        </div>
        <div>
          <span className=" font-semibold">GTIN:&nbsp;</span>
          <Highlighter
            activeClassName="bg-yellow-200 dark:bg-yellow-500"
            searchWords={[searchKeyword]}
            autoEscape={true}
            textToHighlight={stockItem.gtin}
          />
        </div>
      </TableCell>
      <TableCell className="hidden lg:table-cell">
        <Highlighter
          highlightClassName="bg-yellow-200 dark:bg-yellow-500"
          searchWords={[searchKeyword]}
          autoEscape={true}
          textToHighlight={stockItem.productLabel}
        />
      </TableCell>
      <TableCell className="sm:table-cell text-center">
        {stockItem.quantityInitial}
      </TableCell>
      <TableCell className="sm:table-cell text-center">
        {stockItem.quantityValide}
      </TableCell>
      <TableCell className="sm:table-cell text-center">
        {stockItem.unitPriceInitial}
      </TableCell>
      <TableCell className="sm:table-cell text-center">
        {stockItem.unitPriceValide}
      </TableCell>
      <TableCell className="hidden md:table-cell">
        {stockItem.quantitySold}
      </TableCell>
      <TableCell className="hidden md:table-cell">
        {stockItem.quantityAvailable}
      </TableCell>
    </TableRow>
  );
};

export default StockItem;
