import React, { useEffect } from "react";

import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { Button } from "./ui/button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { INVOICE_CREATE_DIALOG } from "@/constants/constants";
import { closeDialog } from "@/store/reducers/dialogReducer";
import { Input } from "./ui/input";
import LoaderButton from "./LoaderButton";
import { InvoiceOrderCheck } from "@/services/InvoiceOrderService";
import { IServerResponse } from "@/interfaces/IRequestResponse";
import { LoaderCircle, Upload, X } from "lucide-react";
import { formateDate, truncateFilename } from "@/lib/utils";
import { v4 as uuidv4 } from "uuid";
import { createInvoice } from "@/services/InvoiceCreateService";
import { uploadInvoiceDoc } from "@/services/InvoiceDocUploadService";
import { IErrorResponse } from "@/interfaces/IErrorResponse";
import { toast } from "./ui/use-toast";
import { setRefreshing } from "@/store/reducers/refreshReducer";

interface InvoiceCreateDialogProps {}

const InvoiceCreateDialog: React.FC<InvoiceCreateDialogProps> = ({}) => {
  const dispatch = useDispatch();

  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialogReducer
  );
  const isOpened = isOpen && dialogType === INVOICE_CREATE_DIALOG;
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [invoiceTotal, setInvoiceTotal] = React.useState<number>(0);
  const [invoiceFiles, setInvoiceFiles] = React.useState<File[]>([]);
  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );

  const [checkResponse, setCheckResponse] = React.useState<IServerResponse>(
    {} as IServerResponse
  );
  const [invoiceNumber, setInvoiceNumber] = React.useState<string>("");

  const [orderNumber, setOrderNumber] = React.useState<string>("");

  const checkForOrder = async (orderNumber: string) => {
    setIsLoading(true);
    const response = await InvoiceOrderCheck(orderNumber);
    console.log("response", response);
    if (response !== undefined) {
      setCheckResponse(response.data);
    } else {
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de la verification",
        variant: "destructive",
      });
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  const [isLoading_, setIsLoading_] = React.useState(false);
  const handleFileChange = (files: File[]) => {
    setInvoiceFiles([...invoiceFiles, ...Array.from(files)]);
  };

  useEffect(() => {
    if (orderNumber === "") {
      setCheckResponse({} as IServerResponse);
    } else {
      const getData = setTimeout(() => {
        checkForOrder(orderNumber);
      }, 1000);
      return () => clearTimeout(getData);
    }
  }, [orderNumber]);

  const handleSubmit = async () => {
    if (checkResponse.message === null && checkResponse.data !== null) {
      setIsLoading_(true);
      const response = await createInvoice(
        invoiceNumber,
        checkResponse.data.orderId,
        invoiceTotal
      );
      if (response.status === 200) {
        await handleUploadFiles(parseInt(response.data.data));
        console.log("Invoice created", response.data);
        setInvoiceNumber("");
        setInvoiceTotal(0);
        setInvoiceFiles([]);
        handleClose();
        toast({
          title: "Succès",
          description: "Facture créée avec succès",
          variant: "success",
        });
        dispatch(setRefreshing(!isRefreshing));
      } else {
        toast({
          title: "Erreur",
          description:
            "Une erreur s'est produit lors de la création de la facture",
          variant: "destructive",
        });
        setIsLoading_(false);
      }
      setIsLoading_(false);
    }
  };
  const handleClose = () => {
    dispatch(closeDialog());
    setInvoiceNumber("");
    setOrderNumber("");
    setInvoiceTotal(0);
    setInvoiceFiles([]);
    setCheckResponse({} as IServerResponse);
  };

  const handleUploadFiles = async (invoiceId: number) => {
    if (invoiceFiles && invoiceFiles.length > 0) {
      console.log("uploading files", invoiceFiles);

      const response = await uploadInvoiceDoc(invoiceFiles, invoiceId);
      if (response.status === 200) {
        return response.data;
      } else {
        const error = response as unknown as IErrorResponse;
        const message = error.messages[0] || error.exception;
        toast({
          title: "Erreur",
          description: message,
          variant: "destructive",
        });
      }
    }
  };

  useEffect(() => {
    if (
      checkResponse.message === null &&
      invoiceTotal > 0 &&
      invoiceFiles.length > 0 &&
      invoiceNumber !== ""
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [checkResponse, invoiceTotal, invoiceFiles, invoiceNumber]);

  return (
    <Dialog open={isOpened} onOpenChange={handleClose}>
      <DialogContent className="w-[600px]">
        <DialogHeader>
          <DialogTitle>Facture</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4">
          <div className="flex flex-col items-start space-y-1 gap-1">
            <span className="text-sm font-medium">N° Commande *</span>

            <div className="flex items-center w-full gap-4">
              <Input
                className="flex-grow"
                id="name"
                placeholder="N° Commande"
                value={orderNumber}
                onChange={(e) => setOrderNumber(e.target.value)}
                disabled={isLoading}
              />
              {isLoading && (
                <LoaderCircle className="animate-spin" color="grey" size={20} />
              )}
            </div>
            {checkResponse.message !== null && (
              <span className="text-sm text-red-500">
                {checkResponse.message}
              </span>
            )}
          </div>
        </div>
        {checkResponse &&
          checkResponse.message === null &&
          checkResponse.data !== null && (
            <>
              <div className="flex flex-row justify-between gap-4">
                <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
                  <span className="text-sm font-medium ">Fournisseur </span>
                  <span className="text-sm text-gray-500">
                    {checkResponse.data.providerLibelle}
                  </span>
                </div>
                <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
                  <span className="text-sm font-medium ">Date commande </span>
                  <span className="text-sm text-gray-500">
                    {formateDate(checkResponse.data.creationDate)}
                  </span>
                </div>
                <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
                  <span className="text-sm font-medium ">Date réception</span>
                  <span className="text-sm text-gray-500">
                    {formateDate(checkResponse.data.receptionDate)}
                  </span>
                </div>
              </div>
              <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
                <span className="text-sm font-medium ">N° Réception</span>
                <Input
                  id="name"
                  value={checkResponse.data.cdaNumber}
                  disabled
                />
              </div>
              <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
                <span className="text-sm font-medium">N° Facture *</span>
                <Input
                  id="name"
                  placeholder="N° Facture"
                  value={invoiceNumber}
                  onChange={(e) => setInvoiceNumber(e.target.value)}
                />
              </div>
              <div className="flex flex-row justify-between gap-4">
                <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
                  <span className="text-sm font-medium ">Total TTC *</span>
                  <Input
                    id="name"
                    type="number"
                    placeholder="Total"
                    onChange={(e) => setInvoiceTotal(Number(e.target.value))}
                    value={invoiceTotal === 0 ? "" : invoiceTotal}
                  />
                </div>
                <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
                  <span className="text-sm font-medium ">TVA</span>
                  <Input
                    id="name"
                    placeholder="Total"
                    value={(invoiceTotal - invoiceTotal / (1 + 0.2)).toFixed(2)}
                    disabled
                  />
                </div>
                <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
                  <span className="text-sm font-medium ">Total HT</span>
                  <Input
                    id="name"
                    placeholder="Total"
                    disabled
                    value={(invoiceTotal / (1 + 0.2)).toFixed(2)}
                  />
                </div>
              </div>

              {invoiceFiles.length > 0 && (
                <div className="space-y-2">
                  <span className="text-sm font-medium ">Pièces jointes</span>
                  {invoiceFiles.map((file) => (
                    <div className="flex items-center ">
                      <Input
                        key={uuidv4()}
                        value={truncateFilename(file.name, 15, 15)}
                        disabled
                      />
                      <Button
                        variant="outline"
                        className="hover:bg-white border-none"
                        onClick={() =>
                          setInvoiceFiles(
                            invoiceFiles.filter((f) => f !== file)
                          )
                        }
                      >
                        <X className="w-4 h-4" />
                      </Button>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        <div className="flex  flex-1 justify-between mt-4">
          <div className="col-span-3 flex items-center justify-start gap-2">
            {checkResponse &&
              checkResponse.message === null &&
              checkResponse.data !== null && (
                <Button
                  variant="outline"
                  className="gap-2"
                  onClick={() => {
                    const input = document.createElement("input");
                    input.type = "file";
                    input.multiple = true;
                    input.accept = [".png", ".jpg", ".jpeg", ".pdf"].join(",");
                    input.onchange = (e) => {
                      const target = e.target as HTMLInputElement;
                      if (target.files && target.files.length > 0) {
                        handleFileChange(Array.from(target.files));
                      }
                    };
                    input.click();
                  }}
                >
                  <Upload className="w-4 h-4" />
                  <span className="text-sm">Importer</span>
                </Button>
              )}
          </div>
          <div className="flex gap-2">
            <Button onClick={handleClose} variant="outline">
              Annuler
            </Button>
            <LoaderButton
              handleSubmit={handleSubmit}
              isLoading={isLoading_}
              name="Enregistrer"
              disabled={isDisabled}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InvoiceCreateDialog;
