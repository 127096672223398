import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { IUser } from "@/interfaces/IUser";
import User from "./User";

interface UserTableProps {
  users: IUser[];
}

const UserTable: React.FC<UserTableProps> = ({ users }) => {
  useEffect(() => {
    console.log("Fetching users");
  }, [users]);
  return (
    <Table>
      <TableHeader>
        <TableRow className="bg-gray-100">
          <TableHead className="sm:table-cell">Identifiant</TableHead>
          <TableHead className="hidden lg:table-cell">Nom</TableHead>
          <TableHead className="hidden lg:table-cell">Prénom</TableHead>
          <TableHead className="sm:table-cell">Email</TableHead>
          <TableHead className="sm:table-cell">Rôle</TableHead>
          <TableHead className="sm:table-cell text-center">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {users && users.length > 0 ? (
          users.map((user) => <User key={user.id} user={user} />)
        ) : (
          <TableRow>
            <TableCell colSpan={8} className="text-center">
              Aucun utilisateur trouvé
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default UserTable;
