import React, { useEffect, useState } from "react";
import { TabsContent } from "./ui/tabs";
import { Label } from "./ui/label";
import { Button } from "./ui/button";
import { Input } from "./ui/input";

import {
  IPriceHistoryItem,
  IProduct,
  IProductToAssign,
} from "@/interfaces/IProduct";
import {
  checkAssignedProduct,
  convertIProductToAssignToAssignementItem_,
  getDisabledDates,
  getEndDate,
  getStartDate,
} from "@/lib/utils";
import { openDialog } from "@/store/reducers/dialogReducer";
import { useDispatch, useSelector } from "react-redux";
import { setRefreshing } from "@/store/reducers/refreshReducer";
import { RootState } from "@/store/store";
import { toast } from "./ui/use-toast";
import LoaderButton from "./LoaderButton";
import DateSelect from "./DateSelect";
import UnitPriceSelect from "./UnitPriceSelect";
import { ChevronsUpDownIcon } from "lucide-react";
import {
  PRODUCT_LIST_DIALOG,
  PRODUCT_PRICE_HISTORY_DIALOG,
} from "@/constants/constants";
import ProductListDialog_ from "./ProductListDialog_";
import { Toaster } from "./ui/toaster";
import { useNavigate, useParams } from "react-router-dom";
import { setProduct } from "@/store/reducers/productReducer";
import { ISupplier, ISupplierProduct } from "@/interfaces/ISupplier";
import ProductPriceHistoryDialog from "./ProductPriceHistoryDialog";
import ProductAssignItemsTable from "./ProductAssignItemsTable";
import { assignProductsToSupplier_ } from "@/services/ProductAssignService_";
import DateConflictDialog from "./DateConflictDialog";

interface ProductSingleAssignTabProps {
  products: ISupplierProduct[];
  supplier: ISupplier;
}

const ProductSingleAssignTab: React.FC<ProductSingleAssignTabProps> = ({
  products,
  supplier,
}) => {
  const { id } = useParams();
  const navigation = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState<IProduct>(
    {} as IProduct
  );
  const product = useSelector(
    (state: RootState) => state.productReducer.product
  );
  const [productButtonText, setProductButtonText] = useState<string>("");
  const [priceHistoryItems, setPriceHistoryItems] = useState<
    IPriceHistoryItem[]
  >([] as IPriceHistoryItem[]);
  const [selectedProducts, setSelectedProducts] = useState<IProductToAssign[]>(
    [] as IProductToAssign[]
  );
  const [validProduct, setValidProduct] = useState<IProduct>({} as IProduct);
  const [productToAssign, setProductToAssign] = useState<IProductToAssign>(
    {} as IProductToAssign
  );
  const dispatch = useDispatch();
  const [unitPrice, setUnitPrice] = useState<number>(0);
  const [selectedStartDate, setSelectedStartDate] = useState<Date>({} as Date);
  const [selectedEndDate, setSelectedEndDate] = useState<Date>({} as Date);
  const [isLoading, setIsLoading] = useState(false);
  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );
  const [confictItems, setConflictItems] = useState<IPriceHistoryItem[]>([]);
  const [open, setOpen] = useState(false);
  const [productExists, setProductExists] = useState(false);
  const [promotionUnitPrice, setPromotionUnitPrice] = useState<number>(0);
  const [editChecked, setEditChecked] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [baseUnitPrice, setBaseUnitPrice] = useState<number>(0);

  console.log("selectedProductaaa", selectedProduct);
  const handleSubmit = async () => {
    if (editChecked) {
      toast({
        title: "Erreur",
        description: "Veuillez remplir les champs obligatoires",
        variant: "destructive",
      });
    } else if (selectedProducts.length > 0) {
      const items = convertIProductToAssignToAssignementItem_(selectedProducts);
      console.log("Items to assign", items);
      setIsLoading(true);
      const response = await assignProductsToSupplier_(
        parseInt(id || ""),
        items
      );
      if (response.status === 200) {
        dispatch(setRefreshing(!isRefreshing));
        toast({
          title: "Produit(s) assigné(s)",
          description: "Les produits ont été assignés avec succès",
          variant: "success",
        });
        resetInputs();
        navigation("/supplier/" + id);
      } else {
        toast({
          title: "Erreur",
          description: response.data.message || "Erreur lors de l'assignation",
          variant: "destructive",
        });
        resetInputs();
        setIsLoading(false);
      }
      setIsLoading(false);
    } else {
      toast({
        title: "Erreur",
        description: "Aucun produit sélectionné",
        variant: "destructive",
      });
    }
  };

  const handleShowProductList = () => {
    dispatch(openDialog(PRODUCT_LIST_DIALOG));
  };

  const handleAddProductWithPermanentPrice = () => {
    const exists = checkAssignedProduct(selectedProduct, selectedProducts);
    if (selectedProduct.productId && !exists) {
      setSelectedProducts([
        ...selectedProducts,
        {
          product: selectedProduct,
          unitPrice: unitPrice,
          startDate: selectedStartDate.toISOString(),
          endDate: null as any,
        },
      ]);
      resetInputs();
      dispatch(setProduct({} as IProduct));
      console.log("added with permanent price");
    }
  };
  const handleAddProductWithPromotionPrice = () => {
    console.log({ products });
    const exists = checkAssignedProduct(selectedProduct, selectedProducts);
    if (selectedProduct.productId && !exists && promotionUnitPrice > 0) {
      if (confictItems.length > 0) {
        toast({
          title: "Erreur",
          description: "Conflit de dates",
          variant: "destructive",
        });
        setOpen(true);
        return;
      }
      setSelectedProducts([
        ...selectedProducts,
        {
          product: selectedProduct,
          unitPrice: promotionUnitPrice,
          startDate: selectedStartDate.toISOString(),
          endDate: selectedEndDate.toISOString(),
        },
      ]);

      resetInputs();
      dispatch(setProduct({} as IProduct));
      console.log("added with promotion price");
    }
  };

  const resetInputs = () => {
    console.log("resetting inputs");
    setProductButtonText("Sélectionner un produit");
    setUnitPrice(0);
    setPromotionUnitPrice(0);
    setSelectedStartDate(new Date());
    setSelectedProduct({} as IProduct);
    dispatch(setProduct({} as IProduct));
  };

  useEffect(() => {
    setProductButtonText(product.title);
    setSelectedProduct(product);

    const exists = products?.find(
      (prod) => prod.productId === product.productId
    );
    if (exists) {
      setValidProduct(product);
      setUnitPrice(exists.priceUnitHT);
      setProductExists(true);
      setSelectedStartDate(getStartDate(new Date()));
      setSelectedEndDate(getEndDate(new Date()));
      setBaseUnitPrice(exists.priceUnitHT);
    } else {
      setSelectedStartDate(getStartDate(new Date(), true));
      setUnitPrice(0);
      setValidProduct({} as IProduct);
      setProductExists(false);
      setEditChecked(false);
      setPromotionUnitPrice(0);
      setSelectedEndDate(null as any);
    }
  }, [product]);

  const isButtonDisabled = (): boolean => {
    if ((!editChecked && !productExists) || (editChecked && productExists)) {
      return !selectedProduct.productId || unitPrice === 0;
    } else {
      return (
        !selectedProduct.productId ||
        promotionUnitPrice === 0 ||
        promotionUnitPrice >= unitPrice ||
        !selectedStartDate ||
        !selectedEndDate
      );
    }
  };
  useEffect(() => {
    const exists = products?.find(
      (prod) => prod.productId === product.productId
    );
    if (exists) {
      const newEndDate = getStartDate(selectedStartDate);

      if (!selectedEndDate || selectedEndDate <= selectedStartDate) {
        setSelectedEndDate(newEndDate);
      }
    }
  }, [selectedStartDate, selectedEndDate]);

  useEffect(() => {
    setButtonDisabled(isButtonDisabled());
  }, [
    selectedProduct,
    unitPrice,
    promotionUnitPrice,
    selectedStartDate,
    selectedEndDate,
    editChecked,
    productExists,
  ]);
  useEffect(() => {
    resetInputs();
    dispatch(setProduct({} as IProduct));
  }, []);

  useEffect(() => {
    if (!productExists) {
      setUnitPrice(0);
    }

    if (productExists && !editChecked) {
      setUnitPrice(baseUnitPrice);
    }
  }, [productExists, editChecked]);

  useEffect(() => {
    if (
      selectedProduct.productId &&
      unitPrice > 0 &&
      selectedStartDate &&
      selectedEndDate &&
      unitPrice > promotionUnitPrice
    ) {
      setProductToAssign({
        product: selectedProduct,
        unitPrice: promotionUnitPrice,
        startDate: selectedStartDate.toISOString(),
        endDate: selectedEndDate?.toISOString(),
      });
    }
  }, [
    selectedProduct,
    unitPrice,
    promotionUnitPrice,
    selectedStartDate,
    selectedEndDate,
  ]);

  return (
    <div>
      <TabsContent value="single">
        <div className="grid gap-6 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label className="text-right" htmlFor="supplier-id">
              Libellé Fournisseur :
            </Label>
            <Input
              className="col-span-3"
              id="supplier-id"
              value={supplier.label || ""}
              disabled
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label className="text-right" htmlFor="search">
              Produit *
            </Label>
            <div className="col-span-3 relative">
              <Button
                className="w-full justify-between col-span-3"
                role="combobox"
                variant="outline"
                onClick={handleShowProductList}
              >
                <span className="truncate">
                  {" "}
                  {productButtonText || "Sélectionner un produit"}
                </span>
                <ChevronsUpDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
              </Button>
            </div>
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <UnitPriceSelect
              unitPrice={unitPrice as number}
              setUnitPrice={setUnitPrice}
              disabled={productExists}
              placeholder="Entrez le prix permanent"
              label="Prix permanent d'achat"
              hadSwitch
              checked={editChecked}
              setChecked={setEditChecked}
            />

            {productExists && (
              <Button
                variant="outline"
                onClick={() => {
                  dispatch(setProduct(selectedProduct));
                  dispatch(openDialog(PRODUCT_PRICE_HISTORY_DIALOG));
                }}
              >
                Historique
              </Button>
            )}
          </div>
          {productExists && !editChecked && (
            <div className="grid grid-cols-4 items-center gap-4 flex-row">
              <UnitPriceSelect
                unitPrice={promotionUnitPrice as number}
                setUnitPrice={setPromotionUnitPrice}
                placeholder="Entrez le prix promotionnel"
                label="Prix promotionnel d'achat"
              />
              {promotionUnitPrice !== 0 && promotionUnitPrice >= unitPrice && (
                <span className="text-grey text-xs">
                  Le prix promotionnel ne peut pas être supérieur au prix
                  permanent
                </span>
              )}
            </div>
          )}
          {productExists && !editChecked && (
            <>
              <DateSelect
                label="Date début *"
                selectedDate={selectedStartDate}
                setSelectedDate={setSelectedStartDate}
                disabledDates={getDisabledDates(1, new Date())}
                gridLayout
              />
              <DateSelect
                label="Date fin *"
                setSelectedDate={setSelectedEndDate}
                selectedDate={selectedEndDate}
                disabledDates={getDisabledDates(1, selectedStartDate)}
                gridLayout
              />
            </>
          )}

          <div className="grid grid-cols-4 items-center gap-4">
            <Label className="text-right" htmlFor="search"></Label>
            <div className="col-span-3 relative">
              <Button
                className="col-span-4"
                onClick={
                  (editChecked && productExists) || !productExists
                    ? handleAddProductWithPermanentPrice
                    : handleAddProductWithPromotionPrice
                }
                disabled={buttonDisabled}
              >
                Ajouter
              </Button>
            </div>
          </div>

          <ProductAssignItemsTable selectedProducts={selectedProducts} />
        </div>

        <div className="flex justify-end gap-2">
          <LoaderButton
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            name="Assigner"
            disabled={!selectedProducts.length}
          />
        </div>
      </TabsContent>
      <ProductListDialog_ />
      <ProductPriceHistoryDialog
        supplier={supplier}
        product={validProduct}
        setPriceHistoryItems_={setPriceHistoryItems}
      />

      <DateConflictDialog
        open={open}
        setOpen={setOpen}
        priceHistoryItems={priceHistoryItems}
        productToAssign={productToAssign}
        setConflictItems={setConflictItems}
        setSelectedStartDate={setSelectedStartDate}
        setSelectedEndDate={setSelectedEndDate}
        resetInputs={resetInputs}
      />
      <Toaster />
    </div>
  );
};

export default ProductSingleAssignTab;
