import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { Toaster } from "@/components/ui/toaster";
import OrderAddBar from "@/components/OrderAddBar";
import OrderItemsEditTable from "@/components/OrderItemEditTable";
import { Item } from "@/interfaces/Item";
import SupplierSelectDialog from "@/components/SupplierSelectDialog";
import {
  ORDER_BATCH_UPLOAD_DIALOG,
  SUPPLIER_SELECT_DIALOG,
} from "@/constants/constants";
import { openDialog } from "@/store/reducers/dialogReducer";
import { Button } from "@/components/ui/button";
import { Boxes } from "lucide-react";
import PriceDetailsBar from "@/components/PriceDetailsBar";
import SupplierDetailsBar from "@/components/SupplierDetailsBar";
import OrderBatchUploadDialog from "@/components/OrderBatchUploadDialog";
import { IOrderHeader } from "@/interfaces/IOrder";
import OrderStatusTag from "@/components/OrderStatusTag";

const OrderCreatePage: React.FC = () => {
  const [orderHeaderInfo, setOrderHeaderInfo] = React.useState<IOrderHeader>(
    {} as IOrderHeader
  );
  const dispatch = useDispatch();
  const selectedSupplier = useSelector(
    (state: RootState) => state.supplierReducer.supplier
  );
  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialogReducer
  );
  const isOpened = isOpen && dialogType === SUPPLIER_SELECT_DIALOG;
  const [selectedProducts, setSelectedProducts] = React.useState<Item[]>([]);

  const [isLoadingDrafts, setIsLoadingDrafts] = React.useState<boolean>(false);

  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );

  useEffect(() => {}, [selectedProducts, isRefreshing]);

  useEffect(() => {
    dispatch(openDialog(SUPPLIER_SELECT_DIALOG));
  }, []);

  useEffect(() => {
    console.log("selected products", selectedProducts);
  }, [selectedProducts]);

  return (
    <div className="flex-1 px-12 py-3">
      <div className="grid gap-6 md:gap-8">
        <div className="flex items-center justify-between">
          {orderHeaderInfo.id && (
            <div className="h-4 gap-4 flex-row flex align-middle">
              <h1 className="text-2xl font-bold">
                Commande N°{orderHeaderInfo.orderNumber}
              </h1>
              <OrderStatusTag status={orderHeaderInfo.statusId} />
            </div>
          )}
          {selectedSupplier.id !== 0 && isOpened == false && (
            <Button
              variant="default"
              onClick={() => dispatch(openDialog(ORDER_BATCH_UPLOAD_DIALOG))}
            >
              <Boxes className="w-5 h-5 text-white" />
              <span className="ml-2">Commande en masse</span>
            </Button>
          )}
        </div>

        {selectedSupplier.id !== 0 && isOpened == false && (
          <>
            <SupplierDetailsBar orderHeaderInfo={orderHeaderInfo} />
            <OrderAddBar
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              supplier={selectedSupplier}
              orderHeaderInfo={orderHeaderInfo}
              setIsLoadingDrafts={setIsLoadingDrafts}
            />
            <OrderItemsEditTable
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              selectedSupplier={selectedSupplier}
              orderHeaderInfo={orderHeaderInfo}
              isLoadingDrafts={isLoadingDrafts}
            />

            <div className="flex flex-1 justify-end">
              <div className="w-1/3">
                <PriceDetailsBar
                  totalPrice={selectedProducts.reduce(
                    (acc, item) => acc + item.unitPriceHT * item.quantity,
                    0
                  )}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <SupplierSelectDialog setOrderHeader={setOrderHeaderInfo} />
      <OrderBatchUploadDialog
        supplier={selectedSupplier}
        orderHeader={orderHeaderInfo}
      />
      <Toaster />
    </div>
  );
};

export default OrderCreatePage;
