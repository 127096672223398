import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { closeDialog } from "@/store/reducers/dialogReducer";
import { IProduct } from "@/interfaces/IProduct";
import { PRODUCT_LIST_DIALOG } from "@/constants/constants";
import TableSkeleton from "./TableSkeleton";
import { ScrollArea } from "./ui/scroll-area";
import ProductsTable from "./ProductsTable";
import TablePagination from "./TablePagination";
import { getProducts } from "@/services/ProductListService";
import { IPageData } from "@/interfaces/IPageData";
import UtilityBar from "./UtilityBar";
import { setProduct } from "@/store/reducers/productReducer";
import { setFilterDisabled } from "@/store/reducers/filterDisableReducer";
import { setDefaultFilter } from "@/store/reducers/filterReducer";
import { toast } from "./ui/use-toast";

interface ProductListDialogProps {}

const ProductListDialog_: React.FC<ProductListDialogProps> = ({}) => {
  const dispatch = useDispatch();
  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialogReducer
  );
  const filter = useSelector((state: RootState) => state.filterReducer.filter);
  const isOpened = isOpen && dialogType === PRODUCT_LIST_DIALOG;

  const [products, setProducts] = useState<IProduct[]>([] as IProduct[]);
  const [pageData, setPageData] = useState<IPageData>();
  const currentPage = useSelector(
    (state: RootState) => state.pageReducer.pageNumber
  );
  const searchKeyword = useSelector(
    (state: RootState) => state.searchReduer.searchText
  );
  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const fetchProducts = async () => {
    const response = await getProducts(
      searchKeyword,
      currentPage,
      10,
      filter.category1.id,
      filter.category2.id,
      filter.category3.id
    );
    if (response.status === 200) {
      setProducts(response.data.data as IProduct[]);
      setPageData({
        pageSize: response.data.pageSize,
        hasNextPage: response.data.hasNextPage,
        hasPreviousPage: response.data.hasPreviousPage,
        currentPage: response.data.currentPage,
        totalCount: response.data.totalCount,
        totalPages: response.data.totalPages,
      });
    } else {
      toast({
        title: "Erreur",
        description:
          "Une erreur s'est produite lors de la récupération des produits",
        variant: "destructive",
      });
    }
    setIsLoading(false);
    dispatch(setFilterDisabled(false));
  };

  useEffect(() => {
    setIsLoading(true);
    fetchProducts();
    // console.log("Products fetched", products);
  }, [currentPage, searchKeyword, isRefreshing]);

  useEffect(() => {
    if (isOpened === true) {
      dispatch(setProduct({} as IProduct));
      dispatch(setDefaultFilter());
    }
  }, [isOpened]);

  useEffect(() => {
    dispatch(setFilterDisabled(true));
    setIsLoading(true);
    fetchProducts();
  }, [filter]);

  return (
    <Dialog open={isOpened} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-full max-h-[calc(100vh_-_theme(spacing.16))] overflow-auto">
        <DialogHeader>
          <DialogTitle>Affectation des produits</DialogTitle>
          <DialogDescription>
            Choisissez un produit à affecter
          </DialogDescription>
          <UtilityBar
            hasCreateButton={false}
            hasSearchButton={true}
            hasFilterButton={true}
            hasProductExportButton={false}
          />
        </DialogHeader>

        {isLoading ? (
          <TableSkeleton rows={9} cols={9} />
        ) : (
          <>
            <ScrollArea className="h-[60vh]">
              <ProductsTable products={products} forAssign />
            </ScrollArea>
            <DialogFooter>
              <div className="flex-1">
                {pageData && <TablePagination pageData={pageData} />}
              </div>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ProductListDialog_;
