import StockDetailsBar from "@/components/StockDetailsBar";
import StockMovementTable from "@/components/StockMovementTable";
import TableSkeleton from "@/components/TableSkeleton";
import { Toaster } from "@/components/ui/toaster";
import { toast } from "@/components/ui/use-toast";
import { IMovement, IStockDetails } from "@/interfaces/IStock";
import { getStockDetails } from "@/services/StockDetailsService";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const StockDetailsPage: React.FC = () => {
  const { id } = useParams();
  const [stockDetails, setStockDetails] = useState<IStockDetails>(
    {} as IStockDetails
  );
  const [movements, setMovements] = useState<IMovement[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  console.log(id);
  useEffect(() => {
    setIsLoading(true);
    fetchStockDetails();
  }, [id]);

  const fetchStockDetails = async () => {
    if (id) {
      const response = await getStockDetails(parseInt(id));
      if (response.status === 200) {
        setStockDetails(response.data);
        setMovements(response.data.movements);
      } else {
        toast({
          title: "Erreur",
          description: "Une erreur s'est produite lors du téléchargement",
          variant: "destructive",
        });
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="flex-1 px-12 py-3">
      {isLoading ? (
        <TableSkeleton rows={10} cols={6} />
      ) : (
        <div className="grid gap-6 md:gap-8">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold">Details Stock</h1>
          </div>
          <StockDetailsBar stockDetails={stockDetails} />

          <div className="h-4">
            <h1 className="text-xl font-bold">Mouvements</h1>
          </div>
          <StockMovementTable movements={movements} />
        </div>
      )}
      <Toaster />
    </div>
  );
};

export default StockDetailsPage;
