import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Button } from "./ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { UPDATE_USER_DIALOG, UserRoles_ } from "@/constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { closeDialog } from "@/store/reducers/dialogReducer";
import { updateUser } from "@/services/UserUpdateService";
import { toast } from "./ui/use-toast";
import { setRefreshing } from "@/store/reducers/refreshReducer";
import LoaderButton from "./LoaderButton";
import { Checkbox } from "./ui/checkbox";
import { getRolesAndActions } from "@/services/UserRolesListService";
import { IUserAction, IUserRole_ } from "@/interfaces/IUser";

const UserEditDialog: React.FC = () => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [actions, setActions] = useState<IUserAction[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );
  const dispatch = useDispatch();
  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialogReducer
  );
  const isOpened = isOpen && dialogType === UPDATE_USER_DIALOG;
  const [selectedActions, setSelectedActions] = useState<IUserAction[]>([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [userRoles, setUserRoles] = useState<IUserRole_[]>([]);
  const [isLoadingRoles, setIsLoadingRoles] = useState(false);

  const user = useSelector((state: RootState) => state.userReducer.user);

  const fetchRolesWithActions = async () => {
    setIsLoadingRoles(true);
    const response = await getRolesAndActions();
    if (response.status === 200) {
      setUserRoles(response.data.data);
    } else {
      toast({
        title: "Erreur",
        description: "Erreur lors de la récupération des rôles",
        variant: "destructive",
      });
    }
    setIsLoadingRoles(false);
  };

  const handleClose = () => {
    dispatch(closeDialog());
    setFirstName("");
    setLastName("");
    setEmail("");
    setRole("");
    setSelectedActions([]);
  };

  const chunkActions = (arr: IUserAction[], size: number) => {
    return Array.from({ length: Math.ceil(arr.length / size) }, (_v, i) =>
      arr.slice(i * size, i * size + size)
    );
  };
  const actionRows = chunkActions(actions, 3);

  const handleActionChange = (action: IUserAction) => {
    setSelectedActions((prev) =>
      prev.some((a) => a.id === action.id)
        ? prev.filter((a) => a.id !== action.id)
        : [...prev, action]
    );
  };

  const handleRoleChange = (selectedRole: string) => {
    setRole(selectedRole);
    const roleData = userRoles.find((r) => r.role === selectedRole);
    if (roleData) {
      setActions(roleData.actions);
      setSelectedActions(roleData.actions);
    } else {
      setActions([]);
      setSelectedActions([]);
    }
  };

  const handleUpdateUser = async () => {
    if (user.id) {
      setIsLoading(true);
      const response = await updateUser({
        id: user.id,
        firstName,
        lastName,
        email,
        role,
        actions: selectedActions,
      });
      if (response.status === 200) {
        dispatch(setRefreshing(!isRefreshing));
        toast({
          title: "Success",
          description: "Utilisateur modifié avec succès",
          variant: "success",
        });
        handleClose();
      } else {
        toast({
          title: "Erreur",
          description: "Erreur lors de la modification de l'utilisateur",
          variant: "destructive",
        });
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRolesWithActions();
  }, []);

  useEffect(() => {
    setIsDisabled(!(firstName && lastName && email && role));
  }, [firstName, lastName, email, role]);

  useEffect(() => {
    if (user && isOpened) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
      setRole(user.role);
      setSelectedActions(user.actions || []);
    }
  }, [user, isOpened]);

  useEffect(() => {
    if (role) {
      const roleData = userRoles.find((r) => r.role === role);
      if (roleData) {
        setActions(roleData.actions);
      }
    }
  }, [role, userRoles]);

  return (
    <Dialog open={isOpened} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Modifier un utilisateur</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="firstName">Nom *</Label>
              <Input
                id="firstName"
                placeholder="Entrer un nom"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="lastName">Prénom *</Label>
              <Input
                id="lastName"
                placeholder="Entrer un prénom"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="space-y-2">
            <Label htmlFor="email">Email *</Label>
            <Input
              id="email"
              type="email"
              placeholder="Entrer un email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="role">Rôle *</Label>
            <Select onValueChange={handleRoleChange} value={role}>
              <SelectTrigger>
                <SelectValue
                  placeholder={
                    isLoadingRoles ? "Chargement..." : "Selectionner un rôle"
                  }
                />
              </SelectTrigger>
              <SelectContent>
                {userRoles.map((roleData) => (
                  <SelectItem key={roleData.role} value={roleData.role}>
                    {roleData.role}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          {role && role !== UserRoles_.ADMIN && (
            <div className="space-y-4">
              <Label>Privilèges *</Label>
              <div className="flex flex-col space-y-4">
                {actionRows.map((row, rowIndex) => (
                  <div key={rowIndex} className="flex justify-between">
                    {row.map((action) => (
                      <div
                        key={action.id}
                        className="flex items-center space-x-2"
                      >
                        <Checkbox
                          id={action.id}
                          checked={selectedActions.some(
                            (a) => a.id === action.id
                          )}
                          onCheckedChange={() => handleActionChange(action)}
                        />
                        <Label htmlFor={action.id} className="text-sm">
                          {action.actionName}
                        </Label>
                      </div>
                    ))}
                    {[...Array(3 - row.length)].map((_, index) => (
                      <div
                        key={`placeholder-${rowIndex}-${index}`}
                        className="invisible"
                      >
                        <Checkbox />
                        <Label className="text-sm">Placeholder</Label>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <DialogFooter className="flex flex-col sm:flex-row gap-2 sm:gap-0">
          <Button variant="outline" className="mr-2" onClick={handleClose}>
            Annuler
          </Button>
          <LoaderButton
            name="Modifier"
            isLoading={isLoading}
            disabled={isDisabled}
            handleSubmit={handleUpdateUser}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default UserEditDialog;
