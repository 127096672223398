import { createSlice } from '@reduxjs/toolkit';

interface DialogState {
    isOpen: boolean;
    dialogType?: string;
}

const initialState: DialogState = {
    isOpen: false,
};

const dialogSlice = createSlice({
    name: 'dialog',
    initialState,
    reducers: {
        openDialog: (state, action) => {
            state.isOpen = true;
            state.dialogType = action.payload;
        },
        closeDialog: (state) => {
            state.isOpen = false;
            state.dialogType = undefined;
        },
        toggleDialog: (state) => {
            state.isOpen = !state.isOpen;
            state.dialogType = undefined;
        },
    },
});

export const { openDialog, closeDialog, toggleDialog } = dialogSlice.actions;

export default dialogSlice.reducer;