import axios, { AxiosResponse } from "axios";
import { list_user_roles_url } from "@/constants/urls";
import { IUserRole_ } from "@/interfaces/IUser";
import { IServerResponse_ } from "@/interfaces/IRequestResponse";

export async function getRolesAndActions(): Promise<
  AxiosResponse<IServerResponse_<IUserRole_[]>>
> {
  const accessToken = sessionStorage.getItem("token");
  try {
    const response: AxiosResponse<IServerResponse_<IUserRole_[]>> = await axios(
      {
        url: list_user_roles_url,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
}
