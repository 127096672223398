import React from "react";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import {
  Box,
  Handshake,
  AlignJustify,
  ListTodo,
  ReceiptText,
  Users,
  Warehouse,
  LayoutList,
} from "lucide-react";
import SideItem from "@/components/SideItem"; // Adjust import path as needed

interface SidebarSheetProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const SidebarSheet: React.FC<SidebarSheetProps> = ({ open, setOpen }) => {
  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetContent
        side="left"
        className="w-[25vh] sm:w-[25vh] md:w-[25vh] p-0 overflow-y-auto"
      >
        <div className="flex flex-col gap-2 p-2">
          <SideItem
            icon={<Box className="h-6 w-6 sm:h-7 sm:w-7 md:h-8 md:w-8" />}
            title="Produits"
            to="/products"
            path="product"
          />
          <SideItem
            icon={<Handshake className="h-6 w-6 sm:h-7 sm:w-7 md:h-8 md:w-8" />}
            title="Fournisseurs"
            to="/suppliers"
            path="supplier"
          />
          <SideItem
            icon={
              <AlignJustify className="h-6 w-6 sm:h-7 sm:w-7 md:h-8 md:w-8" />
            }
            title="Commandes"
            to="/orders"
            path="order"
          />
          <SideItem
            icon={<ListTodo className="h-6 w-6 sm:h-7 sm:w-7 md:h-8 md:w-8" />}
            title="Réceptions"
            to="/deliveries"
            path="deliver"
          />
          <SideItem
            icon={
              <ReceiptText className="h-6 w-6 sm:h-7 sm:w-7 md:h-8 md:w-8" />
            }
            title="Factures"
            to="/invoices"
            path="invoice"
          />
          <SideItem
            icon={
              <LayoutList className="h-6 w-6 sm:h-7 sm:w-7 md:h-8 md:w-8" />
            }
            title="Litiges"
            to="/litige"
            path="litige"
          />
          <SideItem
            icon={<Warehouse className="h-6 w-6 sm:h-7 sm:w-7 md:h-8 md:w-8" />}
            title="Stock"
            to="/stock"
            path="stock"
          />
          <SideItem
            icon={<Users className="h-6 w-6 sm:h-7 sm:w-7 md:h-8 md:w-8" />}
            title="Utilisateurs"
            to="/users"
            path="user"
          />
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default SidebarSheet;
