import React, { useEffect } from "react";

interface OrderStatusTagProps {
  status: number;
}

interface StatusStyle {
  text: string;
  bgColor: string;
  textColor: string;
}

const OrderStatusTag: React.FC<OrderStatusTagProps> = ({ status }) => {
  const [statusStyle, setStatusStyle] = React.useState<StatusStyle>({
    text: "",
    bgColor: "",
    textColor: "",
  });

  useEffect(() => {
    switch (status) {
      case 0:
        setStatusStyle({
          text: "Brouillon",
          bgColor: "bg-gray-100",
          textColor: "text-gray-600",
        });
        break;
      case 1:
        setStatusStyle({
          text: "Crée",
          bgColor: "bg-yellow-100",
          textColor: "text-yellow-600",
        });
        break;
      case 2:
        setStatusStyle({
          text: "Validée",
          bgColor: "bg-green-100",
          textColor: "text-green-600",
        });
        break;
      case 3:
        setStatusStyle({
          text: "Annulée",
          bgColor: "bg-red-100",
          textColor: "text-red-600",
        });
        break;
      case 4:
        setStatusStyle({
          text: "Livrée",
          bgColor: "bg-blue-100",
          textColor: "text-blue-600",
        });
        break;
      case 5:
        setStatusStyle({
          text: "Facturée",
          bgColor: "bg-purple-100",
          textColor: "text-purple-600",
        });
        break;
      default:
        setStatusStyle({
          text: "Inconnu",
          bgColor: "bg-gray-100",
          textColor: "text-gray-600",
        });
        break;
    }
  }, [status]);

  return (
    <div className="flex items-center space-x-2">
      <div
        className={`rounded-md px-3 py-1 text-sm font-semibold ${statusStyle.bgColor} ${statusStyle.textColor}`}
      >
        {statusStyle.text}
      </div>
    </div>
  );
};

export default OrderStatusTag;
