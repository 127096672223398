import React from "react";
import { TableCell, TableRow } from "./ui/table";
import { formateDate } from "@/lib/utils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import Highlighter from "react-highlight-words";
import { IInvoice } from "@/interfaces/IInvoice";
import InvoiceStatusTag from "./InvoiceStatusTag";

import { INVOICE_DETAILS_DIALOG } from "@/constants/constants";
import { openDialog } from "@/store/reducers/dialogReducer";
import { setInvoice } from "@/store/reducers/invoiceReducer";
import { Button } from "./ui/button";

interface InvoiceProps {
  invoice: IInvoice;
}

const Invoice: React.FC<InvoiceProps> = ({ invoice }) => {
  const dispatch = useDispatch();
  const searchKeyword = useSelector(
    (state: RootState) => state.searchReduer.searchText
  );

  const handleInvoiceClick = () => {
    dispatch(setInvoice(invoice));
    dispatch(openDialog(INVOICE_DETAILS_DIALOG));
  };
  return (
    <>
      <TableRow key={invoice.id} className="cursor-pointer hover:bg-gray-100">
        <TableCell
          onClick={handleInvoiceClick}
          className=" sm:table-cell font-semibold"
        >
          <Highlighter
            highlightClassName="bg-yellow-200 dark:bg-yellow-500"
            searchWords={[searchKeyword]}
            autoEscape={true}
            textToHighlight={invoice.invoiceNumber}
          />
        </TableCell>
        <TableCell onClick={handleInvoiceClick} className="sm:table-cell">
          {invoice.orderNumber || "N/A"}
        </TableCell>
        <TableCell
          onClick={handleInvoiceClick}
          className="sm:table-cell text-center"
        >
          {invoice.supplierLabel}
        </TableCell>
        <TableCell
          onClick={handleInvoiceClick}
          className="sm:table-cell text-center"
        >
          {formateDate(invoice.orderDate)}
        </TableCell>
        <TableCell
          onClick={handleInvoiceClick}
          className="sm:table-cell text-center"
        >
          {formateDate(invoice.receptionDate)}
        </TableCell>

        <TableCell
          onClick={handleInvoiceClick}
          className="sm:table-cell text-center"
        >
          {invoice.amountTTC} MAD
        </TableCell>
        <TableCell onClick={handleInvoiceClick} className="sm:table-cell ">
          <InvoiceStatusTag status={invoice.statut} />
        </TableCell>

        {invoice.pathNC || invoice.pathND || invoice.pathBap ? (
          <TableCell className="sm:table-cell ">
            <div className="flex justify-center space-x-2 col-row">
              {invoice.pathNC && (
                <Button
                  variant="outline"
                  size="sm"
                  className="text-grey-500"
                  onClick={() => invoice.pathNC && window.open(invoice.pathNC)}
                >
                  NC
                </Button>
              )}
              {invoice.pathND && (
                <Button
                  variant="outline"
                  size="sm"
                  className="text-grey-500"
                  onClick={() => invoice.pathND && window.open(invoice.pathND)}
                >
                  ND
                </Button>
              )}
              {invoice.pathBap && (
                <Button
                  variant="outline"
                  size="sm"
                  className="text-grey-500"
                  onClick={() =>
                    invoice.pathBap && window.open(invoice.pathBap)
                  }
                >
                  BAP
                </Button>
              )}
            </div>
          </TableCell>
        ) : (
          <TableCell className="sm:table-cell text-center">-</TableCell>
        )}
      </TableRow>
    </>
  );
};

export default Invoice;
