import { ISupplierType } from "@/interfaces/ISupplier";

export const ICON_SIZE = 20;
export const DELETE_RAYON_DIALOG = "DELETE_RAYON_DIALOG";
export const CREATE_RAYON_DIALOG = "CREATE_RAYON_DIALOG";
export const CREATE_USER_DIALOG = "CREATE_USER_DIALOG";
export const UPDATE_USER_DIALOG = "UPDATE_USER_DIALOG";
export const ORDER_SELECT_DIALOG = "ORDER_SELECT_DIALOG";
export const UPDATE_PRODUCT_DIALOG = "UPDATE_PRODUCT_DIALOG";
export const SUPPLIER_SELECT_DIALOG = "SUPPLIER_SELECT_DIALOG";
export const DELIVERY_ORDER_SELECT_DIALOG = "DELIVERY_ORDER_SELECT_DIALOG";
export const UPDATE_RAYON_DIALOG = "UPDATE_RAYON_DIALOG";
export const CREATE_SUPPLIER_DIALOG = "CREATE_SUPPLIER_DIALOG";
export const UPDATE_SUPPLIER_DIALOG = "UPDATE_SUPPLIER_DIALOG";
export const PRODUCT_PRICE_HISTORY_DIALOG = "PRODUCT_PRICE_HISTORY_DIALOG";
export const PRODUCT_ASSIGN_DIALOG = "PRODUCT_ASSIGN_DIALOG";
export const CREATE_ORDER_DIALOG = "CREATE_ORDER_DIALOG";
export const ORDER_DETAILS_DIALOG = "ORDER_DETAILS_DIALOG";
export const INVOICE_UPLOAD_DIALOG = "INVOICE_UPLOAD_DIALOG";
export const DOWNLOAD_CONFIRMATION_DIALOG = "DOWNLOAD_CONFIRMATION_DIALOG";
export const DELETE_USER_DIALOG = "DELETE_USER_DIALOG";
export const DELETE_ORDER_DIALOG = "DELETE_ORDER_DIALOG";
export const ORDER_CREATE_PAGE = "/orders/create";
export const SUPPLIER_CREATE_DIALOG = "SUPPLIER_CREATE_DIALOG";
export const ORDER_VALIDATE_CONFIRMATION_DIALOG =
  "ORDER_VALIDATE_CONFIRMATION_DIALOG";
export const PRODUCT_ASSIGN_PAGE = "/suppliers/";
export const PRODUCT_LIST_DIALOG = "PRODUCT_LIST_DIALOG";
export const SUPPLIER_PAGE = "/suppliers";
export const RAYON_PAGE = "/rayons";
export const PRODUCT_PAGE = "/products";
export const ORDER_PAGE = "/orders";
export const HOME_PAGE = "/";
export const RECEPTION_PAGE = "/deliveries";
export const RECEPTION_CREATE_PAGE = "/deliveries/create";
export const SUPPLIER_DETAILS_PAGE = "/supplier";
export const INVOICE_PAGE = "/invoices";
export const USER_PAGE = "/users";
export const INVOICE_DOCUMENTS_VIEW_DIALOG = "INVOICE_DOCUMENTS_VIEW_DIALOG";
export const INVOICE_CREATE_PAGE = "/invoices/create";
export const delivery_movement = "Reception";
export const sale_movement = "Vente";
export const return_movement = "Retour";
export const ORDER_BATCH_UPLOAD_DIALOG = "ORDER_BATCH_UPLOAD_DIALOG";
export const INVOICE_CREATE_DIALOG = "INVOICE_CREATE_DIALOG";
export const DELIVERY_NOTE_UPLOAD_DIALOG = "DELIVERY_NOTE_UPLOAD_DIALOG";
export const ORDER_INPUT_DIALOG = "ORDER_INPUT_DIALOG";
export const INVOICE_DETAILS_DIALOG = "INVOICE_DETAILS_DIALOG";
export const LITIGE_CONFIRM_DIALOG = "LITIGE_CONFIRM_DIALOG";
export const EXTRACTION_MESSAGE = "Une extraction est en cours...";
export const RETAIL_FINANCE = "RETAIL_FINANCE";
export const RETAIL_ADMIN = "RETAIL_ADMIN";
export const RETAIL_COMMERCIAL = "RETAIL_COMMERCIAL";
export const RETAIL_ENTREPOT = "RETAIL_ENTREPOT";
export const PRODUCT_CREATE_DIALOG = "PRODUCT_CREATE_DIALOG";

export const MOVEMENT_TYPES_EN = {
  Delivery: "Delivery",
  Sale: "Sale",
  Return: "Return",
};
export const MOVEMENT_TYPES_FR = {
  Delivery: "Réception",
  Sale: "Vente",
  Return: "Retour",
};
export const accessToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ5Y2hhaGlkX2V4dEBtYXJqYW5lbWFsbC5tYSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWUiOiJ5Y2hhaGlkX2V4dEBtYXJqYW5lbWFsbC5tYSIsImp0aSI6IjQ2MTdhZmRkLTcwNTgtNDM4OS05YWZiLTVlNzU0Y2JlZjlmYiIsImVtYWlsIjoieWNoYWhpZF9leHRAbWFyamFuZW1hbGwubWEiLCJ1aWQiOiJDOEE2RUYwNi1DRkFDLTQyQTAtQkEwRS02RDk3QUZDOUE3MEYiLCJyb2xlcyI6IkFHRU5UX0tBTSIsImV4cCI6MTczMDMxNTAwMiwiaXNzIjoiQ29yZUlkZW50aXR5IiwiYXVkIjoiQ29yZUlkZW50aXR5VXNlciJ9.rxUwBw1MlIqFLerklfv74dP9m51uDm2uPMphGH9_TwE";

export const supplierTypes: ISupplierType[] = [
  {
    id: 1,
    label: "Fournisseur existant",
    type: 2,
  },
  {
    id: 2,
    label: "Vendeur existant",
    type: 1,
  },
  {
    id: 3,
    label: "Nouveau fournisseur",
    type: 0,
  },
];

export const defaultFormData = {
  address: "",
  bankName: "", // Default value you provided earlier
  billingAddress: "",
  email: "",
  firstName: "",
  ice: "",
  id: 0, // Assuming 0 is used to indicate a new, unsaved record
  identifiantSupplier: 0, // Changed to null based on your earlier example
  label: "",
  lastName: "",
  pattente: "",
  rc: "",
  rib: "",
  socialreason: "",
  supplierCode: "",
  rcFile: null,
  iceFile: null,
  ribFile: null,
  dureeEcheancesEnJours: 0,
  TypePaiment: 0,
};

export const enum UserRoles_ {
  ADMIN = "RETAIL_ADMIN",
  COMMERCIAL = "RETAIL_COMMERCIAL",
  ENTREPOT = "RETAIL_ENTREPOT",
  FINANCE = "RETAIL_FINANCE",
}

export const enum PaymentEnum {
  VIREMENT = 1,
  CHEQUE = 2,
  ESPECE = 3,
}

export const PaymentTypes = [
  {
    id: PaymentEnum.VIREMENT,
    label: "Virement",
  },
];

export const enum InputTypes {
  TEXT = "text",
  NUMBER = "number",
  SELECT = "select",
}
