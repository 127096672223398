import { ISupplierProduct } from "@/interfaces/ISupplier";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SupplierProductState {
  product: ISupplierProduct;
}

const initialState: SupplierProductState = {
  product: {
    supplierProductId: 0,
    gtin: "",
    productId: "",
    title: "",
    imagePath: "",
    priceUnitHT: 0,
    niveau1: "",
    niveau1Id: "",
    niveau2: "",
    niveau2Id: "",
    niveau3: "",
    niveau3Id: "",
  },
};

const supplierProductEditSlice = createSlice({
  name: "supplierProductEdit",
  initialState,
  reducers: {
    setSupplierProduct: (state, action: PayloadAction<ISupplierProduct>) => {
      state.product = action.payload;
    },
    resetSupplierProduct: (state) => {
      state.product = initialState.product;
    },
  },
});

export const { setSupplierProduct } = supplierProductEditSlice.actions;
export default supplierProductEditSlice.reducer;
