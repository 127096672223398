import { ICategory } from "@/interfaces/ICategory";
import { getCategories } from "@/services/CategoyListService";
import React, { useEffect } from "react";
import CategorySelect from "./CategorySelect";
import { useDispatch } from "react-redux";
import { setFilter } from "@/store/reducers/filterReducer";
import { Toaster } from "./ui/toaster";

interface FilterListProps {
  visible: boolean;
  categories: ICategory[];
  hasExportButton?: boolean;
}

const FilterList: React.FC<FilterListProps> = ({ categories }) => {
  const dispatch = useDispatch();

  const [selectedMainCategory, setSelectedMainCategory] =
    React.useState<ICategory>({} as ICategory);
  const [selectedSubCategory, setSelectedSubCategory] =
    React.useState<ICategory>({} as ICategory);
  const [selectedSubSubCategory, setSelectedSubSubCategory] =
    React.useState<ICategory>({} as ICategory);
  const [subCategories, setSubCategories] = React.useState<ICategory[]>(
    [] as ICategory[]
  );
  const [subSubCategories, setSubSubCategories] = React.useState<ICategory[]>(
    [] as ICategory[]
  );

  useEffect(() => {
    if (selectedMainCategory.id) {
      fetchSubCategories();
      dispatch(
        setFilter({
          category1: selectedMainCategory,
          category2: {} as ICategory,
          category3: {} as ICategory,
        })
      );
      setSelectedSubCategory({} as ICategory);
      setSelectedSubSubCategory({} as ICategory);
    }
  }, [selectedMainCategory]);

  useEffect(() => {
    if (selectedSubCategory.id) {
      fetchSubSubCategories();
      dispatch(
        setFilter({
          category1: selectedMainCategory,
          category2: selectedSubCategory,
          category3: {} as ICategory,
        })
      );
      setSelectedSubSubCategory({} as ICategory);
    }
  }, [selectedSubCategory]);

  useEffect(() => {
    if (selectedSubSubCategory.id) {
      dispatch(
        setFilter({
          category1: selectedMainCategory,
          category2: selectedSubCategory,
          category3: selectedSubSubCategory,
        })
      );
    }
  }, [selectedSubSubCategory]);

  const fetchSubCategories = async () => {
    const respone = await getCategories(selectedMainCategory.id);
    if (respone.status === 200) {
      setSubCategories(respone.data as ICategory[]);
    } else {
      console.log("Error fetching categories", respone);
    }
  };

  const fetchSubSubCategories = async () => {
    const respone = await getCategories(selectedSubCategory.id);
    if (respone.status === 200) {
      setSubSubCategories(respone.data as ICategory[]);
    } else {
      console.log("Error fetching categories", respone);
    }
  };

  return (
    <>
      <CategorySelect
        categories={categories}
        selectedCategory={selectedMainCategory}
        setSelectedCategory={setSelectedMainCategory}
        label="N1"
      />
      {Object.keys(selectedMainCategory).length !== 0 && (
        <CategorySelect
          categories={subCategories}
          selectedCategory={selectedSubCategory}
          setSelectedCategory={setSelectedSubCategory}
          label="N2"
        />
      )}
      {Object.keys(selectedSubCategory).length !== 0 && (
        <CategorySelect
          categories={subSubCategories}
          selectedCategory={selectedSubSubCategory}
          setSelectedCategory={setSelectedSubSubCategory}
          label="N3"
        />
      )}

      <Toaster />
    </>
  );
};

export default FilterList;
