import axios, { AxiosResponse } from "axios";
import { list_retail_products_url } from "@/constants/urls";
import { IRetailProductResponse } from "@/interfaces/IProduct";

const accessToken = sessionStorage.getItem("token");

export async function getRetailProducts(
  keyword: string,
  pageNumber: number,
  pageSize: number,
  category1: string,
  category2: string,
  category3: string,
  supplierId: number | null
): Promise<AxiosResponse<IRetailProductResponse>> {
  try {
    const response: AxiosResponse<IRetailProductResponse> = await axios({
      url: list_retail_products_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        keyword: keyword,
        pageNumber: pageNumber,
        pageSize: pageSize,
        orderBy: [],
        idNiveau3: category3,
        idNiveau2: category2,
        idNiveau1: category1,
        supplierId: supplierId,
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
