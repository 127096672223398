import axios, { AxiosResponse } from "axios";
import { product_create_url } from "@/constants/urls";
import { IProductRequest } from "@/interfaces/IProduct";
import { IServerResponse__ } from "@/interfaces/IRequestResponse";

export async function createProduct(
  product: IProductRequest,
  img: File[]
): Promise<AxiosResponse<IServerResponse__>> {
  const accessToken = sessionStorage.getItem("token");

  try {
    const formData = new FormData();

    formData.append("request", JSON.stringify(product));

    formData.append("img", img[0]);

    const response: AxiosResponse<IServerResponse__> = await axios({
      url: product_create_url,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: formData,
    });

    return response;
  } catch (error: any) {
    return error.response;
  }
}
