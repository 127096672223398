import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { ISupplierProduct } from "@/interfaces/ISupplier";
import SupplierProduct from "./SupplierProduct";

interface SupplierProductsTableProps {
  products: ISupplierProduct[];
}

const SupplierProductsTable: React.FC<SupplierProductsTableProps> = ({
  products,
}) => {
  return (
    <Table className="drop-shadow-sm bg-white rounded-r-xl">
      <TableHeader>
        <TableRow>
          <TableHead className="hidden xl:table-cell">Image</TableHead>
          <TableHead className="sm:table-cell">Produit</TableHead>
          <TableHead className="hidden lg:table-cell">Titre</TableHead>
          <TableHead className="md:table-cell">Catégories</TableHead>
          {/*}
          <TableHead className="md:table-cell text-center">Stock </TableHead>
          <TableHead className="md:table-cell text-center">
            Couverture du stock{" "}
          </TableHead>
          <TableHead className="md:table-cell text-center">
            Stock revalorisé
          </TableHead>
          */}
          <TableHead className="md:table-cell text-center">
            Historique
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {products && products.length > 0 ? (
          products.map((product) => <SupplierProduct product={product} />)
        ) : (
          <TableRow>
            <TableCell colSpan={9} className="text-center">
              Aucun produit trouvé
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default SupplierProductsTable;
