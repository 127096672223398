// Sidebar.js
import {
  AlignJustify,
  Box,
  Handshake,
  LayoutList,
  ListTodo,
  ReceiptText,
  UsersRound,
  Warehouse,
} from "lucide-react";
import SideItem from "./SideItem";
import { ScrollArea } from "./ui/scroll-area";

interface SidebarProps {
  open: boolean;
  hasBorder?: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ hasBorder }) => {
  return (
    <div
      className={`hidden min-h-screen shrink-0 lg:block bg-white  py-2 px-2 ${
        hasBorder ? "border-r" : ""
      }`}
    >
      <ScrollArea>
        <div className="flex flex-col">
          <nav className="grid text-sm font-medium">
            <ul className="space-y-1">
              <li>
                <SideItem
                  icon={<Box className="h-6 w-6" />}
                  title="Produits"
                  to="/products"
                  path="product"
                />
              </li>
              {/* <li>
              <SideItem
                icon={<Layers className="h-6 w-6" />}
                title="Rayons"
                to="/rayons"
              />
            </li> */}
              <li>
                <SideItem
                  icon={<Handshake className="h-6 w-6" />}
                  title="Fournisseurs"
                  to="/suppliers"
                  path="supplier"
                />
              </li>
              <li>
                <SideItem
                  icon={<AlignJustify className="h-6 w-6" />}
                  title="Commandes"
                  to="/orders"
                  path="order"
                />
              </li>
              <li>
                <SideItem
                  icon={<ListTodo className="h-6 w-6" />}
                  title="Réceptions"
                  to="/deliveries"
                  path="deliver"
                />
              </li>
              <li>
                <SideItem
                  icon={<ReceiptText className="h-6 w-6" />}
                  title="Factures"
                  to="/invoices"
                  path="invoice"
                />
              </li>
              <li>
                <SideItem
                  icon={<LayoutList className="h-6 w-6" />}
                  title="Litiges"
                  to="/litige"
                  path="litige"
                />
              </li>
              <li>
                <SideItem
                  icon={<Warehouse className="h-6 w-6" />}
                  title="Stock"
                  to="/stock"
                  path="stock"
                />
              </li>
              <li>
                <SideItem
                  icon={<UsersRound className="h-6 w-6" />}
                  title="Utilisateurs"
                  to="/users"
                  path="user"
                />
              </li>
            </ul>
          </nav>
        </div>
      </ScrollArea>
    </div>
  );
};

export default Sidebar;
