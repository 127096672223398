import { toast } from "@/components/ui/use-toast";
import { accessToken } from "@/constants/constants";
import { order_changeStatus } from "@/constants/urls";
import { IDeliveryItemDetails } from "@/interfaces/IDelivery";
import { IInvoiceProduct } from "@/interfaces/IInvoice";
import { IOrderBatchItem, IOrderItemDetails } from "@/interfaces/IOrder";
import {
  IAssignementItem,
  IAssignementItem_,
  IOrderProduct,
  IPriceHistoryItem,
  IProduct,
  IProductToAssign,
} from "@/interfaces/IProduct";
import { ISupplierProduct } from "@/interfaces/ISupplier";
import { v4 as uuidv4 } from "uuid";
import { Item } from "@/interfaces/Item";
import axios, { AxiosResponse } from "axios";
import { type ClassValue, clsx } from "clsx";
import { differenceInHours, differenceInMinutes, parseISO } from "date-fns";
import { twMerge } from "tailwind-merge";
import { addDays } from "date-fns";
import JSEncrypt from "jsencrypt";
import { publicKey } from "@/config";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
export function checkName(name: string) {
  const namePattern = /^[a-zA-Z ]{2,30}$/;
  return namePattern.test(name);
}
export function checkEmail(email: string) {
  const emailPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailPattern.test(email);
}
export function checkPhone(phone: string) {
  const phonePattern = /^0[5-7]\d{8}$/;
  return phonePattern.test(phone);
}
export function checkRib(rib: string) {
  const ribPattern = /^\d{24}$/;
  return ribPattern.test(rib);
}
export function checkPattente(pattente: string) {
  const pattentePattern = /^\d{8}$/;
  return pattentePattern.test(pattente);
}
export function checkRC(rc: string) {
  return rc === "" || rc.length === 8;
}
export const checkICE = (ice: string) => {
  const icePattern = /^\d{15}$/;
  return icePattern.test(ice);
};

export const formateDate = (dateString: string) => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return "-";
  }

  return date.toLocaleDateString();
};

export function generateNumericId(length: number = 10): string {
  const uuid = uuidv4().replace(/-/g, "");
  const numericId = parseInt(uuid, 16).toString().substr(0, length);
  return numericId.padStart(length, "0");
}

export const formatDate = (date: Date) => {
  const year = date.getFullYear().toString().padStart(4, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const milliseconds = date.getMilliseconds().toString().padStart(3, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
};

//TODO: CHNAGE METHODE TO POST
export async function changeOrderStatus(
  orderId: string
): Promise<AxiosResponse<string>> {
  try {
    const response: AxiosResponse<string> = await axios({
      url: order_changeStatus + `/${orderId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}

export const DownloadFile = async (
  _url: string,
  _filename: string
): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(_url, {
      headers: { Authorization: "Bearer " + accessToken },
    });
    const link = response.data.data;
    handleDownload(link, _filename);

    return response;
  } catch (error: any) {
    return error.response.data;
  } finally {
    console.log("Download file");
  }
};

export const getDownloadLink = async (_url: string): Promise<void> => {
  try {
    const downloadLink = _url;
    console.log("File download link:", downloadLink);

    if (downloadLink) {
      const link = document.createElement("a");
      link.href = downloadLink;
      link.target = "_blank";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (error: any) {
    console.error("Error fetching download link:", error);
  } finally {
    console.log("Fetch and download link process completed");
  }
};

export const DownloadFile_ = async (
  _url: string,
  _filename: string,
  _data: any
  // Add this parameter for POST data
): Promise<AxiosResponse | any> => {
  try {
    const response = await axios.post(_url, _data, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers your API requires
      },
    });

    const blob = response.data;
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = _filename;
    link.style.display = "none"; // Hide the link
    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      document.body.removeChild(link);
      URL.revokeObjectURL(url); // Revoke the temporary URL
    }, 100);

    return response;
  } catch (error: any) {
    console.error("Error downloading file:", error);
    if (error.response) {
      return error.response.data;
    } else {
      throw error; // Re-throw if it's not an axios error
    }
  }
};

export const checkIfOrderProduct = (product: Item, products: Item[]) => {
  return products?.find((p) => p.productId === product.productId);
};

export const convertProductToOrderProduct = (
  product: IProduct
): IOrderProduct => {
  return {
    product: product,
    quantity: 1,
  };
};

export const searchTextHighlight = (text: string, search: string) => {
  if (search === "") return text;
  const searchPattern = new RegExp(search, "gi");
  return text.replace(
    searchPattern,
    (match) => `<span className="text-yellow-300 ">${match}</span>`
  );
};

export const convertDeliveryItemsToInvoiceProducts = (
  deliveryItems: IDeliveryItemDetails[]
): IInvoiceProduct[] => {
  return deliveryItems.map((_deliveryItem) => {
    return [] as unknown as IInvoiceProduct;
  });
};

export const resetQuantityandUnitPrice = (products: IInvoiceProduct[]) => {
  return products.map((product) => {
    return {
      ...product,
      quantity: 1,
      unitPrice: 1,
    };
  });
};

export const convertProductToItem = (product: ISupplierProduct): Item => {
  return {
    id: product.supplierProductId,
    productId: product.productId,
    unitPriceHT: product.priceUnitHT,
    unitPriceTTC: product.priceUnitHT,
    tva: 0,
    productSupplierId: product.supplierProductId,
    title: product.title,

    quantity: 1,

    gtin: product.gtin,
  };
};

export const convertIProductToAssignToAssignementItem = (
  products: IProductToAssign[],
  providerId: number
): IAssignementItem[] => {
  return products.map((product) => {
    return {
      octopiaProductId: product.product.id.toString(),
      providerId: providerId,
      unitPrice: product.unitPrice,
      startDate: product.startDate,
      endDate: product.endDate,
    };
  });
};

// export const convertIProductToAssignToAssignementItem_ = (
//   products: IProductToAssign[]
// ): IAssignementItem_[] => {
//   return products.map((product) => {
//     return {
//       unitPriceHT: product.unitPrice,
//       startDate: product.startDate,
//       endDate: product.endDate,
//       idProduct: product.product.id,
//     };
//   });
// };

// TODO: fix dates (-1)

export const convertIProductToAssignToAssignementItem_ = (
  products: IProductToAssign[]
): IAssignementItem_[] => {
  return products.map((product) => {
    const startDate = new Date(product.startDate);
    const endDate = new Date(product.endDate);

    if (isNaN(endDate.getTime()) || endDate <= startDate) {
      return {
        unitPriceHT: product.unitPrice,
        startDate: addDays(startDate, 1).toISOString(),
        endDate: null,
        productId: product.product.productId,
      };
    }

    return {
      unitPriceHT: product.unitPrice,
      startDate: addDays(startDate, 1).toISOString(),
      endDate: addDays(endDate, 1).toISOString(),
      productId: product.product.productId,
    };
  });
};

export function truncateFilename(
  filename: string,
  firstChars: number,
  lastChars: number
) {
  // Handle empty or very short filenames
  if (!filename || filename.length <= firstChars + lastChars + 1) {
    return filename;
  }

  const middleLength = filename.length - firstChars - lastChars - 1;
  const middle = middleLength > 0 ? "..." : ""; // Ellipsis for truncation

  return `${filename.slice(0, firstChars)}${middle}${filename.slice(
    -lastChars
  )}`;
}

export const getRelativeTime = (dateString: string) => {
  const date = parseISO(dateString);
  const now = new Date();
  const minutesDiff = differenceInMinutes(now, date);
  const hoursDiff = differenceInHours(now, date);

  if (minutesDiff < 60) {
    return `${minutesDiff} minute${minutesDiff !== 1 ? "s" : ""} ago`;
  } else {
    const remainingMinutes = minutesDiff % 60;
    return `${hoursDiff} hr${
      hoursDiff !== 1 ? "s" : ""
    } ${remainingMinutes} min${remainingMinutes !== 1 ? "s" : ""} ago`;
  }
};

export function formatDateTime(dateTimeString: string): string {
  const date = new Date(dateTimeString);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() is zero-indexed
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${day}/${month}/${year} - ${hours}:${minutes}`;
}

export function getStartDate(date: Date, isPermanent?: boolean): Date {
  const startDate = new Date(date);
  if (isPermanent) {
    startDate.setDate(startDate.getDate());
  } else {
    startDate.setDate(startDate.getDate() + 1);
  }
  startDate.setHours(0, 0, 0, 0); // Set to start of day
  return startDate;
}

export function getEndDate(date: Date): Date {
  const endDate = new Date(date);
  endDate.setDate(endDate.getDate() + 2); // Set to day after tomorrow
  endDate.setHours(0, 0, 0, 0); // Set to start of day
  return endDate;
}

export function getDisabledDates(
  days: number,
  referenceDate: Date = new Date()
) {
  return (date: Date) => {
    const minAllowedDate = new Date(referenceDate);
    minAllowedDate.setDate(minAllowedDate.getDate() + days);
    minAllowedDate.setHours(0, 0, 0, 0); // Set to start of day
    return date < minAllowedDate;
  };
}

export function checkAssignedProduct(
  selectedProduct: IProduct,
  selectedProducts: IProductToAssign[]
) {
  if (
    selectedProducts.find(
      (prod) => prod.product.productId === selectedProduct.productId
    )
  ) {
    toast({
      title: "Erreur",
      description: "Ce produit est déjà dans la liste",
      variant: "destructive",
    });
    return true;
  }
}

export function castToItem(orderBatchItem: IOrderBatchItem): Item {
  return {
    id: orderBatchItem.idDetail,
    quantity: orderBatchItem.qnt,
    unitPriceHT: orderBatchItem.price,
    productId: orderBatchItem.idDetail.toString(), // Assuming idDetail can be used as productId
    tva: 0,
    productSupplierId: orderBatchItem.idDetail,
    unitPriceTTC: orderBatchItem.price,
    title: "",
    gtin: "", // IOrderBatchItem doesn't have a corresponding field for gtin
  };
}

export function castArrayToItems(orderBatchItems: IOrderBatchItem[]): Item[] {
  return orderBatchItems.map(castToItem);
}

export function castOrderItemDetailsToItem(
  orderItemDetails: IOrderItemDetails
): Item {
  return {
    id: orderItemDetails.id,
    quantity: orderItemDetails.quantity,
    unitPriceHT: orderItemDetails.unitPriceHT,
    productId: orderItemDetails.productId,
    productSupplierId: orderItemDetails.productSupplierId,
    gtin: orderItemDetails.gtin,
    unitPriceTTC: orderItemDetails.unitPriceTTC,
    tva: orderItemDetails.tva,
    title: orderItemDetails.title,
  };
}

export function castArrayOrderItemDetailsToItems(
  orderItemDetailsArray: IOrderItemDetails[]
): Item[] {
  return orderItemDetailsArray.map(castOrderItemDetailsToItem);
}

export const handleDownload = (fileUrl: string, fileName: string): void => {
  const link = document.createElement("a");

  link.href = fileUrl;
  link.download = fileName;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);

  console.log(`Download triggered for: ${fileName}`);
};

export const DownloadFile___ = async (
  _url: string,
  _filename: string
): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(_url, {
      responseType: "arraybuffer",
      headers: { Authorization: "Bearer " + accessToken },
    });

    // Create a Blob from the response data
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    const url = URL.createObjectURL(blob);

    // Create a link element and trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.download = _filename;
    link.style.display = "none";

    document.body.appendChild(link);
    link.click();

    // Clean up
    setTimeout(() => {
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }, 100);

    return response;
  } catch (error: any) {
    console.error("Error downloading file:", error);
    return error.response;
  } finally {
    console.log("Download file attempt completed");
  }
};
export const convertProductToAssignToPriceHistoryItem = (
  productToAssign: IProductToAssign,
  providerId: number
): IPriceHistoryItem => {
  return {
    supplierProductPriceId: 0, // Generate a new UUID for each item
    providerId: providerId,
    retailProductId: productToAssign.product.id,
    productCode: productToAssign.product.productId,
    unitPrice: productToAssign.unitPrice,
    startDate: productToAssign.startDate,
    endDate: productToAssign.endDate,
  };
};

export const convertIPriceHistoryItemsToItemsToAssign = (
  priceHistoryItems: IPriceHistoryItem[],
  productId: string
): IAssignementItem_[] => {
  return priceHistoryItems.map((priceHistoryItem) => ({
    id:
      priceHistoryItem.supplierProductPriceId === 0
        ? null
        : priceHistoryItem.supplierProductPriceId,
    productId: productId,
    unitPriceHT: priceHistoryItem.unitPrice,
    startDate: priceHistoryItem.startDate,
    endDate: priceHistoryItem.endDate,
  }));
};

export const encryptMessage = (message: string): Promise<string | null> => {
  console.log("Encrypting message:", message);
  return new Promise((resolve) => {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    const encryptedMessage = encrypt.encrypt(message);
    if (encryptedMessage !== false) {
      resolve(encryptedMessage);
    } else {
      resolve(null);
    }
  });
};

export const sortPriceHistoryItems = (
  items: IPriceHistoryItem[]
): IPriceHistoryItem[] => {
  return [...items].sort((a, b) => {
    if (a.isPermanent && b.isPermanent) {
      // If both are permanent, sort by date (most recent first)
      return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
    }
    if (a.isPermanent) return -1;
    if (b.isPermanent) return 1;
    // For non-permanent items, sort by date (most recent first)
    return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
  });
};
