import { IInvoice } from "@/interfaces/IInvoice";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InvoiceState {
  invoice: IInvoice;
}

const initialState: InvoiceState = {
  invoice: {} as IInvoice,
};

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    setInvoice(state, action: PayloadAction<IInvoice>) {
      state.invoice = action.payload;
    },
  },
});

export const { setInvoice } = invoiceSlice.actions;

export default invoiceSlice.reducer;
