import { formatDateTime } from "@/lib/utils";
import React from "react";
import { IDeliveryDetails } from "@/interfaces/IDelivery";

interface DeliveryDetailsBarProps {
  deliveryDetails: IDeliveryDetails;
}

const DeliveryDetailsBar: React.FC<DeliveryDetailsBarProps> = ({
  deliveryDetails,
}) => {
  return (
    <div className="flex flex-col sm:flex-row sm:flex-wrap sm:justify-between sm:gap-6 w-full">
      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          N° Commande
        </span>
        <span className="font-medium">{deliveryDetails.orderNumber}</span>
      </div>
      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          Fournisseur
        </span>
        <span className="font-medium">{deliveryDetails.supplierLibelle}</span>
      </div>
      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          Commandée le
        </span>
        <span className="font-medium">
          {formatDateTime(deliveryDetails.creationDate)}
        </span>
      </div>
      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          Livrée le
        </span>
        <span className="font-medium">
          {formatDateTime(deliveryDetails.receptionDate)}
        </span>
      </div>
    </div>
  );
};

export default DeliveryDetailsBar;
