import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFilter } from "../../interfaces/ICategory";

interface FilterState {
  filter: IFilter;
  exportMsg: string | null;
  fileLink: string | null;
}

const initialState: FilterState = {
  filter: {
    category1: {
      id: "",
      valeur: "",
      idParent: null,
    },
    category2: {
      id: "",
      valeur: "",
      idParent: null,
    },
    category3: {
      id: "",
      valeur: "",
      idParent: null,
    },
  },
  exportMsg: null,
  fileLink: null,
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setFilter(state, action: PayloadAction<IFilter>) {
      state.filter = action.payload;
    },
    setDefaultFilter(state) {
      state.filter = initialState.filter;
    },
    setExportMsg(state, action: PayloadAction<string>) {
      state.exportMsg = action.payload;
    },
    setFileLink(state, action: PayloadAction<string>) {
      state.fileLink = action.payload;
    },
  },
});

export const { setFilter, setDefaultFilter, setExportMsg, setFileLink } =
  filterSlice.actions;

export default filterSlice.reducer;
