import React from "react";
import { Input } from "./ui/input";

interface QuantitySelectProps {
  selectedQuantity: number;
  setSelectedQuantity: React.Dispatch<React.SetStateAction<number>>;
}

const QuantitySelect: React.FC<QuantitySelectProps> = ({
  selectedQuantity,
  setSelectedQuantity,
}) => {
  return (
    <div className="grid gap-2">
      <label className="text-sm font-medium" htmlFor="quantity">
        Quantité
      </label>
      <Input
        className="w-full"
        id="quantity"
        placeholder="Entrer une quantité"
        value={selectedQuantity === 0 ? "" : selectedQuantity}
        type="number"
        onChange={(e) => setSelectedQuantity(parseInt(e.target.value))}
        min={1}
      />
    </div>
  );
};

export default QuantitySelect;
