import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISupplier } from '@/interfaces/ISupplier';

interface SupplierState {
    supplier: ISupplier 
}

const initialState: SupplierState = {
    supplier: {
        id: 0,
        label: '',
        socialreason: '',
        firstName: '',
        lastName: '',
        rib: '',
        bankName: '',
        ice: '',
        pattente: '',
        rc: '',
        email: '',
        address: '',
        billingAddress: '',
        providerCode: 0,
        productCount: 0,
    },
};

const supplierSlice = createSlice({
    name: 'supplier',
    initialState,
    reducers: {
        setSupplier: (state, action: PayloadAction<ISupplier>) => {
            state.supplier = action.payload;
        },
        clearSupplier: (state) => {
            state.supplier = initialState.supplier;
        },
    },
});

export const { setSupplier, clearSupplier } = supplierSlice.actions;

export default supplierSlice.reducer;