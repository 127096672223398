import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PageState {
  pageNumber: number;
}

const initialState: PageState = {
  pageNumber: 1,
};

const pageSlice = createSlice({
  name: "pageReducer",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    },
    setDefault: (state) => {
      state.pageNumber = 1;
    },
  },
});

export const { setPage, setDefault } = pageSlice.actions;

export default pageSlice.reducer;
