import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "./ui/table";
import { Skeleton } from "./ui/skeleton";

interface TableSkeletonProps {
  rows: number;
  cols: number;
}

const TableSkeleton: React.FC<TableSkeletonProps> = ({ rows, cols }) => {
  return (
    <Table className="w-full">
      <TableBody>
        {[...Array(rows)].map((_, index) => (
          <TableRow key={index}>
            {[...Array(cols)].map((_, index) => (
              <TableCell key={index} colSpan={cols}>
                <Skeleton className="h-5 w-full" />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TableSkeleton;
