import React, { useEffect } from "react";
import { Dialog, DialogHeader, DialogTitle } from "./ui/dialog";
import { DialogContent } from "./ui/dialog";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { SUPPLIER_SELECT_DIALOG } from "@/constants/constants";
import { closeDialog } from "@/store/reducers/dialogReducer";
import { ISupplier } from "@/interfaces/ISupplier";
import { getSuppliersOrder } from "@/services/SupplierListService";
import TableSkeleton from "./TableSkeleton";
import { useNavigate } from "react-router-dom";
import LoaderButton from "./LoaderButton";
import SupplierSelect from "./SupplierSelect";
import { createOrder } from "@/services/OrderCreateService";
import { IOrderHeader } from "@/interfaces/IOrder";
import { toast } from "./ui/use-toast";
import { setSupplier } from "@/store/reducers/supplierReducer";

interface SupplierSelectDialogProps {
  setOrderHeader: React.Dispatch<React.SetStateAction<IOrderHeader>>;
}

const SupplierSelectDialog: React.FC<SupplierSelectDialogProps> = ({
  setOrderHeader,
}) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialogReducer
  );
  const isOpened = isOpen && dialogType === SUPPLIER_SELECT_DIALOG;
  const [selectedSupplier, setSelectedSupplier] = React.useState<ISupplier>(
    {} as ISupplier
  );
  // TODO: change type
  const [suppliers, setSuppliers] = React.useState<ISupplier[]>(
    [] as ISupplier[]
  );
  const [isCreateLoading, setIsCreateLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const fetchSuppliers = async () => {
    try {
      const response = await getSuppliersOrder();

      if (response.status === 200) {
        const { data } = response.data;
        setSuppliers(data);
      } else {
        toast({
          title: "Erreur",
          description:
            "Une erreur s'est produite lors de la récupération des fournisseurs",
          variant: "destructive",
        });
      }
      setLoading(false);
    } catch (error) {
      toast({
        title: "Erreur",
        description:
          "Une erreur s'est produite lors de la récupération des fournisseurs",
        variant: "destructive",
      });
      setLoading(false);
    }
  };

  const handleClose = () => {
    dispatch(closeDialog());
    navigation("/orders");
  };

  const handleCreateHeader = async () => {
    setIsCreateLoading(true);
    const response = await createOrder(selectedSupplier.id);
    if (response.status === 200) {
      setOrderHeader(response.data);
      dispatch(closeDialog());
    } else {
      toast({
        title: "Erreur",
        description:
          "Une erreur s'est produite lors de la création de la commande",
        variant: "destructive",
      });
      dispatch(closeDialog());
      navigation("/orders");
    }
    setIsCreateLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchSuppliers();
  }, [isOpened]);

  useEffect(() => {
    dispatch(setSupplier(selectedSupplier));
  }, [selectedSupplier]);

  return (
    <Dialog open={isOpened} onOpenChange={handleClose}>
      <DialogContent className="w-96">
        <DialogHeader>
          <DialogTitle>Choisir un fournisseur</DialogTitle>
        </DialogHeader>
        {loading ? (
          <TableSkeleton rows={1} cols={1} />
        ) : (
          <>
            <SupplierSelect
              suppliers={suppliers}
              selectedSupplier={selectedSupplier}
              setSelectedSupplier={setSelectedSupplier}
            />
            <div className="flex justify-end mt-4">
              <LoaderButton
                name="Initier"
                handleSubmit={() => {
                  handleCreateHeader();
                }}
                isLoading={isCreateLoading}
                disabled={selectedSupplier.id === undefined}
              />
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SupplierSelectDialog;
