import React, { useEffect } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { cn } from "@/lib/utils";
import {
  CommandInput,
  CommandEmpty,
  CommandList,
  CommandGroup,
  CommandItem,
  Command,
} from "./ui/command";
import { ChevronsUpDownIcon, Check } from "lucide-react";
import { Button } from "./ui/button";
import { ISupplier } from "@/interfaces/ISupplier";
import { useDispatch } from "react-redux";
import { setSupplier } from "@/store/reducers/supplierReducer";

interface SupplierSelectProps {
  suppliers?: ISupplier[];
  selectedSupplier: ISupplier;
  setSelectedSupplier: (supplier: ISupplier) => void;
  editMode?: boolean;
  supplier?: ISupplier;
}

const SupplierSelect: React.FC<SupplierSelectProps> = ({
  selectedSupplier,
  setSelectedSupplier,
  suppliers,
  editMode,
  supplier,
}) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = React.useState(false);
  const [supplierOpen, setSupplierOpen] = React.useState(false);
  useEffect(() => {
    if (editMode && supplier) {
      setSelectedSupplier(supplier);
      setDisabled(true);
    }
  }, [editMode, supplier]);
  return (
    <div className="grid gap-2 ">
      <label className="text-sm font-medium" htmlFor="supplier">
        Fournisseur
      </label>
      <Popover open={supplierOpen} onOpenChange={setSupplierOpen}>
        <PopoverTrigger asChild>
          <Button
            className="w-full justify-between "
            role="combobox"
            variant="outline"
            disabled={disabled}
          >
            {selectedSupplier.label && selectedSupplier
              ? selectedSupplier.label
              : "Choisir un fournisseur"}
            <ChevronsUpDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[--radix-popover-trigger-width] max-h-[--radix-popover-content-available-height]">
          <Command>
            <CommandInput
              className="h-9"
              placeholder="Chercher un fournisseur"
            />
            <CommandEmpty>Aucun fournisseur trouvé</CommandEmpty>
            <CommandList>
              <CommandGroup>
                {suppliers &&
                  suppliers.map((supplier) => (
                    <CommandItem
                      key={supplier.id}
                      disabled={disabled}
                      value={supplier.label}
                      onSelect={() => {
                        setSelectedSupplier(supplier);
                        dispatch(setSupplier(supplier));
                        setSupplierOpen(false);
                      }}
                    >
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          selectedSupplier.id === supplier.id
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {supplier.label}
                    </CommandItem>
                  ))}
                {supplier && (
                  <CommandItem value={supplier.label} disabled={true}>
                    {supplier.label}
                  </CommandItem>
                )}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default SupplierSelect;
