import ProductsTable from "@/components/ProductsTable";
import TablePagination from "@/components/TablePagination";
import UtilityBar from "@/components/UtilityBar";
import { IPageData } from "@/interfaces/IPageData";
import { IRetailProduct } from "@/interfaces/IProduct";
import { RootState } from "../store/store";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Toaster } from "@/components/ui/toaster";
import TableSkeleton from "@/components/TableSkeleton";
import { ScrollArea } from "@/components/ui/scroll-area";
import { setFilterDisabled } from "@/store/reducers/filterDisableReducer";
import { pingExportProducts } from "@/services/ProductExportService";
import { toast } from "@/components/ui/use-toast";
import { setExportMsg, setFileLink } from "@/store/reducers/filterReducer";
import { EXTRACTION_MESSAGE } from "@/constants/constants";
import { getRetailProducts } from "@/services/RetailProductListService";
import ProductCreateDialog from "@/components/ProductCreateDialog";

const ProductPage: React.FC = () => {
  const dispatch = useDispatch();

  const [products, setProducts] = useState<IRetailProduct[]>(
    [] as IRetailProduct[]
  );
  const [pageData, setPageData] = useState<IPageData>();

  const filter = useSelector((state: RootState) => state.filterReducer.filter);
  const exportMsg = useSelector(
    (state: RootState) => state.filterReducer.exportMsg
  );

  const fileLink = useSelector(
    (state: RootState) => state.filterReducer.fileLink
  );

  const cureentPage = useSelector(
    (state: RootState) => state.pageReducer.pageNumber
  );
  const searchKeyword = useSelector(
    (state: RootState) => state.searchReduer.searchText
  );
  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );
  const [isLoading, setIsLoading] = useState(false);

  const supplier = useSelector(
    (state: RootState) => state.supplierReducer.supplier
  );

  useEffect(() => {
    setIsLoading(true);
    fetchProducts();
  }, [cureentPage, searchKeyword, isRefreshing]);

  useEffect(() => {
    dispatch(setFilterDisabled(true));
    setIsLoading(true);
    fetchProducts();
  }, [filter]);

  useEffect(() => {
    setIsLoading(true);
    fetchProducts();
  }, [supplier]);

  const fetchProducts = async () => {
    const response = await getRetailProducts(
      searchKeyword,
      cureentPage,
      10,
      filter.category1.id,
      filter.category2.id,
      filter.category3.id,
      supplier.id === 0 ? null : supplier.id
    );
    if (response.status === 200) {
      setProducts(response.data.data as IRetailProduct[]);
      setPageData({
        pageSize: response.data.pageSize,
        hasNextPage: response.data.hasNextPage,
        hasPreviousPage: response.data.hasPreviousPage,
        currentPage: response.data.currentPage,
        totalCount: response.data.totalCount,
        totalPages: response.data.totalPages,
      });
    } else {
      toast({
        title: "Erreur",
        description:
          "Une erreur s'est produite lors de la récupération des produits",
        variant: "destructive",
      });
      setIsLoading(false);
      setProducts([]);
    }
    setIsLoading(false);
    dispatch(setFilterDisabled(false));
  };

  const handlePingExportProducts = async () => {
    const response = await pingExportProducts();
    if (response.status === 200) {
      dispatch(setExportMsg(response.data.message));
      dispatch(setFileLink(response.data.data));
    } else {
      console.log("Error exporting products", response);
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de l'exportation",
        variant: "destructive",
      });
    }
  };

  const is_product_page = location.pathname?.startsWith("/products");

  useEffect(() => {
    if (is_product_page) {
      handlePingExportProducts();
      if (exportMsg === EXTRACTION_MESSAGE) {
        const intervalId = setInterval(() => {
          handlePingExportProducts();
        }, 10000);
        return () => clearInterval(intervalId);
      }
    }
  }, [is_product_page, filter, exportMsg]);

  return (
    <div className="flex-1 px-12 p-4">
      <h1 className="text-2xl font-bold mb-4">Produits</h1>
      <UtilityBar
        hasSearchButton={true}
        hasFilterButton={true}
        hasProductExportButton={true}
        exportMsg={exportMsg}
        fileLink={fileLink}
        hasCategoryFilter
        hasSupplierFilter
      />
      {isLoading ? (
        <TableSkeleton rows={10} cols={9} />
      ) : (
        <>
          <ScrollArea className="h-[60vh]">
            <ProductsTable
              products={products}
              forRetail={true}
              retailProducts={products}
            />
          </ScrollArea>
          {pageData && <TablePagination pageData={pageData} />}
        </>
      )}
      <ProductCreateDialog />
      <Toaster />
    </div>
  );
};

export default ProductPage;
