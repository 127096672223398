import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { closeDialog } from "@/store/reducers/dialogReducer";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./ui/alert-dialog";
import { DELETE_ORDER_DIALOG } from "@/constants/constants";
import { setRefreshing } from "@/store/reducers/refreshReducer";
import { toast } from "./ui/use-toast";
import { IErrorResponse } from "@/interfaces/IErrorResponse";
import { cancelOrder, deleteOrder } from "@/services/OrderDeleteService";
import { useNavigate } from "react-router-dom";
import { deleteDelivery } from "@/services/DeliveryDeleteService";
import { deleteInvoice } from "@/services/InvoiceDeleteService";

interface DeleteConfirmationDialogProps {
  orderId?: number;
  forOrder?: boolean;
  forDelivery?: boolean;
  deliveryId?: number;
  forInvoice?: boolean;
  invoiceId?: number;
  forUser?: boolean;
  userId?: number;
  forDraft?: boolean;
  // Add any props you need here
}

const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({
  orderId,
  forDelivery,
  deliveryId,
  forInvoice,
  invoiceId,
  forOrder,
  forUser,
  userId,
  forDraft,
}) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const text = forOrder
    ? "commande"
    : forDelivery
    ? "réception"
    : forInvoice
    ? "facture"
    : forUser
    ? "utilisateur"
    : "";
  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialogReducer
  );
  const isOpened = isOpen && dialogType === DELETE_ORDER_DIALOG;
  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );
  const handleClose = () => {
    dispatch(closeDialog());
  };
  const handleCancelOrder = async () => {
    const response = await cancelOrder(orderId as number);
    if (response.status === 200) {
      dispatch(setRefreshing(!isRefreshing));
      toast({
        title: "Commande annulée",
        description: response?.data?.data?.message,
        variant: "success",
      });
      navigation("/orders");
    } else {
      const error = response as unknown as IErrorResponse;
      const message = error.messages[0] || error.exception;
      toast({
        title: "Erreur",
        description: message,
        variant: "destructive",
      });
    }
  };

  const handleDeleteOrder = async () => {
    const response = await deleteOrder(orderId as number);
    if (response.status === 200) {
      dispatch(setRefreshing(!isRefreshing));
      toast({
        title: "Commande supprimée",
        description: "Commande supprimée avec succès",
        variant: "success",
      });
      navigation("/orders");
    } else {
      const error = response as unknown as IErrorResponse;
      const message = error.messages[0] || error.exception;
      toast({
        title: "Erreur",
        description: message,
        variant: "destructive",
      });
    }
  };

  const handleDeleteDelivery = async () => {
    const response = await deleteDelivery(deliveryId as number);
    if (response.status === 200) {
      dispatch(setRefreshing(!isRefreshing));
      toast({
        title: "Réception supprimée",
        description: "Réception supprimée avec succès",
        variant: "success",
      });
      navigation("/deliveries");
    } else {
      const error = response as unknown as IErrorResponse;
      const message = error.messages[0] || error.exception;
      toast({
        title: "Erreur",
        description: message,
        variant: "destructive",
      });
    }
  };
  const handleDeleteInvoice = async () => {
    const response = await deleteInvoice(invoiceId as number);
    if (response.status === 200) {
      dispatch(setRefreshing(!isRefreshing));
      toast({
        title: "Facture supprimée",
        description: "Facture supprimée avec succès",
        variant: "success",
      });
      navigation("/invoices");
    } else {
      const error = response as unknown as IErrorResponse;
      const message = error.messages[0] || error.exception;
      toast({
        title: "Erreur",
        description: message,
        variant: "destructive",
      });
    }
  };
  const handleDeleteUser = () => {
    console.log("Delete user", userId);
  };

  return (
    <AlertDialog open={isOpened} onOpenChange={handleClose}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {forDraft ? "Supprimer la" : "Annuler la"} {text}
          </AlertDialogTitle>
          <AlertDialogDescription>
            {forDraft
              ? "Etes vous sûr de vouloir supprimer cette "
              : "Etes vous sûr de vouloir annuler cette "}
            {text} ?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={handleClose}>Annuler</AlertDialogCancel>
          {forOrder && (
            <AlertDialogAction
              onClick={forDraft ? handleDeleteOrder : handleCancelOrder}
            >
              {forDraft ? "Supprimer" : "Confirmer"}
            </AlertDialogAction>
          )}
          {forInvoice && (
            <AlertDialogAction onClick={handleDeleteInvoice}>
              Supprimer
            </AlertDialogAction>
          )}
          {forDelivery && !forInvoice && (
            <AlertDialogAction onClick={handleDeleteDelivery}>
              Supprimer
            </AlertDialogAction>
          )}
          {forUser && (
            <AlertDialogAction onClick={handleDeleteUser}>
              Supprimer
            </AlertDialogAction>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteConfirmationDialog;
