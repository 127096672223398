import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";
import { ScrollArea } from "./ui/scroll-area";

import { Button } from "./ui/button";
import { Check, Plus, TrashIcon, X } from "lucide-react";
import DateSelect from "./DateSelect";
import { IPriceHistoryItem, IProductToAssign } from "@/interfaces/IProduct";
import { v4 as uuidv4 } from "uuid";
import { deleteSupplierProductPriceItem } from "@/services/SupplierProductPriceItemDeleteService";
import { toast } from "./ui/use-toast";
import {
  convertIPriceHistoryItemsToItemsToAssign,
  formatDate,
  getDisabledDates,
} from "@/lib/utils";
import { updateProductPrices } from "@/services/SupplierProductPriceUpdateService";

interface DateConflictDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  priceHistoryItems: IPriceHistoryItem[];
  setConflictItems: (items: IPriceHistoryItem[]) => void;
  productToAssign: IProductToAssign;
  setSelectedStartDate: (date: Date) => void;
  setSelectedEndDate: (date: Date) => void;
  resetInputs?: () => void;
}

const DateConflictDialog: React.FC<DateConflictDialogProps> = ({
  open,
  setOpen,
  priceHistoryItems,
  setConflictItems,
  productToAssign,
  resetInputs,
}) => {
  const [items, setItems] = useState<IPriceHistoryItem[]>([]);
  const [conflicts, setConflicts] = useState<number[]>([]);
  const [itemToDelete, setItemToDelete] = useState<number | null>(null);
  const [editedItemIds, setEditedItemIds] = useState<Set<number>>(new Set());

  useEffect(() => {
    if (!productToAssign.product) {
      return;
    }
    const convertedProductToAssign: IPriceHistoryItem = {
      supplierProductPriceId: 0,
      providerId: 0,
      retailProductId: 0,
      productCode: productToAssign.product.productId,
      unitPrice: productToAssign.unitPrice,
      startDate: productToAssign.startDate,
      endDate: productToAssign.endDate,
    };

    setItems([
      ...priceHistoryItems
        .filter((item) => !item.isPermanent) // Filter out items where isPermanent is true
        .map((item) => ({
          ...item,
          id: item.supplierProductPriceId || uuidv4(),
        })),
      convertedProductToAssign,
    ]);
    setEditedItemIds(new Set([0]));
  }, [priceHistoryItems, productToAssign]);
  const checkForConflicts = () => {
    let conflictIds: number[] = [];

    for (let i = 0; i < items.length; i++) {
      for (let j = i + 1; j < items.length; j++) {
        const startDateI = new Date(items[i].startDate);
        const endDateI = new Date(items[i].endDate);
        const startDateJ = new Date(items[j].startDate);
        const endDateJ = new Date(items[j].endDate);

        if (
          (startDateI <= endDateJ && endDateI >= startDateJ) ||
          (startDateJ <= endDateI && endDateJ >= startDateI) ||
          startDateI.getDate() === endDateJ.getDate() ||
          endDateI.getDate() === startDateJ.getDate()
        ) {
          conflictIds.push(
            items[i].supplierProductPriceId!,
            items[j].supplierProductPriceId!
          );
        }
      }
    }

    const uniqueConflictIds = [...new Set(conflictIds)];
    setConflicts(uniqueConflictIds);
    setConflictItems(
      items.filter((item) =>
        uniqueConflictIds.includes(item.supplierProductPriceId!)
      )
    );
  };

  useEffect(() => {
    checkForConflicts();
  }, [items, editedItemIds]);

  useEffect(() => {}, [priceHistoryItems, productToAssign]);

  const handleRemove = async (id: number) => {
    setItems((prevItems: IPriceHistoryItem[]) =>
      prevItems.filter((item) => item.supplierProductPriceId !== id)
    );
    const response = await deleteSupplierProductPriceItem(id);
    if (response.status === 200) {
      toast({
        title: "Succès",
        description: "Prix supprimé avec succès",
        variant: "success",
      });
    } else {
      toast({
        title: "Erreur",
        description: "Erreur lors de la suppression du prix",
        variant: "destructive",
      });
    }
    setItemToDelete(null);
  };

  const handleStartDateChange = (id: number, startDate: string) => {
    console.log("start date changed for id ", id);
    setItems((prevItems: IPriceHistoryItem[]) =>
      prevItems.map((item) => {
        if (item.supplierProductPriceId === id) {
          const newStartDate = new Date(startDate);

          console.log("newStartDate", newStartDate.getDate());
          const endDate = new Date(item.endDate);
          const newAlteredEndDate = new Date(newStartDate);
          newAlteredEndDate.setDate(newStartDate.getDate() + 1);
          if (
            newStartDate.getFullYear() > endDate.getFullYear() ||
            (newStartDate.getFullYear() === endDate.getFullYear() &&
              (newStartDate.getMonth() > endDate.getMonth() ||
                (newStartDate.getMonth() === endDate.getMonth() &&
                  newStartDate.getDate() >= endDate.getDate())))
          ) {
            return {
              ...item,
              startDate,
              endDate: formatDate(newAlteredEndDate),
            };
          }
          return { ...item, startDate };
        }
        return item;
      })
    );

    setEditedItemIds((prev) => new Set([...prev, id]));
  };
  const handleEndDateChange = (id: number, endDate: string) => {
    console.log("end date changed for id ", id);
    setItems((prevItems: IPriceHistoryItem[]) =>
      prevItems.map((item) => {
        if (item.supplierProductPriceId === id) {
          const startDate = new Date(item.startDate);
          return { ...item, startDate: formatDate(startDate), endDate };
        }
        return item;
      })
    );
    setEditedItemIds((prev) => new Set([...prev, id]));
  };

  const isItemPast = (startDate: string) => {
    return new Date(startDate) < new Date();
  };

  const handleResolve = async () => {
    setConflictItems([]);
    const editedItems = items.filter((item) =>
      editedItemIds.has(item.supplierProductPriceId!)
    );
    const response = await updateProductPrices(
      items[0].providerId,
      convertIPriceHistoryItemsToItemsToAssign(
        editedItems,
        productToAssign.product.productId
      )
    );
    if (response.status === 200) {
      toast({
        title: "Succès",
        description: "Prix mis à jour avec succès",
        variant: "success",
      });
      resetInputs && resetInputs();
    } else {
      toast({
        title: "Erreur",
        description: "Erreur lors de la mise à jour des prix",
        variant: "destructive",
      });
    }

    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="w-full max-w-full sm:max-w-full md:max-w-full lg:max-w-full">
        <ScrollArea className="max-h-[500px]">
          <DialogHeader>
            <DialogTitle>Chevauchement des dates</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            {items.map((item) => {
              const isPast = isItemPast(item.startDate);
              return (
                <div
                  key={item.supplierProductPriceId}
                  className={`flex flex-1 justify-between items-center rounded-md border p-4 ${
                    conflicts.includes(item.supplierProductPriceId!)
                      ? "border-red-500"
                      : ""
                  }`}
                >
                  <div className="flex items-center gap-4">
                    {item.retailProductId === 0 && (
                      <Plus className="h-4 w-4" color="green" strokeWidth={2} />
                    )}
                    <div className="font-semibold">{item.productCode}</div>
                  </div>

                  <div className="flex items-center gap-2">
                    <DateSelect
                      selectedDate={new Date(item.startDate)}
                      setSelectedDate={(value) =>
                        !isPast &&
                        handleStartDateChange(
                          item.supplierProductPriceId!,
                          formatDate(value)
                        )
                      }
                      gridLayout
                      label="Date début"
                      disabled={isPast}
                      disabledDates={getDisabledDates(1, new Date())}
                    />
                  </div>
                  <div className="flex items-center gap-2">
                    <DateSelect
                      selectedDate={new Date(item.endDate)}
                      setSelectedDate={(value) =>
                        handleEndDateChange(
                          item.supplierProductPriceId!,
                          formatDate(value)
                        )
                      }
                      label="Date fin"
                      disabledDates={getDisabledDates(
                        1,
                        new Date(item.startDate)
                      )}
                    />
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="font-semibold ml-4">{`${item.unitPrice} MAD`}</div>
                  </div>
                  {itemToDelete === item.supplierProductPriceId && (
                    <div className="text-md">
                      Êtes-vous sûr de vouloir supprimer ce prix ?
                    </div>
                  )}
                  {!isPast ? (
                    <div className="flex items-center gap-2 justify-end">
                      {itemToDelete === item.supplierProductPriceId ? (
                        <>
                          <Button
                            variant="ghost"
                            onClick={() =>
                              handleRemove(item.supplierProductPriceId!)
                            }
                          >
                            <Check
                              className="h-4 w-4 text-green-500"
                              strokeWidth={4}
                            />
                          </Button>
                          <Button
                            variant="ghost"
                            onClick={() => setItemToDelete(null)}
                          >
                            <X
                              className="h-4 w-4 text-red-500"
                              strokeWidth={4}
                            />
                          </Button>
                        </>
                      ) : (
                        <Button
                          variant="ghost"
                          onClick={() =>
                            setItemToDelete(item.supplierProductPriceId!)
                          }
                          className="ml-auto"
                        >
                          <TrashIcon className="h-4 w-4" strokeWidth={2} />
                          <span className="sr-only">Remove</span>
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div className="flex items-center gap-2 justify-end">
                      <div className="font-semibold ml-4">En cours</div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          <DialogFooter>
            <Button onClick={handleResolve} disabled={conflicts.length > 0}>
              Resoudre les conflits
            </Button>
          </DialogFooter>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};

export default DateConflictDialog;
