import axios, { AxiosResponse } from "axios";
import { list_user_url } from "@/constants/urls";
import { accessToken } from "@/constants/constants";
import { IUser } from "@/interfaces/IUser";
import { IServerResponse_ } from "@/interfaces/IRequestResponse";

export async function getUsers(
  keyword: string,
  pageNumber?: number
): Promise<AxiosResponse<IServerResponse_<IUser[]>>> {
  try {
    const response: AxiosResponse<IServerResponse_<IUser[]>> = await axios({
      url: list_user_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        keyword: keyword,
        pageNumber: pageNumber || 1,
        pageSize: 1000,
        orderBy: [],
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
