import React from "react";
import { IProduct } from "@/interfaces/IProduct";
import { TableCell, TableRow } from "./ui/table";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "@/store/reducers/dialogReducer";
import { setProduct } from "@/store/reducers/productReducer";
import Highlighter from "react-highlight-words";
import { RootState } from "@/store/store";
import {
  Tooltip,
  TooltipProvider,
  TooltipContent,
  TooltipTrigger,
} from "./ui/tooltip";

interface ProductProps {
  product: IProduct;

  forAssign?: boolean;
}

const Product: React.FC<ProductProps> = ({ product, forAssign }) => {
  const dispatch = useDispatch();
  const searchKeyword = useSelector(
    (state: RootState) => state.searchReduer.searchText
  );
  console.log("product", product);
  const handleSelectProduct = () => {
    dispatch(setProduct(product));
    dispatch(closeDialog());
    console.log("product", product);
  };
  return (
    <TableRow
      {...(forAssign
        ? { className: "cursor-pointer", onClick: handleSelectProduct }
        : {})}
    >
      <TableCell className="hidden xl:table-cell">
        <img
          alt="Product image"
          className="aspect-square rounded-md object-cover"
          height={150}
          src={product.imagePath}
          width={75}
        />
      </TableCell>
      <TableCell className="sm:table-cell">
        <div>
          <span className=" font-semibold">SKU:&nbsp;</span>
          <Highlighter
            activeClassName="bg-yellow-200 dark:bg-yellow-500"
            searchWords={[searchKeyword]}
            autoEscape={true}
            textToHighlight={product.productId}
          />
        </div>
        <div>
          <span className=" font-semibold">GTIN:&nbsp;</span>
          <Highlighter
            activeClassName="bg-yellow-200 dark:bg-yellow-500"
            searchWords={[searchKeyword]}
            autoEscape={true}
            textToHighlight={product.gtin}
          />
        </div>
      </TableCell>
      <TableCell className="hidden lg:table-cell line">
        <TooltipProvider>
          <Tooltip>
            <TooltipContent className="text-wrap line-clamp-2 ">
              {product.title}
            </TooltipContent>
            <TooltipTrigger>
              <Highlighter
                highlightClassName="bg-yellow-200 dark:bg-yellow-500"
                searchWords={[searchKeyword]}
                autoEscape={true}
                textToHighlight={product.title?.toLocaleLowerCase() || ""}
              />
            </TooltipTrigger>
          </Tooltip>
        </TooltipProvider>
      </TableCell>
      <TableCell className="hidden md:table-cell">
        <p className="  text-sm line-clamp-2">
          {product.brand?.toLocaleLowerCase() || "-"}
        </p>
      </TableCell>
      <TableCell className="sm:table-cell">
        <div className="flex flex-col  text-sm ">
          <span className="flex text-sm">
            <span className="font-semibold">N1:&nbsp;</span>
            {product.niveau1?.toLocaleLowerCase()}
          </span>

          <span className="flex text-sm ">
            <span className=" font-semibold">N2:&nbsp;</span>
            {product.niveau2?.toLocaleLowerCase()}
          </span>
          <span className="flex  text-sm ">
            <span className=" font-semibold">N3:&nbsp;</span>{" "}
            {product.niveau3?.toLocaleLowerCase()}
          </span>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default Product;
