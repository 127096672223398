import OrderAddBar from "@/components/OrderAddBar";
import OrderDetailsBar from "@/components/OrderDetailsBar";
import OrderDetailsSkeleton from "@/components/OrderDetailsSkeleton";
import OrderItemsEditTable from "@/components/OrderItemEditTable";
import OrderItemsTable from "@/components/ItemsTable";
import { Toaster } from "@/components/ui/toaster";
import { IOrderDetails, IOrderHeader } from "@/interfaces/IOrder";
import { RootState } from "@/store/store";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Item } from "@/interfaces/Item";
import ActionBar from "@/components/ActionBar";
import { openDialog } from "@/store/reducers/dialogReducer";
import {
  DELETE_ORDER_DIALOG,
  DOWNLOAD_CONFIRMATION_DIALOG,
  ORDER_VALIDATE_CONFIRMATION_DIALOG,
} from "@/constants/constants";
import OrderDeleteDialog from "@/components/DeleteConfirmationDialog";
import PriceDetailsBar from "@/components/PriceDetailsBar";
import OrderStatusTag from "@/components/OrderStatusTag";
import SupplierDetailsBar from "@/components/SupplierDetailsBar";
import { getOrderDetails_ } from "@/services/OrderDetailsService_";
import { castArrayOrderItemDetailsToItems } from "../lib/utils";
import OrderValidateConfirmationDialog from "@/components/OrderValidateConfirmationDialog";
import PurchaseOrderDownloadDialog from "@/components/PurchaseOrderDownloadDialog";
import { toast } from "@/components/ui/use-toast";
import { checkValidationDesCommandes } from "@/lib/roleHelper";

const OrderDetailsPage: React.FC = () => {
  const { orderId, supplierId } = useParams();

  const dispatch = useDispatch();
  const supplier = useSelector(
    (state: RootState) => state.supplierReducer.supplier
  );
  const [orderDetails, setOrderDetails] = React.useState<IOrderDetails>(
    {} as IOrderDetails
  );
  const [orderHeaderInfo, setOrderHeaderInfo] = React.useState<IOrderHeader>(
    {} as IOrderHeader
  );
  const [baseProducts, setBaseProducts] = React.useState<Item[]>([]);
  const [checked, setChecked] = React.useState(false);
  const [orderItems, setOrderItems] = React.useState<Item[]>([]);
  const [selectedProducts, setSelectedProducts] = React.useState<Item[]>(
    [] as Item[]
  );

  const [isLoadingDrafts, setIsLoadingDrafts] = React.useState<boolean>(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );

  const fetchOrderDetails = async () => {
    if (supplierId && orderId) {
      const response = await getOrderDetails_(parseInt(orderId));
      if (response.status === 200) {
        setOrderDetails(response.data);
        setOrderItems(
          castArrayOrderItemDetailsToItems(response.data.orderDetails)
        );
        setBaseProducts(
          castArrayOrderItemDetailsToItems(response.data.orderDetails)
        );
        setOrderHeaderInfo({
          id: response.data.id,
          orderNumber: response.data.orderNumber,
          statusId: response.data.statusId,
          totalPriceHT: response.data.totalPriceHT,
          supplierLabel: response.data.providerLabel,
          supplierId: response.data.supplierId,
          orderDate: response.data.orderDate,
          totalTVA: response.data.totalTVA,
          totalPriceTTC: response.data.totalPriceTTC,
        });
      } else {
        toast({
          title: "Erreur",
          description:
            "Une erreur s'est produite lors de la récupération des détails de la commande",
          variant: "destructive",
        });
      }
      setIsLoading(false);
    }
  };

  const handleEdit = () => {
    if (checked) {
      setChecked(false);
      setSelectedProducts(orderItems);
    } else {
      setChecked(true);
      setSelectedProducts(baseProducts);
    }
  };

  const handleDelete = () => {
    dispatch(openDialog(DELETE_ORDER_DIALOG));
  };

  const handleValidate = () => {
    dispatch(openDialog(ORDER_VALIDATE_CONFIRMATION_DIALOG));
  };

  const handleDownload = () => {
    dispatch(openDialog(DOWNLOAD_CONFIRMATION_DIALOG));
  };

  useEffect(() => {
    setIsLoading(true);
    fetchOrderDetails();
  }, [isRefreshing]);

  return (
    <div className="flex-1 px-12 py-3">
      {isLoading ? (
        <OrderDetailsSkeleton />
      ) : (
        <div className="grid gap-6 md:gap-8">
          <div className="flex items-center justify-between">
            <div className="h-4 gap-4 flex-row flex align-middle">
              <h1 className="text-2xl font-bold">
                Commande N°{orderDetails.orderNumber}
              </h1>
              <OrderStatusTag status={orderDetails.statusId} />
            </div>
            <ActionBar
              checked={checked}
              handleEdit={handleEdit}
              hasEdit={
                orderDetails.statusId === 1 || orderDetails.statusId === 0
              }
              hasDelete={
                orderDetails.statusId === 1 || orderDetails.statusId === 0
              }
              statusId={orderDetails.statusId}
              handleDelete={handleDelete}
              hasDownload={orderDetails.statusId !== 0}
              handleDownload={handleDownload}
              handleValidate={handleValidate}
              hasValidate={
                orderDetails.statusId === 1 && checkValidationDesCommandes()
              }
            />
          </div>
          <OrderDetailsBar orderDetails={orderDetails} />

          <div className="h-4">
            <h1 className="text-xl font-bold">Produits commandés</h1>
          </div>
          {checked ? (
            <>
              <SupplierDetailsBar orderHeaderInfo={orderHeaderInfo} />
              <OrderAddBar
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                supplier={supplier}
                setIsLoadingDrafts={setIsLoadingDrafts}
                orderHeaderInfo={orderHeaderInfo}
              />
              <OrderItemsEditTable
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                handleEdit={handleEdit}
                orderDetails={orderDetails}
                isLoadingDrafts={isLoadingDrafts}
                orderHeaderInfo={orderHeaderInfo}
              />
            </>
          ) : (
            <>
              <OrderItemsTable items={orderItems} />
              <div className="flex flex-1 justify-end">
                <div className="w-1/3">
                  <PriceDetailsBar totalPrice={orderDetails.totalPriceHT} />
                </div>
              </div>
            </>
          )}
        </div>
      )}
      <Toaster />

      <OrderValidateConfirmationDialog orderId={orderDetails.id} />
      <OrderDeleteDialog
        orderId={orderDetails.id}
        forOrder
        forDraft={orderDetails.statusId === 0}
      />
      <PurchaseOrderDownloadDialog
        orderId={orderDetails.id}
        orderNumber={orderDetails.orderNumber}
      />
    </div>
  );
};

export default OrderDetailsPage;
