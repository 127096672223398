import axios, { AxiosResponse } from "axios";

import {
  IDeliveryDetails,
  IDeliveryDetailsRequest,
} from "@/interfaces/IDelivery";
import {
  get_delivery_details,
  upload_bl_url,
  download_brv_url,
} from "@/constants/urls";
import { IServerResponse_ } from "@/interfaces/IRequestResponse";

const accessToken = sessionStorage.getItem("token");

export async function getDeliveryDetails(
  id: string,
  payload: IDeliveryDetailsRequest
): Promise<AxiosResponse<IServerResponse_<IDeliveryDetails>>> {
  try {
    const response: AxiosResponse<IServerResponse_<IDeliveryDetails>> =
      await axios({
        url: `${get_delivery_details}${id}`,

        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        // TODO: Why the fuck is the body being sent using the GET method?
        data: payload,
        method: "GET",
      });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}

export async function uploadBlUrl(
  deliveryFiles: File[],
  cdaNumber: string
): // TODO: chnage types..
Promise<AxiosResponse<IServerResponse_<IDeliveryDetails[]>>> {
  const formData = new FormData();

  deliveryFiles.forEach((file: File) => {
    if (file) {
      formData.append("file", file, file.name);
    }
  });

  try {
    const response: AxiosResponse<IServerResponse_<IDeliveryDetails[]>> =
      await axios({
        url: upload_bl_url + cdaNumber,
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });
    return response;
  } catch (error: any) {
    return error.response;
  }
}

export async function downloadBrv(
  id: string
): Promise<AxiosResponse<IServerResponse_<string>>> {
  try {
    // TODO: chnage types..
    const response: AxiosResponse<IServerResponse_<string>> = await axios({
      url: `${download_brv_url}${id}`,

      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}
