import OrderDetailsSkeleton from "@/components/OrderDetailsSkeleton";
import { Toaster } from "@/components/ui/toaster";
import { RootState } from "@/store/store";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IInvoiceDetails, IOrderDetailsDTO } from "@/interfaces/IInvoice";
import { getInvoiceDetails } from "@/services/InvoiceDetailsService";
import InvoiceDetailsBar from "@/components/InvoiceDetailsBar";
import DeleteConfirmationDialog from "@/components/DeleteConfirmationDialog";

import InvoiceDocumentsViewDialog from "@/components/InvoiceDocumentsViewDialog";
import {
  IDeliveryDetails,
  IDeliveryDetailsRequest,
} from "@/interfaces/IDelivery";

import { getDeliveryDetails } from "@/services/DeliveryDetailsService";
import InvoiceStatusTag from "@/components/InvoiceStatusTag";
import { toast } from "@/components/ui/use-toast";

const OrderDetailsPage: React.FC = () => {
  const { id } = useParams();

  const [invoiceDetails, setInvoiceDetails] = React.useState<IInvoiceDetails>(
    {} as IInvoiceDetails
  );
  const [, setDeliveryDetails] = React.useState<IDeliveryDetails>(
    {} as IDeliveryDetails
  );
  /* const [baseProducts, setBaseProducts] = React.useState<IInvoiceProduct[]>(
    [] as IInvoiceProduct[]
  ); */
  // comment 3
  //const [checked, setChecked] = React.useState(false);
  const [, setOrderItems] = React.useState<IOrderDetailsDTO[]>(
    [] as IOrderDetailsDTO[]
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );
  const [invoiceTotal, setInvoiceTotal] = React.useState<number>(0);

  const fetchInvoiceDetails = async () => {
    if (id) {
      const response = await getInvoiceDetails(parseInt(id));
      if (response.status === 200) {
        await fetchDeliveryDetails(response.data.cdaNumber);
        setInvoiceDetails(response.data);
        setInvoiceTotal(response.data.amountTTC);
      } else {
        toast({
          title: "Erreur",
          description:
            "Une erreur s'est produite lors de la récupération des détails de la facture",
          variant: "destructive",
        });
      }
    }
    setIsLoading(false);
  };

  const fetchDeliveryDetails = async (receptionId: string) => {
    if (receptionId) {
      const response = await getDeliveryDetails(
        receptionId,
        {} as IDeliveryDetailsRequest
      );
      if (response.status === 200) {
        console.log("Delivery details", response.data);
        setDeliveryDetails(response.data.data);
        setOrderItems(response.data.data.orderDetailDto);
        console.log("Order items", response.data.data.orderDetailDto);
      } else {
        toast({
          title: "Erreur",
          description:
            "Une erreur s'est produite lors de la récupération des détails de la livraison",
          variant: "destructive",
        });
      }
    }
  };
  {
    /*
  const handleEdit = () => {
    if (checked) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  };
  */
  }
  {
    /*
  const handleDelete = () => {
    dispatch(openDialog(DELETE_ORDER_DIALOG));
  };
  /*}
  {/*
  const handleView = () => {
    dispatch(openDialog(INVOICE_DOCUMENTS_VIEW_DIALOG));
  };
  */
  }

  useEffect(() => {
    setIsLoading(true);
    fetchInvoiceDetails();
  }, [isRefreshing]);

  return (
    <div className="flex-1 px-12 py-3">
      {isLoading ? (
        <OrderDetailsSkeleton />
      ) : (
        <div className="grid gap-6 md:gap-8">
          <div className="flex items-center justify-between">
            <div className="h-4 gap-4 flex-row flex align-middle">
              <h1 className="text-2xl font-bold">
                Facture N°{invoiceDetails.invoiceNumber}
              </h1>
              <InvoiceStatusTag status={invoiceDetails.statut} />
            </div>
          </div>
          <InvoiceDetailsBar
            invoiceDetails={invoiceDetails}
            invoiceTotal={invoiceTotal}
          />
        </div>
      )}
      <Toaster />
      <DeleteConfirmationDialog forInvoice invoiceId={invoiceDetails.id} />
      <InvoiceDocumentsViewDialog links={invoiceDetails.invoiceDocuments} />
    </div>
  );
};

export default OrderDetailsPage;
