import { ISupplier } from "@/interfaces/ISupplier";
import React from "react";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Button } from "./ui/button";
import { File, Upload } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { InputTypes, PaymentTypes } from "@/constants/constants";

interface SupplierInputProps {
  label: string;
  defaultValue: string;
  isDisabled: boolean;
  supplierInfo: ISupplier;
  setSupplierInfo: (value: any) => void;
  fieldName: string;
  hasUploadButton?: boolean;
  file?: File;
  setFile?: (file: File) => void;
  fileLink?: string;
  noInput?: boolean;
  inputType?: string;
}

const SupplierInput: React.FC<SupplierInputProps> = ({
  label,
  defaultValue,
  isDisabled,
  setSupplierInfo,
  supplierInfo,
  fieldName,
  hasUploadButton,
  file,
  setFile,
  fileLink,
  noInput,
  inputType,
}) => {
  console.log("defaultValue of:", fieldName, typeof defaultValue, defaultValue);
  return (
    <div>
      <Label className="mb-2" htmlFor="label">
        {label}
      </Label>

      <div className="flex items-center space-x-2">
        {!noInput && inputType !== InputTypes.SELECT && (
          <Input
            defaultValue={defaultValue}
            disabled={!isDisabled}
            placeholder={"Vide"}
            type={inputType || "text"}
            min={0}
            id={label}
            onChange={(e) =>
              setSupplierInfo({
                ...supplierInfo,
                [fieldName]:
                  inputType === InputTypes.NUMBER
                    ? parseInt(e.target.value)
                    : e.target.value,
              })
            }
          />
        )}
        {inputType === InputTypes.SELECT && (
          <Select
            onValueChange={(value) =>
              setSupplierInfo({ ...supplierInfo, [fieldName]: parseInt(value) })
            }
            defaultValue={defaultValue}
          >
            <SelectTrigger className="w-full" disabled={!isDisabled}>
              <SelectValue
                placeholder={
                  PaymentTypes.find(
                    (type) => type.id === parseInt(defaultValue)
                  )?.label || "Vide"
                }
              />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {PaymentTypes.map((type) => (
                  <SelectItem key={type.id} value={type.id.toString()}>
                    {type.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        )}
        {hasUploadButton && isDisabled && (
          <div className="flex items-center space-x-2">
            <Button
              type="button"
              variant="outline"
              onClick={() => {
                const input = document.createElement("input");
                input.type = "file";
                input.onchange = (e) => {
                  const file =
                    (e.target as HTMLInputElement).files?.[0] || null;
                  if (setFile) setFile(file as File);
                };
                input.click();
              }}
            >
              <Upload className="h-4 w-4" />
            </Button>
          </div>
        )}
        {hasUploadButton && !isDisabled && fileLink && (
          <Button
            variant="outline"
            onClick={() => window.open(fileLink, "_blank")}
          >
            <File className="h-4 w-4" />
          </Button>
        )}
      </div>
      {file && file.name && (
        <span className="text-xs text-gray-500">{file.name}</span>
      )}
    </div>
  );
};

export default SupplierInput;
