import { ISupplier, ISupplierProduct } from "@/interfaces/ISupplier";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "./ui/use-toast";
import { checkIfOrderProduct } from "@/lib/utils";
import { draftOrder } from "@/services/OrderAddBarService";
import {
  IOrderHeader,
  IOrderDraftResponse,
  IOrderDraft,
} from "@/interfaces/IOrder";

import ProductSelect from "./ProductSelect";
import QuantitySelect from "./QuantitySelect";
import { Button } from "./ui/button";
import { Item } from "@/interfaces/Item";
import { Input } from "./ui/input";

interface OrderAddBarProps {
  suppliers?: ISupplier[];
  supplier?: ISupplier;
  selectedProducts: Item[];
  setSelectedProducts: React.Dispatch<React.SetStateAction<Item[]>>;
  orderHeaderInfo?: IOrderHeader;
  setIsLoadingDrafts: React.Dispatch<React.SetStateAction<boolean>>;
}
const OrderAddBar: React.FC<OrderAddBarProps> = ({
  suppliers,
  supplier,
  orderHeaderInfo,
  selectedProducts,
  setSelectedProducts,
  setIsLoadingDrafts,
}) => {
  const [selectedSupplier] = React.useState<ISupplier>({} as ISupplier);
  const [selectedProduct, setSelectedProduct] =
    React.useState<ISupplierProduct>({} as ISupplierProduct);
  const [selectedQuantity, setSelectedQuantity] = React.useState<number>(1);
  const [clearInput, setClearInput] = React.useState<boolean>(false);

  const { orderId } = useParams();
  const parsedOrderId = parseInt(orderId ?? "", 10);

  const handleAddProduct = () => {
    const orderProduct: Item = {
      productId: selectedProduct.productId,
      quantity: selectedQuantity,
      id: 0,
      gtin: selectedProduct.gtin,
      title: selectedProduct.title,
      unitPriceHT: selectedProduct.priceUnitHT,
      unitPriceTTC: 0,
      tva: 0,
      productSupplierId: selectedProduct.supplierProductId,
    };

    if (!selectedProduct.productId) {
      toast({
        title: "Erreur",
        description: "Veuillez sélectionner un produit",
        variant: "destructive",
      });
    } else if (checkIfOrderProduct(orderProduct, selectedProducts)) {
      toast({
        title: "Erreur",
        description: "Ce produit est déjà ajouté",
        variant: "destructive",
      });
    } else if (selectedQuantity === 0) {
      toast({
        title: "Erreur",
        description: "Veuillez entrer une quantité",
        variant: "destructive",
      });
    } else {
      setSelectedProduct({} as ISupplierProduct);
      setSelectedQuantity(1);

      handleDraftProduct(orderProduct);
    }
  };

  const handleDraftProduct = async (orderProduct: Item) => {
    const draft = {
      OrderId: parsedOrderId || (orderHeaderInfo && orderHeaderInfo.id),
      OrderDetailCreate: {
        IdLigne: null,
        productSupplierId: orderProduct.productSupplierId,
        quantity: orderProduct.quantity,
      },
    };
    setClearInput(true);
    setIsLoadingDrafts(true);
    const response = await draftOrder(draft as IOrderDraft);
    if (response.status === 200) {
      const orderProduct: Item[] = response.data.data.map(
        (item: IOrderDraftResponse) => {
          return {
            id: item.idLigne,
            productSupplierId: item.productSupplierId,
            productId: item.prodcutid,
            gtin: item.gtin,
            unitPriceHT: item.unitPriceHT,
            unitPriceTTC: item.unitPriceTTC,
            tva: item.tva,
            quantity: item.quantity,
            title: item.title,
          };
        }
      );

      setSelectedProducts(orderProduct);
      // toast({
      //   title: "Brouillon",
      //   description: "La commande a été ajoutée au Brouillon avec succès",
      //   variant: "success",
      // });
    } else {
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de l'ajout au Brouillon",
        variant: "destructive",
      });
    }
    setClearInput(false);
    setIsLoadingDrafts(false);
  };

  useEffect(() => {
    if (suppliers) {
      setSelectedProducts([]);
    }
    setSelectedProduct({} as ISupplierProduct);
    setSelectedQuantity(0);
  }, [selectedSupplier]);

  useEffect(() => {
    setSelectedQuantity(0);
  }, [selectedProduct]);

  return (
    <div className="grid gap-6">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <ProductSelect
          supplier={supplier as ISupplier}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
        />
        <div className="grid gap-2">
          <label className="text-sm font-medium" htmlFor="quantity">
            Prix d'achat (HT)
          </label>
          <Input
            className="w-full"
            id="quantity"
            value={clearInput ? "" : selectedProduct.priceUnitHT}
            disabled
            placeholder="Prix d'achat HT"
          />
        </div>
        <QuantitySelect
          selectedQuantity={selectedQuantity}
          setSelectedQuantity={setSelectedQuantity}
        />
        <div className="grid gap-2">
          <label className="text-sm font-medium" htmlFor="product">
            &nbsp;&nbsp;
          </label>
          <Button onClick={handleAddProduct}>Ajouter</Button>
        </div>
      </div>
    </div>
  );
};

export default OrderAddBar;
