import axios, { AxiosResponse } from "axios";
import { supplier_product_price_item_delete_url } from "@/constants/urls";

const accessToken = sessionStorage.getItem("token");

export async function deleteSupplierProductPriceItem(
  priceId: number
): Promise<AxiosResponse> {
  try {
    const response: AxiosResponse = await axios({
      url: supplier_product_price_item_delete_url + priceId,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "DELETE",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
