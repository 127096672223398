import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { IStockItem } from "@/interfaces/IStock";
import StockItem from "./StockItem";

interface StocksTableProps {
  stocks: IStockItem[];
}

const StocksTable: React.FC<StocksTableProps> = ({ stocks }) => {
  return (
    <Table>
      <TableHeader>
        <TableRow className="bg-gray-100">
          <TableHead className="sm:table-cell">Produit</TableHead>
          <TableHead className="hidden lg:table-cell">Titre</TableHead>
          <TableHead className="sm:table-cell text-center">Qté reçue</TableHead>
          <TableHead className="sm:table-cell text-center">
            Qté validée
          </TableHead>
          <TableHead className="sm:table-cell text-center">
            Prix d'achat (HT)
          </TableHead>
          <TableHead className="sm:table-cell text-center">
            Prix d'achat validé (HT)
          </TableHead>
          <TableHead className="hidden md:table-cell">Qté vendue</TableHead>
          <TableHead className="hidden md:table-cell">Qté disponible</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {stocks && stocks.length > 0 ? (
          stocks.map((stock) => <StockItem key={stock.id} stockItem={stock} />)
        ) : (
          <TableRow>
            <TableCell colSpan={8} className="text-center">
              Aucun stock trouvé
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default StocksTable;
