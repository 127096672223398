import axios, { AxiosResponse } from "axios";
import { auth_url } from "@/constants/urls";
import { IUserCredentials, IUserCredentials_ } from "@/interfaces/IUser";
import { basic_auth_url } from "@/constants/urls";
import { IServerResponse_ } from "@/interfaces/IRequestResponse";

export async function authenticateUser(
  email: string,
  password: string
): Promise<AxiosResponse<IUserCredentials>> {
  try {
    const response = await axios({
      url: auth_url,
      headers: {
        tenant: "marjanemall",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: {
        email: email,
        password: password,
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}

export async function BasicAth(
  email: string
): Promise<AxiosResponse<IServerResponse_<IUserCredentials_>>> {
  try {
    const response = await axios({
      url: basic_auth_url,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        postObject: email,
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}
