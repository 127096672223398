import axios, { AxiosResponse } from "axios";
import { supplier_details_url } from "@/constants/urls";
import { ISupplier } from "@/interfaces/ISupplier";

const accessToken = sessionStorage.getItem("token");

export async function getSupplierDetails(
  id: number
): Promise<AxiosResponse<ISupplier>> {
  try {
    const response: AxiosResponse<ISupplier> = await axios({
      url: supplier_details_url + id,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
