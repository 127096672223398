import axios, { AxiosResponse } from "axios";
import { order_details_url_get } from "@/constants/urls";
import { IOrderDetails } from "@/interfaces/IOrder";

const accessToken = sessionStorage.getItem("token");

export async function getOrderDetails_(
  orderId: number
): Promise<AxiosResponse<IOrderDetails>> {
  try {
    const response: AxiosResponse<IOrderDetails> = await axios({
      url: order_details_url_get + orderId,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
