import axios, { AxiosResponse } from "axios";

import { create_delivery_url } from "@/constants/urls";
import { IDeliveryItemRequest } from "@/interfaces/IDelivery";

export async function createDelivery(
  deliveryItems: IDeliveryItemRequest[],
  orderId: number
): Promise<AxiosResponse> {
  const accessToken = sessionStorage.getItem("token");
  try {
    const response = await axios({
      url: create_delivery_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        id: 0,
        orderId: orderId,
        deliveryItems: deliveryItems,
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
