import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { INVOICE_DOCUMENTS_VIEW_DIALOG } from "@/constants/constants";
import { closeDialog } from "@/store/reducers/dialogReducer";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Download } from "lucide-react";
import { truncateFilename } from "@/lib/utils";

interface InvoiceDocumentsViewDialogProps {
  links: string[] | null;
}

const InvoiceDocumentsViewDialog: React.FC<InvoiceDocumentsViewDialogProps> = ({
  links,
}) => {
  const dispatch = useDispatch();
  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialogReducer
  );
  const isOpened = isOpen && dialogType === INVOICE_DOCUMENTS_VIEW_DIALOG;

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const handleOpenLink = (link: string) => {
    window.open(link, "_blank");
  };
  return (
    <Dialog open={isOpened} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Documents Facture</DialogTitle>
        </DialogHeader>

        <div className="flex flex-col space-y-4">
          {links && links.length > 0 ? (
            links.map((link, index) => (
              <div key={index} className="flex items-center gap-4">
                <Input
                  key={index}
                  value={truncateFilename(link, 15, 15)}
                  className="border-none"
                  disabled
                />
                <Button
                  variant="outline"
                  className="hover:bg-white border-none"
                  onClick={() => handleOpenLink(link)}
                >
                  <Download className="w-5 h-5" />
                </Button>
              </div>
            ))
          ) : (
            <p>No files available.</p>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InvoiceDocumentsViewDialog;
