import React from "react";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "./ui/pagination";
import { IPageData } from "@/interfaces/IPageData";
import { useDispatch } from "react-redux";
import { setPage } from "@/store/reducers/pageReducer";

interface TablePaginationProps {
  pageData: IPageData;
}

const TablePagination: React.FC<TablePaginationProps> = ({ pageData }) => {
  const dispatch = useDispatch();

  return (
    <footer className="flex justify-center">
      <Pagination className="mt-4">
        <PaginationContent>
          {pageData.hasPreviousPage && (
            <PaginationItem>
              <PaginationPrevious
                onClick={() => dispatch(setPage(pageData.currentPage - 1))}
              />
            </PaginationItem>
          )}
          {pageData.currentPage - 3 >= 1 && (
            <PaginationItem>
              <PaginationEllipsis
                onClick={() => dispatch(setPage(pageData.currentPage - 3))}
              />
            </PaginationItem>
          )}
          {pageData.hasPreviousPage && (
            <PaginationItem
              onClick={() => dispatch(setPage(pageData.currentPage - 1))}
            >
              <PaginationLink>{pageData.currentPage - 1}</PaginationLink>
            </PaginationItem>
          )}
          <PaginationItem>
            <PaginationLink isActive>{pageData.currentPage}</PaginationLink>
          </PaginationItem>
          {pageData.hasNextPage &&
            pageData.currentPage <= pageData.totalPages && (
              <PaginationItem
                onClick={() => dispatch(setPage(pageData.currentPage + 1))}
              >
                <PaginationLink>{pageData.currentPage + 1}</PaginationLink>
              </PaginationItem>
            )}
          {pageData.currentPage + 3 < pageData.totalPages && (
            <PaginationItem>
              <PaginationEllipsis
                onClick={() => dispatch(setPage(pageData.currentPage + 3))}
              />
            </PaginationItem>
          )}
          {pageData.currentPage + 1 < pageData.totalPages && (
            <PaginationItem>
              <PaginationNext
                title="Suivant"
                onClick={() => dispatch(setPage(pageData.currentPage + 1))}
              />
            </PaginationItem>
          )}
        </PaginationContent>
      </Pagination>
    </footer>
  );
};

export default TablePagination;
