import React from "react";
import UtilityBar from "./UtilityBar";
import { ScrollArea } from "./ui/scroll-area";
import { ISupplierProduct } from "@/interfaces/ISupplier";
import SupplierProductsTable from "./SupplierProductTable";
import { checkAssignationDesProduits } from "@/lib/roleHelper";

interface SupplierProductSectionProps {
  products: ISupplierProduct[];
}

const SupplierProductSection: React.FC<SupplierProductSectionProps> = ({
  products,
}) => {
  return (
    <div className="bg-white rounded-lg  mb-8 mt-8">
      <UtilityBar hasCreateButton={checkAssignationDesProduits()} />
      <div className="overflow-x-auto">
        <ScrollArea className="h-[60vh]">
          <SupplierProductsTable products={products} />
        </ScrollArea>
      </div>
    </div>
  );
};

export default SupplierProductSection;
