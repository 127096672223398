import axios, { AxiosResponse } from "axios";
import { supplier_check_url } from "@/constants/urls";
import { IServerResponse_ } from "@/interfaces/IRequestResponse";
import { ISupplier } from "@/interfaces/ISupplier";

const accessToken = sessionStorage.getItem("token");

export async function checkSupplier(
  type: number,
  keyword: string
): Promise<AxiosResponse<IServerResponse_<ISupplier>>> {
  try {
    const response: AxiosResponse<IServerResponse_<ISupplier>> = await axios({
      url: supplier_check_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        type: type,
        keyword: keyword,
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}
