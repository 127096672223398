import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { IMovement } from "@/interfaces/IStock";
import StockMovement from "./StockMovement";

interface StockMovementTableProps {
  movements: IMovement[];
}

const StockMovementTable: React.FC<StockMovementTableProps> = ({
  movements,
}) => {
  return (
    <Table>
      <TableHeader>
        <TableRow className="bg-gray-100">
          <TableHead className="sm:table-cell ">Identifiant</TableHead>
          <TableHead className="sm:table-cell text-center">Type</TableHead>
          <TableHead className="sm:table-cell text-center ">
            Date de mouvement
          </TableHead>
          <TableHead className="sm:table-cell text-center">
            Prix d'achat
          </TableHead>
          <TableHead className="sm:table-cell text-center">
            Prix de vente
          </TableHead>
          <TableHead className="sm:table-cell text-center">Quantité</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {movements.length > 0 ? (
          movements.map((movement) => (
            <StockMovement key={movement.id} movement={movement} />
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={7} className="text-center">
              Aucune movement trouvé
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default StockMovementTable;
