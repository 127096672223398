import React, { useState, useEffect, createContext, useContext } from "react";
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { LoaderCircle } from "lucide-react";
import { BasicAth } from "@/services/authService";
import { encryptMessage } from "@/lib/utils";
import { Toaster } from "@/components/ui/toaster";
import Root from "./components/Root";
import LoginPage from "./pages/LoginPage";

const AuthContext = createContext<{
  token: string | null;
  role: string | null;
  setAuth: (
    token: string | null,
    role: string | null,
    actions: string[]
  ) => void;
  clearAuth: () => void;
  isAuthReady: boolean;
  actions: string[];
} | null>(null);

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error("useAuth must be used within an AuthProvider");
  return context;
};

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [token, setToken] = useState<string | null>(null);
  const [role, setRole] = useState<string | null>(null);
  const [actions, setActions] = useState<string[]>([]);
  const [isAuthReady, setIsAuthReady] = useState(false);

  useEffect(() => {
    const storedToken = sessionStorage.getItem("token");
    const storedRole = sessionStorage.getItem("role");
    console.log("storedToken", storedToken);
    console.log("storedRole", storedRole);

    if (storedToken && storedRole) {
      setToken(storedToken);
      setRole(storedRole);
    }
    setIsAuthReady(true);
  }, []);

  const setAuth = (
    newToken: string | null,
    newRole: string | null,
    actions: string[]
  ) => {
    setToken(newToken);
    setRole(newRole);
    if (newToken && newRole) {
      sessionStorage.setItem("token", newToken);
      sessionStorage.setItem("role", newRole);
      sessionStorage.setItem("actions", actions.join(","));
    }
    console.log("actions", actions);
  };

  const clearAuth = () => {
    setToken(null);
    setRole(null);
    setActions([]);
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("actions");
  };

  return (
    <AuthContext.Provider
      value={{ token, role, setAuth, clearAuth, isAuthReady, actions }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const MainContent = () => {
  const { instance, accounts } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const [isLoading, setIsLoading] = useState(false);
  const { token, setAuth, clearAuth, isAuthReady } = useAuth();

  const handleAuthentication = async () => {
    if (!activeAccount || !accounts[0]?.username) return;

    const jsonString = JSON.stringify({ Email: accounts[0]?.username });

    try {
      setIsLoading(true);
      const encryptedMessage = await encryptMessage(jsonString);
      if (!encryptedMessage) {
        console.error("Encryption failed");
        clearAuth();
        instance.logoutRedirect();
        return;
      }

      const response = await BasicAth(encryptedMessage);

      if (!response.data.success) {
        console.log("Authentication failed", response.data.message);
        clearAuth();
        instance.logoutRedirect();
        return;
      }

      const newToken = response.data?.data.jwtToken;
      const role = response.data?.data.role || null;
      const actions = response.data?.data.actions || [];

      setAuth(newToken, role, actions);
      window.location.href = "/products";
    } catch (error) {
      console.log("Authentication failed", error);
      clearAuth();
      instance.logoutRedirect();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log("activeAccount", activeAccount);
    console.log("token", token);
    console.log("isAuthReady", isAuthReady);
    if (isAuthReady && !token && activeAccount) {
      console.log("handleAuthentication");
      handleAuthentication();
    }
  }, [activeAccount, token, isAuthReady]);

  if ((!isAuthReady || isLoading) && !token) {
    return (
      <div className="absolute inset-0 flex items-center justify-center h-full w-full bg-gradient-to-r from-teal-600 to-teal-200">
        <div className="p-6 bg-white rounded-lg shadow-lg flex items-center space-x-4">
          <LoaderCircle className="w-6 h-6 animate-spin" />
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      <AuthenticatedTemplate>
        {activeAccount && token ? <Root /> : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
      <Toaster />
    </div>
  );
};

const App: React.FC<{ instance: any }> = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <AuthProvider>
        <MainContent />
      </AuthProvider>
    </MsalProvider>
  );
};

export default App;
