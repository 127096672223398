import InvoiceStatusTag from "@/components/InvoiceStatusTag";
import LitigeDetailsBar from "@/components/LitigeDetailsBar";
import LitigeItemsTable from "@/components/LitigeItemsTable";
import PriceDetailsBar_ from "@/components/PriceDetailsBar_";
import TableSkeleton from "@/components/TableSkeleton";
import { Toaster } from "@/components/ui/toaster";
import { toast } from "@/components/ui/use-toast";
import { ILitigeDetails, ILitigeItem } from "@/interfaces/ILitige";
import { getLitigeDetails } from "@/services/LitigeDetailsService";

import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

const LitigeDetailsPage: React.FC = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [litigeItems, setLitigeItems] = useState<ILitigeItem[]>([]);
  const [litigeDetails, setLitigeDetails] = useState<ILitigeDetails>(
    {} as ILitigeDetails
  );
  const [total, setTotal] = useState(0);

  const fetchLitigeDetails = async () => {
    if (!id) return;
    setIsLoading(true);
    try {
      const response = await getLitigeDetails(parseInt(id));
      if (response.status === 200) {
        setLitigeDetails(response.data.data);
        setLitigeItems(response.data.data.invoiceReceptionOrderItems);
      } else {
        toast({
          title: "Erreur",
          description:
            "Une erreur s'est produite lors de la récupération des détails du litige",
          variant: "destructive",
        });
      }
    } catch (error) {
      toast({
        title: "Erreur",
        description:
          "Une erreur s'est produite lors de la récupération des détails du litige",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLitigeDetails();
  }, [id]);

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 px-12 py-8 overflow-y-auto">
        {isLoading ? (
          <TableSkeleton rows={10} cols={6} />
        ) : (
          <div className="grid gap-6 md:gap-8">
            <div className="flex items-center justify-between">
              <div className="h-4 gap-4 flex-row flex align-middle">
                <h1 className="text-2xl font-bold">
                  Facture N°{litigeDetails.invoiceNumber}
                </h1>
                <InvoiceStatusTag invoiceId={litigeDetails.id} />
              </div>
            </div>
            <LitigeDetailsBar litigeDetails={litigeDetails} />
            <LitigeItemsTable
              invoiceId={litigeDetails.id}
              litigeItems={litigeItems}
              setTotal={setTotal}
              setLitigeItems={setLitigeItems}
              total={total}
              invoiceTotal={litigeDetails.amountHT || 0}
              brvTotal={litigeDetails.amountHtBRV || 0}
              litigeStatus={litigeDetails.statut}
            />
          </div>
        )}
      </div>

      <PriceDetailsBar_
        totalPrice={total}
        invoiceTotal={litigeDetails.amountHT || 0}
        totalBRV={litigeDetails.amountHtBRV || 0}
      />

      <Toaster />
    </div>
  );
};

export default LitigeDetailsPage;
