import axios, { AxiosResponse } from "axios";
import {
  cancel_order_url,
  delete_order_url,
  delete_order_line_url,
} from "@/constants/urls";

const accessToken = sessionStorage.getItem("token");

export async function cancelOrder(id: number): Promise<AxiosResponse> {
  try {
    const response = await axios({
      url: cancel_order_url + id,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}

export async function deleteOrder(id: number): Promise<AxiosResponse> {
  try {
    const response = await axios({
      url: delete_order_url + id,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "DELETE",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}

export async function deleteOrderLine(id: number): Promise<AxiosResponse> {
  try {
    const response = await axios({
      url: delete_order_line_url + id,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "DELETE",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
