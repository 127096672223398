import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { IOrder } from "@/interfaces/IOrder";
import Order from "./Order";
import { ISupplier } from "@/interfaces/ISupplier";

interface OrderTableProps {
  orders: IOrder[];
  suppliers?: ISupplier[];
  forOrderCreation?: boolean;
}

const OrderTable: React.FC<OrderTableProps> = ({
  orders,
  suppliers,
  forOrderCreation,
}) => {
  return (
    <Table>
      <TableHeader>
        <TableRow className="bg-gray-100">
          <TableHead className="sm:table-cell">N° Commande</TableHead>
          <TableHead className="sm:table-cell">Fournisseur</TableHead>

          <TableHead className="sm:table-cell text-center">
            Date de commande
          </TableHead>
          <TableHead className="sm:table-cell text-center">
            Nombre de produits
          </TableHead>
          <TableHead className="sm:table-cell">Total (TTC)</TableHead>
          <TableHead className="sm:table-cell">Statut</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {orders && orders.length > 0 ? (
          orders.map((order) => (
            <Order
              key={order.id}
              order={order}
              forOrderCreation={forOrderCreation}
              supplier={
                suppliers?.find(
                  (supplier) => supplier.id === order.supplierId
                ) ||
                ({
                  label: "N/A",
                } as ISupplier)
              }
            />
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={7} className="text-center">
              Aucune commande trouvée
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default OrderTable;
