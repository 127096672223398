import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOrder } from "@/interfaces/IOrder";

interface OrderState {
  order: IOrder;
}

const initialState: OrderState = {
  order: {
    id: 0,
    orderNumber: "",
    totalPrice: 0,
    isCompleted: false,
    creationDate: "",
    providerId: 0,
    orderItems: [],
    providerLabel: "",
    isDelivered: false,
  },
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrder: (state, action: PayloadAction<IOrder>) => {
      state.order = action.payload;
    },
    clearOrder: (state) => {
      state.order = initialState.order;
    },
  },
});

export const { setOrder, clearOrder } = orderSlice.actions;

export default orderSlice.reducer;
