import React, { useEffect } from "react";
import { TableCell, TableRow } from "./ui/table";
import { formatDateTime } from "@/lib/utils";
import { IMovement } from "@/interfaces/IStock";
import {
  delivery_movement,
  return_movement,
  sale_movement,
} from "@/constants/constants";

interface StockMovementProps {
  movement: IMovement;
}

const StockMovement: React.FC<StockMovementProps> = ({ movement }) => {
  useEffect(() => {
    console.log(movement);
  }, []);
  return (
    <TableRow key={movement.id} className="cursor-pointer hover:bg-gray-100">
      <TableCell className=" sm:table-cell font-semibold ">
        {movement.id}
      </TableCell>
      <TableCell className="sm:table-cell text-center">
        {movement.movementType === "Réception"
          ? delivery_movement
          : movement.movementType === "Vente"
          ? sale_movement
          : movement.movementType === "Retour"
          ? return_movement
          : movement.movementType}
      </TableCell>
      <TableCell className="sm:table-cell text-center">
        {formatDateTime(movement.movementDate)}
      </TableCell>

      <TableCell className="sm:table-cell text-center">
        {movement.movementType === "Réception"
          ? movement.purchaseUnitPrice
          : "-"}{" "}
        MAD
      </TableCell>
      <TableCell className="sm:table-cell text-center">
        {movement.movementType === "Vente" ? movement.sellingPrice : "-"} MAD
      </TableCell>
      <TableCell className="sm:table-cell text-center">
        {movement.quantity}
      </TableCell>
    </TableRow>
  );
};

export default StockMovement;
