import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { IProduct, IRetailProduct } from "@/interfaces/IProduct";
import Product from "./Product";
import RetailProduct from "./RetailProduct";

interface ProductsTableProps {
  products: IProduct[];
  retailProducts?: IRetailProduct[];
  forRetail?: boolean;
  forAssign?: boolean;
}

const ProductsTable: React.FC<ProductsTableProps> = ({
  products,
  forRetail,
  forAssign,
  retailProducts,
}) => {
  return (
    <Table className="drop-shadow-sm bg-white rounded-r-xl">
      <TableHeader>
        <TableRow>
          <TableHead className="hidden xl:table-cell">Image</TableHead>
          <TableHead className="sm:table-cell">Produit</TableHead>
          <TableHead className="hidden lg:table-cell w-[200px]">
            Titre
          </TableHead>
          <TableHead className="hidden md:table-cell">Marque</TableHead>
          <TableHead className="md:table-cell">Catégories</TableHead>
          {forRetail && (
            <>
              <TableHead className="hidden sm:table-cell">
                Fournisseur
              </TableHead>
              <TableHead className="hidden sm:table-cell w-[150px]">
                Prix d'achat (HT)
              </TableHead>
              <TableHead className="hidden sm:table-cell w-[100px]">
                TVA (%)
              </TableHead>
            </>
          )}
        </TableRow>
      </TableHeader>
      <TableBody>
        {forRetail && retailProducts && retailProducts.length > 0 ? (
          retailProducts.map((product) => (
            <RetailProduct key={product.id} product={product} />
          ))
        ) : products && products.length > 0 ? (
          products.map((product) => (
            <Product key={product.id} product={product} forAssign={forAssign} />
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={9} className="text-center">
              Aucun produit trouvé
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default ProductsTable;
