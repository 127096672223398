import { IProductToAssign } from "@/interfaces/IProduct";
import React from "react";
import { ScrollArea } from "./ui/scroll-area";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { formateDate } from "@/lib/utils";

interface ProductAssignItemsTableProps {
  selectedProducts: IProductToAssign[];
}

const ProductAssignItemsTable: React.FC<ProductAssignItemsTableProps> = ({
  selectedProducts,
}) => {
  return (
    <ScrollArea className="max-h-[250px] mb-4 px-2">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>SKU</TableHead>
            <TableHead>Titre</TableHead>
            <TableHead className="text-center">Date début</TableHead>
            <TableHead className="text-center">Date fin</TableHead>
            <TableHead className="text-center">Prix d'achat (MAD)</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {selectedProducts.length > 0 ? (
            selectedProducts.map((product) => (
              <TableRow key={product.product.productId}>
                <TableCell>{product.product.productId}</TableCell>
                <TableCell>{product.product.title}</TableCell>
                <TableCell className="text-center">
                  {formateDate(product.startDate) || "N/A"}
                </TableCell>
                <TableCell className="text-center">
                  {product.endDate === null
                    ? "-"
                    : formateDate(product.endDate)}
                </TableCell>
                <TableCell className="text-center">
                  {product.unitPrice}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} className="text-center">
                Aucun produit sélectionné
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </ScrollArea>
  );
};

export default ProductAssignItemsTable;
