import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { DELIVERY_NOTE_UPLOAD_DIALOG } from "@/constants/constants";
import { closeDialog } from "@/store/reducers/dialogReducer";
import { clearOrder } from "@/store/reducers/orderReducer";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { X } from "lucide-react";
import { truncateFilename } from "@/lib/utils";
import { uploadBlUrl } from "@/services/DeliveryDetailsService";
import { useParams } from "react-router-dom";
import { toast } from "@/components/ui/use-toast";
import { getDeliveryDetails } from "@/services/DeliveryDetailsService";
import { setRefreshing } from "@/store/reducers/refreshReducer";

interface DeliveryNoteUploadDialogProps {
  deliveryFiles: File[];
  setDeliveryFiles: (files: File[]) => void;
}

const DeliveryNoteUploadDialog: React.FC<DeliveryNoteUploadDialogProps> = ({
  deliveryFiles,
  setDeliveryFiles,
}) => {
  const dispatch = useDispatch();
  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialogReducer
  );
  const isOpened = isOpen && dialogType === DELIVERY_NOTE_UPLOAD_DIALOG;
  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );

  const { id = "" } = useParams();

  const handleFileChange = (files: File[]) => {
    setDeliveryFiles([...deliveryFiles, ...Array.from(files)]);
  };

  const handleClose = () => {
    dispatch(closeDialog());
    dispatch(clearOrder());
  };

  const handleCancel = () => {
    dispatch(closeDialog());
    setDeliveryFiles([]);
  };

  const handleConfirm = async () => {
    const response = await uploadBlUrl(deliveryFiles, id);

    const payload = {
      keyword: "",
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
    };

    if (response.status === 200) {
      toast({
        title: "Succès",
        description: response.data.message,
        variant: "success",
      });

      await getDeliveryDetails(id, payload);
    } else {
      toast({
        title: "Erreur",
        description: "Erreur lors de l'importation du BL",
        variant: "destructive",
      });
    }
    dispatch(setRefreshing(!isRefreshing));
    dispatch(closeDialog());
  };

  return (
    <Dialog open={isOpened} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Importer bon de livraison </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-2">
          <Button
            variant="outline"
            className="w-full justify-start hover:bg-white"
            onClick={() => {
              const input = document.createElement("input");
              input.type = "file";
              input.multiple = true;
              input.accept = [".pdf"].join(",");
              input.onchange = (e) => {
                const target = e.target as HTMLInputElement;
                if (target.files && target.files.length > 0) {
                  handleFileChange(Array.from(target.files));
                }
              };
              input.click();
            }}
          >
            <div className="flex items-center gap-2">
              <span className="text-sm">Ajouter un fichier</span>
              <span className="text-xs text-gray-500">.pdf</span>
            </div>
          </Button>
          {deliveryFiles.length > 0 && (
            <div className="space-y-2">
              {deliveryFiles.map((file, index) => (
                <div className="flex items-center ">
                  <Input
                    key={index}
                    value={truncateFilename(file.name, 15, 15)}
                    className="border-none"
                    disabled
                  />
                  <Button
                    variant="outline"
                    className="hover:bg-white border-none"
                    onClick={() =>
                      setDeliveryFiles(deliveryFiles.filter((f) => f !== file))
                    }
                  >
                    <X className="w-4 h-4" />
                  </Button>
                </div>
              ))}
            </div>
          )}

          <div className="flex justify-end gap-2 mt-4">
            <Button onClick={handleCancel} variant="outline">
              Annuler
            </Button>
            <Button onClick={handleConfirm}>Confirmer</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeliveryNoteUploadDialog;
