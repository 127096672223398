import { IDelivery } from "@/interfaces/IDelivery";
import React from "react";
import { TableCell, TableRow } from "./ui/table";
import { formateDate } from "@/lib/utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import Highlighter from "react-highlight-words";

interface DeliveryProps {
  delivery: IDelivery;
}

const Delivery: React.FC<DeliveryProps> = ({ delivery }) => {
  const navigation = useNavigate();
  const searchKeyword = useSelector(
    (state: RootState) => state.searchReduer.searchText
  );
  // TODO: fix the handleShowDetails function, the id is messing
  const handleShowDetails = () => {
    navigation("/deliveries/" + delivery.receptionNumber);
  };

  return (
    <>
      <TableRow
        key={delivery.receptionNumber}
        onClick={handleShowDetails}
        className="cursor-pointer hover:bg-gray-100"
      >
        <TableCell className=" sm:table-cell font-semibold">
          <Highlighter
            highlightClassName="bg-yellow-200 dark:bg-yellow-500"
            searchWords={[searchKeyword]}
            autoEscape={true}
            textToHighlight={delivery.orderNumber || "N/A"}
          />
        </TableCell>
        <TableCell className=" sm:table-cell font-semibold">
          <Highlighter
            highlightClassName="bg-yellow-200 dark:bg-yellow-500"
            searchWords={[searchKeyword]}
            autoEscape={true}
            textToHighlight={delivery.receptionNumber || "N/A"}
          />
        </TableCell>
        <TableCell className="sm:table-cell">
          <Highlighter
            highlightClassName="bg-yellow-200 dark:bg-yellow-500"
            searchWords={[searchKeyword]}
            autoEscape={true}
            textToHighlight={delivery.supplierLibelle || "N/A"}
          />
        </TableCell>
        <TableCell className="sm:table-cell text-center">
          {formateDate(new Date(delivery.creationDate)?.toDateString())}
        </TableCell>
        <TableCell className="sm:table-cell text-center">
          {formateDate(new Date(delivery.receptionDate)?.toDateString())}
        </TableCell>
        <TableCell className="sm:table-cell text-center">
          {delivery.productCount}
        </TableCell>
        <TableCell className="sm:table-cell text-center">
          {delivery.totalPricTtc && delivery.totalPricTtc.toFixed(2)}
        </TableCell>
      </TableRow>
    </>
  );
};

export default Delivery;
