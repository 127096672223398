import axios, { AxiosResponse } from "axios";
import { update_delivery_url } from "@/constants/urls";
import { IDeliveryItemRequest } from "@/interfaces/IDelivery";

const accessToken = sessionStorage.getItem("token");

export async function updateDelivery(
  id: number,
  deliveryItems: IDeliveryItemRequest[],
  orderId: number
): Promise<AxiosResponse> {
  try {
    const response: AxiosResponse = await axios({
      url: update_delivery_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        id: id,
        orderId: orderId,
        deliveryItems: deliveryItems,
      },
      method: "PUT",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
