import React, { useEffect } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { cn } from "@/lib/utils";
import {
  CommandInput,
  CommandEmpty,
  CommandList,
  CommandGroup,
  CommandItem,
  Command,
} from "./ui/command";
import { Check, ChevronsUpDown } from "lucide-react";
import { Button } from "./ui/button";
import { ICategory } from "@/interfaces/ICategory";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";

interface CategorySelectProps {
  categories: ICategory[];
  selectedCategory: ICategory;
  setSelectedCategory: (category: ICategory) => void;
  label: string;
}

const CategorySelect: React.FC<CategorySelectProps> = ({
  categories,
  selectedCategory,
  setSelectedCategory,
  label,
}) => {
  const [categoryOpen, setCategoryOpen] = React.useState(false);
  const disabled = useSelector(
    (state: RootState) => state.filterDisableReducer.filterDisabled
  );

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength - 3) + "...";
  };

  const ButtonText = selectedCategory?.valeur
    ? selectedCategory.valeur.toLowerCase()
    : label;

  useEffect(() => {
    console.log("disabled", disabled);
  }, [disabled]);

  return (
    <div>
      <Popover open={categoryOpen} onOpenChange={setCategoryOpen}>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <PopoverTrigger asChild disabled={disabled}>
                <Button
                  className="w-full justify-between col-span-3"
                  role="combobox"
                  variant="outline"
                  aria-expanded={categoryOpen}
                >
                  <span className="truncate mr-2">
                    {truncateText(ButtonText, 15)}
                  </span>
                  <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
            </TooltipTrigger>
            <TooltipContent side="bottom" className="max-w-xs">
              {ButtonText}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <PopoverContent className="w-full p-0">
          <Command>
            <CommandInput
              className="h-9"
              placeholder="Chercher une catégorie"
            />
            <CommandEmpty>Aucun résultat</CommandEmpty>
            <CommandList>
              <CommandGroup>
                {categories &&
                  categories.map((category) => (
                    <CommandItem
                      key={category.id}
                      value={category.valeur}
                      onSelect={() => {
                        setSelectedCategory(category);
                        setCategoryOpen(false);
                      }}
                    >
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          selectedCategory.id === category.id
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {category.valeur.toLowerCase()}
                    </CommandItem>
                  ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default CategorySelect;
