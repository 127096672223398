import axios, { AxiosResponse } from "axios";

import { IServerResponse_ } from "@/interfaces/IRequestResponse";
import { product_ean_generation_url } from "@/constants/urls";

const accessToken = sessionStorage.getItem("token");

export async function generateProductEAN(): Promise<
  AxiosResponse<IServerResponse_<string>>
> {
  try {
    const response: AxiosResponse<IServerResponse_<string>> = await axios({
      url: product_ean_generation_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}
