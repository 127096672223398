import { IOrderDetails } from "@/interfaces/IOrder";
import { formatDateTime } from "@/lib/utils";
import React from "react";

interface OrderDetailsBarProps {
  orderDetails: IOrderDetails;
  onSide?: boolean;
  hasStatus?: boolean;
}

const OrderDetailsBar: React.FC<OrderDetailsBarProps> = ({
  orderDetails,
  hasStatus,
}) => {
  // const totlePrice = orderDetails.totalPrice * 0.8;

  return (
    <div className="flex flex-col sm:flex-row sm:flex-wrap sm:justify-between sm:gap-6 w-full">
      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          Fournisseur
        </span>
        <span className="font-medium">{orderDetails.providerLabel}</span>
      </div>
      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          Commandée le
        </span>
        <span className="font-medium">
          {formatDateTime(orderDetails.orderDate?.toString() || "")}
        </span>
      </div>
      {/*orderDetails.isCompleted && (
        <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
          <span className="text-sm text-gray-500 dark:text-gray-400">
            BDC édité le
          </span>
          <span className="font-medium">
            {formatDateTime(orderDetails.firstDownloadDate?.toString() || "")}
          </span>
        </div>
      )*/}
      {/*
      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          Total (HT)
        </span>
        <span className="font-medium">{totlePrice.toFixed(2)} MAD</span>
      </div>
      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          Total (TTC)
        </span>
        <span className="font-medium">{orderDetails.totalPrice} MAD</span>
      </div>
      */}
      {hasStatus && (
        <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
          <span className="text-sm text-gray-500 dark:text-gray-400">
            Statut
          </span>
          <span className="text-sm text-gray-500 dark:text-gray-400">
            {/*<StatusTag status={orderDetails.isCompleted} forOrder />*/}
          </span>
        </div>
      )}
    </div>
  );
};

export default OrderDetailsBar;
