import axios, { AxiosResponse } from "axios";
import { supplier_products_url } from "@/constants/urls";
import { ISupplierProduct } from "@/interfaces/ISupplier";

const accessToken = sessionStorage.getItem("token");

export async function getSupplierProducts(
  supplierId: number
): Promise<AxiosResponse<ISupplierProduct[]>> {
  try {
    const response: AxiosResponse<ISupplierProduct[]> = await axios({
      url: supplier_products_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        supplierId,
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
