import axios, { AxiosResponse } from "axios";

import { ICategory } from "@/interfaces/ICategory";
import { list_category_url } from "@/constants/urls";

export async function getCategories(
  idParent?: string
): Promise<AxiosResponse<ICategory[]>> {
  const accessToken = sessionStorage.getItem("token");
  try {
    const response: AxiosResponse<ICategory[]> = await axios({
      url: list_category_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        idParent: idParent || "",
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
