import { ORDER_VALIDATE_CONFIRMATION_DIALOG } from "@/constants/constants";
import { closeDialog } from "@/store/reducers/dialogReducer";
import { RootState } from "@/store/store";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeOrderStatus } from "@/lib/utils";
import LoaderButton from "./LoaderButton";
import { setRefreshing } from "@/store/reducers/refreshReducer";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";
import { toast } from "./ui/use-toast";

interface OrderValidateConfirmationDialogProps {
  orderId: number;
}

const OrderValidateConfirmationDialog: React.FC<
  OrderValidateConfirmationDialogProps
> = ({ orderId }) => {
  const dispatch = useDispatch();
  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialogReducer
  );

  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );

  const [isLoading, setIsLoading] = React.useState(false);
  const isOpened = isOpen && dialogType === ORDER_VALIDATE_CONFIRMATION_DIALOG;
  const handleClose = () => {
    dispatch(closeDialog());
  };

  const handleChangeOrderStatus = async () => {
    setIsLoading(true);
    const response = await changeOrderStatus(orderId.toString());
    if (response.status === 200) {
      console.log("Status updated successfully");
      dispatch(setRefreshing(!isRefreshing));
    } else {
      toast({
        title: "Erreur",
        description:
          "Une erreur s'est produite lors de validation de la commande",
        variant: "destructive",
      });
    }
    setIsLoading(false);
    handleClose();
  };

  return (
    <Dialog open={isOpened} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Confirmation</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          Êtes-vous sûr de vouloir valider cette commande ?
        </DialogDescription>
        <DialogFooter>
          <LoaderButton
            name="Valider"
            handleSubmit={handleChangeOrderStatus}
            isLoading={isLoading}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default OrderValidateConfirmationDialog;
