export const baseUrl = "https://mm-retail-prod-pre.azurewebsites.net/"; // change
export const list_produit_url = baseUrl + "/api/products/search";
export const list_rayon_url = baseUrl + "/api/rayons/search";
export const list_seller_url = baseUrl + "/api/suppliers/search";
export const list_suppliers_order_url =
  baseUrl + "/api/suppliers/get-Suppliers";
export const list_order_url = baseUrl + "/api/orders/search";
export const list_delivery_url = baseUrl + "/api/Receptions/search";
export const list_category_url = baseUrl + "/api/category/categorysbyidparent";
export const list_movement_url = baseUrl + "/api/mouvements/search";
export const list_invoice_url = baseUrl + "/api/invoices/search";
export const list_litige_url = baseUrl + "/api/Invoices/search-litige";
export const update_product_url = baseUrl + "/api/products/";
export const list_stock_url = baseUrl + "/api/stocks/search";
export const list_order_without_invoice =
  baseUrl + "/api/orders/list-without-invoice";
export const export_products_url = baseUrl + "/api/products/export";
export const ping_export_products_url = baseUrl + "/api/products/export/file";
export const product_assign_canvas_url =
  baseUrl + "/api/supplierproduct/downloadfile/";
export const batch_assign_url =
  baseUrl + "/api/supplierproduct/batch-assign-product/";
export const product_export_download_url =
  baseUrl + "/api/products/export/file?id=";
export const order_mass_create_url =
  baseUrl + "/api/orders/order-mass-product/";
export const create_rayon_url = baseUrl + "/api/rayons/";
export const delete_rayon_url = baseUrl + "/api/rayons/";
export const update_rayon_url = baseUrl + "/api/rayons/";
export const create_supplier_url = baseUrl + "/api/suppliers/create";
export const supplier_check_url = baseUrl + "/api/Suppliers/get-supplier-exist";
export const update_supplier_url = baseUrl + "/api/suppliers/update";
export const supplier_products_url =
  baseUrl + "/api/supplierproduct/supplier-products";
export const supplier_products_url_ =
  baseUrl + "/api/supplierproduct/supplier-products-order";
export const supplier_details_url =
  baseUrl + "/api/suppliers/supplier-detail?supplierId=";
export const list_product_price_history_url =
  baseUrl + "/api/suppliers/supplier-product-history";
export const create_order_url = baseUrl + "/api/orders/create";
export const list_notifications_url =
  baseUrl + "/api/notification/notification";
export const canva_download_url =
  baseUrl + "/api/canvas/provide-canvas/download";
export const product_assign_url = baseUrl + "/api/suppliers/assign-product";
export const new_product_assign_url =
  baseUrl + "/api/SupplierProduct/assign-product";
export const purchase_order_url = baseUrl + "/api/Orders/downloadpdf/";
export const update_order_url = baseUrl + "/api/orderdetail/createorupdate";
export const delete_order_url = baseUrl + "/api/orders/delete-order/";
export const cancel_order_url = baseUrl + "/api/orders/cancel-order/";
export const order_details_url = baseUrl + "/api/orderdetail/list";
export const update_assigned_product_price =
  baseUrl + "/api/SupplierProduct/update-product-price";
export const order_details_url_get = baseUrl + "/api/orders/get-order/";
export const delete_order_line_url =
  baseUrl + "/api/orderdetail/delete-order-item/";
export const product_details_url = baseUrl + "/api/products/";
export const create_delivery_url = baseUrl + "/api/deliveries/create";
export const delivery_details_url = baseUrl + "/api/deliveries/detail/";
export const delivery_details_url_get = baseUrl + "/api/deliveries/detail?id=";
export const supplier_product_price_item_delete_url =
  baseUrl + "/api/SupplierProduct/delete-supplierProductPrice/";
export const delete_delivery_url =
  baseUrl + "/api/deliveries/delete?deliveryId=";
export const update_delivery_url = baseUrl + "/api/deliveries/update/";
export const create_invoice_url = baseUrl + "/api/invoices/create";
export const upload_invoice_doc_url = baseUrl + "/api/Invoices/upload-pj/";
export const invoice_details_url = baseUrl + "/api/Invoices/detail?id=";
export const update_invoice_url = baseUrl + "/api/invoices/update/";
export const invoice_pab_status_url = baseUrl + "/api/invoices/status";
export const stock_details_url = baseUrl + "/api/Stocks/detail/";
export const invoice_delete_url = baseUrl + "/api/Invoices/detail?id=";
export const invoice_details_url_get = baseUrl + "/api/invoices/detail?id=";
export const delivery_details_by_order_url =
  baseUrl + "/api/deliveries/getbyorderid?orderId=";
export const litige_details_url = baseUrl + "/api/Invoices/detail-litige/";
export const auth_url = baseUrl + "/api/tokens";
export const order_supplier_canvas_url = baseUrl + "/api/orders/downloadfile/";
export const order_changeStatus = baseUrl + "/api/orders/valide-order";
export const draft_order_url =
  baseUrl + "/api/orderdetail/assign-product-order";
export const get_delivery_details = baseUrl + "/api/Receptions/get/";
export const upload_bl_url = baseUrl + "/api/Receptions/upload-bl/";
export const download_brv_url = baseUrl + "/api/Receptions/download-BRV/";
export const order_check_url = baseUrl + "/api/Invoices/get-order";
export const litige_item_invoiced_update_url =
  baseUrl + "/api/Invoices/Update-Item-Invoice-Facture";
export const litige_item_valid_update_url =
  baseUrl + "/api/Invoices/Update-Item-Invoice-Valid";
export const invoice_status_update_url =
  baseUrl + "/api/Invoices/update-statut-invoice";
export const invoice_status_get_url =
  baseUrl + "/api/Invoices/get-statut-invoice/";
export const list_retail_products_url =
  baseUrl + "/api/products/search-product-retail";
export const basic_auth_url = baseUrl + "/api/account/authenticate/With/Mail";
export const list_user_url = baseUrl + "/api/account/List/";
export const create_user_url = baseUrl + "/api/account/Register/";
export const update_user_url = baseUrl + "/api/account/Update?userId=";
export const delete_user_url = baseUrl + "/api/account/delete?userId=";
export const supplier_validation_url = baseUrl + "/api/Suppliers/valid/";
export const supplier_documents_upload_url = baseUrl + "/api/Suppliers/upload/";
export const invoice_items_mass_update_url =
  baseUrl + "/api/Invoices/update-items-invoice-en-masse/";
export const list_user_roles_url = baseUrl + "/api/roles/actions/list";
export const product_ean_generation_url =
  baseUrl + "/api/products/generate/ean";
export const product_create_url = baseUrl + "/api/products/create";
export const list_assign_proofs_url =
  baseUrl + "/api/SupplierProduct/GetSupplierProductAssignmentPriceFile/";
