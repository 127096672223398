import React, { useEffect, useState } from "react";
import { TableCell, TableRow } from "./ui/table";
import { ILitigeItem } from "@/interfaces/ILitige";
import { Check, LoaderCircle, X } from "lucide-react";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import {
  updateLitigeItem,
  updateValidLitigeItem,
} from "@/services/LitigeItemUpdateService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { setRefreshing } from "@/store/reducers/refreshReducer";
import { toast } from "./ui/use-toast";
import {
  checkSaisieDetailDesFacturesLitige,
  checkValidationLitigePrix,
  checkValidationLitigeQte,
} from "@/lib/roleHelper";

interface LitigeItemProps {
  item: ILitigeItem;
  litigeStatus: number;
  setLitigeItems: React.Dispatch<React.SetStateAction<ILitigeItem[]>>;
  litigeItems: ILitigeItem[];
  disabledItems: Set<number>;
  setDisabledItems: React.Dispatch<React.SetStateAction<Set<number>>>;
}
const isFirstCallAdd = { current: true };
const isFirstCallValid = { current: true };

const LitigeItem: React.FC<LitigeItemProps> = ({
  item,
  setDisabledItems,
  litigeStatus,
  setLitigeItems,
  litigeItems,
  disabledItems,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [validDisabled, setValidDisabled] = useState(false);
  const [priceValidDisabled, setPriceValidDisabled] = useState(false);
  const [quantityValidDisabled, setQuantityValidDisabled] = useState(false);

  const dispatch = useDispatch();
  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );

  const toggleDisabled = async (id: number) => {
    const newSet = new Set(disabledItems);
    const litigeItem = litigeItems.find((item) => item.id === id);
    if (newSet.has(id)) {
      newSet.delete(id);
    } else {
      newSet.add(id);

      if (litigeItem) {
        if (litigeItem.statut === 3 || litigeItem.statut === 2) {
          console.log("called valid");
          await handleAddValidLitigeItem(litigeItem);
          if (isFirstCallValid.current) {
            console.log("isFirstCallValid", isFirstCallValid.current);
            dispatch(setRefreshing(!isRefreshing));
            console.log("valid dispatched");
            isFirstCallValid.current = false;
          }
        } else if (litigeItem.statut === 0 || litigeItem.statut === 1) {
          console.log("called add");
          await handleAddLitigeItem(litigeItem);
          if (isFirstCallAdd.current) {
            console.log("isFirstCall", isFirstCallAdd.current);
            dispatch(setRefreshing(!isRefreshing));
            console.log("add dispatched");
            isFirstCallAdd.current = false;
          }
        }
      }
    }
    setDisabledItems(newSet);
  };

  const handleAddLitigeItem = async (litigeItem: ILitigeItem) => {
    setIsLoading(true);
    const response = await updateLitigeItem(
      litigeItem.id,
      litigeItem.quantityFacture,
      litigeItem.unitPriceFacture
    );
    if (response.status === 200) {
      console.log("response", response.data.data);
    } else {
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de la mise à jour",
        variant: "destructive",
      });
    }
    setIsLoading(false);
  };

  const handleAddValidLitigeItem = async (litigeItem: ILitigeItem) => {
    setIsLoading(true);
    const response = await updateValidLitigeItem(
      litigeItem.id,
      litigeItem.quantityValid,
      litigeItem.unitPriceValid
    );
    if (response.status === 200) {
      console.log("response", response.data.data);
    } else {
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de la mise à jour",
        variant: "destructive",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (litigeStatus === 1) {
      if (disabledItems.has(item.id)) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
    if (litigeStatus === 2) {
      if (disabledItems.has(item.id)) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
    if (litigeStatus === 3) {
      setIsDisabled(true);

      if (item.statut === 5) {
        setValidDisabled(true);
      }
      if (item.statut === 2 && disabledItems.has(item.id)) {
        setValidDisabled(true);
      }
      if (item.statut === 2 && !disabledItems.has(item.id)) {
        setValidDisabled(false);
      }
    }
    if (litigeStatus === 4) {
      setIsDisabled(true);
      if (item.statut === 5) {
        setValidDisabled(true);
      }
      if (
        item.statut === 2 ||
        (item.statut === 3 && disabledItems.has(item.id))
      ) {
        setValidDisabled(true);
      }
      if (
        item.statut === 2 ||
        (item.statut === 3 && !disabledItems.has(item.id))
      ) {
        setValidDisabled(false);
      }
    }
    if (litigeStatus === 5) {
      setIsDisabled(true);
      setValidDisabled(true);
    }
  }, [litigeStatus, item.statut, disabledItems]);

  useEffect(() => {
    if (litigeStatus === 3 || litigeStatus === 4 || litigeStatus === 2) {
      if (checkValidationLitigeQte()) {
        setQuantityValidDisabled(false);
      } else {
        setQuantityValidDisabled(true);
      }
      if (checkValidationLitigePrix()) {
        setPriceValidDisabled(false);
      } else {
        setPriceValidDisabled(true);
      }
    } else if (litigeStatus === 1 || litigeStatus === 2) {
      if (checkSaisieDetailDesFacturesLitige()) {
        setQuantityValidDisabled(false);
        setPriceValidDisabled(false);
      } else {
        setQuantityValidDisabled(true);
        setPriceValidDisabled(true);
      }
    }
  }, [litigeStatus]);

  const handleShowActions = () => {
    if (litigeStatus === 1 || litigeStatus === 2) {
      if (checkSaisieDetailDesFacturesLitige()) {
        return true;
      } else {
        return false;
      }
    } else if (litigeStatus === 3 || litigeStatus === 4) {
      if (checkValidationLitigeQte() || checkValidationLitigePrix()) {
        return true;
      } else {
        return false;
      }
    }
  };

  const shouldRenderItem = () => {
    if (litigeStatus === 3 || litigeStatus === 4) {
      return item.statut !== 5;
    }
    return true;
  };

  if (!shouldRenderItem()) {
    return null;
  }

  return (
    <TableRow
      className={`
        ${
          (item.statut === 2 &&
            litigeStatus === 3 &&
            !disabledItems.has(item.id)) ||
          (item.statut === 3 &&
            litigeStatus === 4 &&
            !disabledItems.has(item.id)) ||
          ((item.statut === 3 || item.statut === 4) && litigeStatus === 5)
            ? "bg-red-50 hover:bg-red-50"
            : ""
        }
      `}
    >
      <TableCell className="sm:table-cell">{item.gtin}</TableCell>
      <TableCell className="sm:table-cell">{item.productLabel}</TableCell>
      {(litigeStatus === 1 || litigeStatus === 2) && (
        <TableCell className="sm:table-cell text-center">
          {item.quantityCommand}
        </TableCell>
      )}
      <TableCell className="|sm:table-cell text-center">
        {item.quantityReceiver}
      </TableCell>
      <TableCell className="sm:table-cell text-center">
        {item.unitPriceCommand}
      </TableCell>
      <TableCell className="sm:table-cell text-center">
        <Input
          type="number"
          value={item.quantityFacture}
          disabled={isDisabled}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            const newItems = litigeItems.map((i) => {
              if (i.id === item.id) {
                return { ...i, quantityFacture: value };
              }
              return i;
            });
            setLitigeItems(newItems);
          }}
        />
      </TableCell>
      <TableCell className="sm:table-cell text-center">
        <Input
          type="number"
          value={item.unitPriceFacture}
          disabled={isDisabled}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            const newItems = litigeItems.map((i) => {
              if (i.id === item.id) {
                return { ...i, unitPriceFacture: value };
              }
              return i;
            });
            setLitigeItems(newItems);
          }}
        />
      </TableCell>
      {(litigeStatus === 4 || litigeStatus === 3 || litigeStatus === 5) && (
        <>
          <TableCell className="sm:table-cell text-center">
            <Input
              type="number"
              value={item.quantityValid}
              disabled={quantityValidDisabled || validDisabled}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                const newItems = litigeItems.map((i) => {
                  if (i.id === item.id) {
                    return { ...i, quantityValid: value };
                  }
                  return i;
                });
                setLitigeItems(newItems);
              }}
            />
          </TableCell>
          <TableCell className="sm:table-cell text-center">
            <Input
              type="number"
              value={item.unitPriceValid}
              disabled={priceValidDisabled || validDisabled}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                const newItems = litigeItems.map((i) => {
                  if (i.id === item.id) {
                    return { ...i, unitPriceValid: value };
                  }
                  return i;
                });
                setLitigeItems(newItems);
              }}
            />
          </TableCell>
        </>
      )}
      {handleShowActions() && (
        <TableCell className="sm:table-cell text-center">
          <Button
            variant="outline"
            size="sm"
            disabled={item.statut === 5 || litigeStatus === 5 || isLoading}
            onClick={() => toggleDisabled(item.id)}
          >
            {isLoading ? (
              <LoaderCircle size={16} className="animate-spin" />
            ) : (
              <>
                {litigeStatus <= 2 &&
                  (isDisabled === true ? (
                    <X size={16} color="red" />
                  ) : (
                    <Check size={16} color="green" />
                  ))}

                {litigeStatus > 2 &&
                  (validDisabled === true ? (
                    <X size={16} color="red" />
                  ) : (
                    <Check size={16} color="green" />
                  ))}
              </>
            )}
          </Button>
        </TableCell>
      )}
    </TableRow>
  );
};

export default LitigeItem;
