import OrderDetailsDialog from "@/components/OrderDetailsDialog";
import OrderTable from "@/components/OrdersTable";
import TablePagination from "@/components/TablePagination";
import TableSkeleton from "@/components/TableSkeleton";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Toaster } from "@/components/ui/toaster";
import { toast } from "@/components/ui/use-toast";
import UtilityBar from "@/components/UtilityBar";
import { IOrder } from "@/interfaces/IOrder";
import { IPageData } from "@/interfaces/IPageData";
import { ISupplier } from "@/interfaces/ISupplier";
import { checkCreationDesCommandes } from "@/lib/roleHelper";
import { getOrders } from "@/services/OrderLisrService";
import { getSuppliers } from "@/services/SupplierListService";
import { RootState } from "@/store/store";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const OrderPage: React.FC = () => {
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [pageData, setPageData] = useState<IPageData>();
  const currentPage = useSelector(
    (state: RootState) => state.pageReducer.pageNumber
  );
  const searchKeyword = useSelector(
    (state: RootState) => state.searchReduer.searchText
  );
  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );
  const [isLoading, setIsLoading] = useState(false);
  const [suppliers, setSuppliers] = useState<ISupplier[]>([] as ISupplier[]);
  useEffect(() => {
    setIsLoading(true);
    fetchOrders();
    fetchSuppliers();
    console.log("Orders fetched", orders);
  }, [currentPage, searchKeyword, isRefreshing]);

  const fetchOrders = async () => {
    const response = await getOrders(searchKeyword, currentPage);
    if (response.status === 200) {
      setOrders(response.data.data as IOrder[]);
      setPageData({
        pageSize: response.data.pageSize,
        hasNextPage: response.data.hasNextPage,
        hasPreviousPage: response.data.hasPreviousPage,
        currentPage: response.data.currentPage,
        totalCount: response.data.totalCount,
        totalPages: response.data.totalPages,
      });
    } else {
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors du téléchargement",
        variant: "destructive",
      });
      setIsLoading(false);
      setOrders([]);
    }
  };

  const fetchSuppliers = async () => {
    const response = await getSuppliers(searchKeyword);
    if (response.status === 200) {
      setSuppliers(response.data.data);
    } else {
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors du téléchargement",
        variant: "destructive",
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="flex-1 px-12 py-3">
      <h1 className="text-2xl font-bold mb-4">Commandes</h1>
      <UtilityBar hasCreateButton={checkCreationDesCommandes()} />
      {isLoading ? (
        <TableSkeleton rows={10} cols={6} />
      ) : (
        <>
          <ScrollArea className="h-[60vh]">
            <OrderTable orders={orders} suppliers={suppliers} />
          </ScrollArea>
          {pageData && <TablePagination pageData={pageData} />}
        </>
      )}
      <OrderDetailsDialog />
      <Toaster />
    </div>
  );
};

export default OrderPage;
