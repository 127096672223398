import { DOWNLOAD_CONFIRMATION_DIALOG } from "@/constants/constants";
import { closeDialog } from "@/store/reducers/dialogReducer";
import { RootState } from "@/store/store";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import LoaderButton from "./LoaderButton";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";
import { DownloadFile___ } from "@/lib/utils";
import { purchase_order_url } from "@/constants/urls";

interface OrderValidateConfirmationDialogProps {
  orderId: number;
  orderNumber: string;
}

const OrderValidateConfirmationDialog: React.FC<
  OrderValidateConfirmationDialogProps
> = ({ orderId, orderNumber }) => {
  const dispatch = useDispatch();
  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialogReducer
  );

  const [isLoading, setIsLoading] = React.useState(false);
  const isOpened = isOpen && dialogType === DOWNLOAD_CONFIRMATION_DIALOG;
  const handleClose = () => {
    dispatch(closeDialog());
  };

  const handleDownloadPurchaseOrder = async () => {
    setIsLoading(true);
    const response = DownloadFile___(
      purchase_order_url + orderId,
      "BDC -" + orderNumber + ""
    );

    if ((await response).status === 200) {
      console.log("File downloaded successfully");
    }
    setIsLoading(false);
    handleClose();
  };
  return (
    <Dialog open={isOpened} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Confirmation</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          Êtes-vous sûr de vouloir télécharger ce bon de commande ?
        </DialogDescription>
        <DialogFooter>
          <LoaderButton
            name="Télécharger"
            handleSubmit={handleDownloadPurchaseOrder}
            isLoading={isLoading}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default OrderValidateConfirmationDialog;
