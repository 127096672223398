import { ILitigeDetails } from "@/interfaces/ILitige";
import { formatDateTime } from "@/lib/utils";
import React from "react";

interface LitigeDetailsBarProps {
  litigeDetails: ILitigeDetails;
}

const LitigeDetailsBar: React.FC<LitigeDetailsBarProps> = ({
  litigeDetails,
}) => {
  return (
    <div className="flex flex-col sm:flex-row sm:flex-wrap sm:justify-between sm:gap-6 w-full">
      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          Facture N°
        </span>
        <span className="font-medium">{litigeDetails.invoiceNumber}</span>
      </div>
      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          Commande N°
        </span>
        <span className="font-medium">{litigeDetails.orderNumber}</span>
      </div>
      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          Réception N°
        </span>
        <span className="font-medium">{litigeDetails.cdaNumber}</span>
      </div>
      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          Fournisseur
        </span>
        <span className="font-medium">{litigeDetails.supplierLabel}</span>
      </div>
      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          Commandé le
        </span>
        <span className="font-medium">
          {formatDateTime(litigeDetails.orderDate)}
        </span>
      </div>
      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          Réceptionné le
        </span>
        <span className="font-medium">
          {formatDateTime(litigeDetails.receptionDate)}
        </span>
      </div>

      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          Montant Facture (HT)
        </span>
        <span className="font-medium">{litigeDetails.amountHT} MAD </span>
      </div>
      <div className="flex flex-col items-start mb-4 sm:mb-0 space-y-1">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          Montant BRV (HT)
        </span>
        <span className="font-medium">{litigeDetails.amountHtBRV} MAD </span>
      </div>
    </div>
  );
};

export default LitigeDetailsBar;
