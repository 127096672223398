import axios, { AxiosResponse } from "axios";
import { list_product_price_history_url } from "@/constants/urls";
import { IPriceHistoryResponse } from "@/interfaces/IProduct";

const accessToken = sessionStorage.getItem("token");

export async function getProductPriceHistory(
  pageNumber: number,
  providerId: number,
  productId: string,
  pageSize?: number
): Promise<IPriceHistoryResponse> {
  try {
    const response: AxiosResponse<IPriceHistoryResponse> = await axios({
      url: list_product_price_history_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        pageNumber: pageNumber,
        pageSize: pageSize || 10,
        keyword: "",
        providerId: providerId,
        productId: productId,
      },
      method: "POST",
    });
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
}
