import axios, { AxiosResponse } from "axios";
import {
  export_products_url,
  ping_export_products_url,
} from "@/constants/urls";
import { IFilter } from "@/interfaces/ICategory";
import {
  IServerResponse_,
  ISuccessResponse,
} from "@/interfaces/IRequestResponse";

const accessToken = sessionStorage.getItem("token");

export async function exportProducts(
  filter: IFilter,
  supplierId: number | null
): Promise<AxiosResponse<ISuccessResponse>> {
  try {
    const response: AxiosResponse<ISuccessResponse> = await axios({
      url: export_products_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        idNiveau1: filter.category1.id,
        idNiveau2: filter.category2.id,
        idNiveau3: filter.category3.id,
        supplierId: supplierId,
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}

export async function pingExportProducts(): Promise<
  AxiosResponse<IServerResponse_<string>>
> {
  try {
    const response: AxiosResponse<IServerResponse_<string>> = await axios({
      url: ping_export_products_url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
}
