"use client";

import React, { useEffect } from "react";
import { Label } from "./ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { CalendarDaysIcon } from "lucide-react";
import { Button } from "./ui/button";
import { Calendar } from "./ui/calendar";

interface DateSelectProps {
  selectedDate?: Date;
  setSelectedDate?: (value: Date) => void;
  disabledDates?: (date: Date) => boolean;
  label: string;
  disabled?: boolean;
  gridLayout?: boolean;
}

export default function DateSelect({
  selectedDate,
  setSelectedDate,
  disabledDates,
  label,
  disabled,
  gridLayout,
}: DateSelectProps) {
  const [calendarOpen, setCalendarOpen] = React.useState(false);
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  useEffect(() => {}, [selectedDate]);

  return (
    <div
      className={`${
        !gridLayout
          ? "flex flex-col space-y-2 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-4"
          : "grid grid-cols-4 items-center gap-4"
      }`}
    >
      <Label
        className={`${gridLayout ? "" : "w-full"}text-right`}
        htmlFor="date-select"
      >
        {label}
      </Label>
      <div className={`${gridLayout ? "col-span-3" : "w-full"}`}>
        <Popover open={calendarOpen} onOpenChange={setCalendarOpen}>
          <PopoverTrigger asChild>
            <Button
              id="date-select"
              className="w-full justify-start text-left font-normal"
              variant="outline"
              disabled={disabled}
            >
              <CalendarDaysIcon className="hidden sm:inline-block mr-2 h-4 w-4" />
              <span className="font-medium">
                {selectedDate &&
                selectedDate instanceof Date &&
                !isNaN(selectedDate.getTime())
                  ? selectedDate.toLocaleDateString("fr-FR", options)
                  : "Choisir une date"}
              </span>
            </Button>
          </PopoverTrigger>
          <PopoverContent align="start" className="w-auto p-0">
            <Calendar
              initialFocus
              mode="single"
              selected={selectedDate}
              onSelect={(value) => {
                setSelectedDate && setSelectedDate(value as Date);
                setCalendarOpen(false);
              }}
              disabled={disabledDates}
            />
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
}
