import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { Table } from "./ui/table";
import {
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "./ui/table";
import { Separator } from "./ui/Separator";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { ORDER_DETAILS_DIALOG } from "@/constants/constants";
import { closeDialog } from "@/store/reducers/dialogReducer";
import StatusTag from "./StatusTag";
import { formateDate } from "@/lib/utils";
import { clearOrder } from "@/store/reducers/orderReducer";

interface OrderDetailsDialogProps {}

const OrderDetailsDialog: React.FC<OrderDetailsDialogProps> = () => {
  const dispatch = useDispatch();
  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialogReducer
  );
  const isOpened = isOpen && dialogType === ORDER_DETAILS_DIALOG;
  const order = useSelector((state: RootState) => state.orderReducer.order);
  const supplier = useSelector(
    (state: RootState) => state.supplierReducer.supplier
  );

  const handleClose = () => {
    dispatch(closeDialog());
    dispatch(clearOrder());
  };

  return (
    <Dialog open={isOpened} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Details Commmade</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 p-4 sm:p-6">
          <div className="grid grid-cols-2 gap-2 sm:grid-cols-4 sm:gap-4">
            <div className="grid gap-1">
              <span className="text-sm text-gray-500 dark:text-gray-400">
                Commande #
              </span>
              <span className="font-medium">{order.orderNumber}</span>
            </div>
            <div className="grid gap-1">
              <span className="text-sm text-gray-500 dark:text-gray-400">
                Total
              </span>
              <span className="font-medium">{order.totalPrice}</span>
            </div>
            <div className="grid gap-1">
              <span className="text-sm text-gray-500 dark:text-gray-400">
                Statut
              </span>
              <StatusTag status={order.isCompleted} forOrder={true} />
            </div>
            <div className="grid gap-1">
              <span className="text-sm text-gray-500 dark:text-gray-400">
                Créé le
              </span>
              <span className="font-medium">
                {formateDate(new Date(order.creationDate))}
              </span>
            </div>
            <div className="grid gap-1 sm:order-first">
              <span className="text-sm text-gray-500 dark:text-gray-400">
                Fournisseur
              </span>
              <span className="font-medium">{supplier.label}</span>
            </div>
          </div>
          <Separator />
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Produit</TableHead>
                <TableHead>Quantité</TableHead>
                <TableHead>Prix</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {order?.orderItems?.map((item) => (
                <TableRow>
                  <TableCell className="font-medium">
                    {item.productId}
                  </TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell>{item.unitPrice}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default OrderDetailsDialog;
