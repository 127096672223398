import { Button } from "@/components/ui/button";

import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "@/utils/authConfig";

const LoginPage: React.FC = () => {
  const { instance } = useMsal();

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };
  return (
    <div className="flex min-h-[100dvh] items-center justify-center bg-gradient-to-br from-[#2C5F5C] to-[#7DCBC8] flex-col">
      <div className="w-full max-w-md rounded-lg bg-white p-8 shadow-lg dark:bg-gray-950 gap-4">
        <div className="space-y-4">
          <h1 className="text-3xl font-bold text-center text-gray-800 dark:text-white">
            Login
          </h1>
          <p className="text-center text-gray-600 dark:text-gray-300">
            Please login to continue
          </p>

          <div>
            <Button
              type="submit"
              className="w-full bg-[#2C5F5C] text-white hover:bg-[#23504F] gap-2"
              onClick={handleLoginRedirect}
            >
              <img
                src="https://img.icons8.com/fluent/48/000000/microsoft.png"
                className="h-6 w-6 inline-block"
                alt="Microsoft Logo"
              />
              Login with Microsoft
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
