import DeliveryItemsEditTable from "@/components/DeliveryItemEditTable";
import OrderDeleteDialog from "@/components/DeleteConfirmationDialog";
import OrderDetailsSkeleton from "@/components/OrderDetailsSkeleton";
import { Toaster } from "@/components/ui/toaster";
import {
  IDeliveryDetails,
  IDeliveryDetailsRequest,
  IDeliveryItemDetails,
} from "@/interfaces/IDelivery";
import { getDeliveryDetails } from "@/services/DeliveryDetailsService";
import { RootState } from "@/store/store";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ItemsTable from "@/components/ItemsTable";

import ActionBar from "@/components/ActionBar";
import {
  DELETE_ORDER_DIALOG,
  DELIVERY_NOTE_UPLOAD_DIALOG,
  // DOWNLOAD_CONFIRMATION_DIALOG,
  INVOICE_CREATE_DIALOG,
} from "@/constants/constants";
import { openDialog } from "@/store/reducers/dialogReducer";
import DeliveryDetailsBar from "@/components/DeliveryDetailsBar";
import { IOrderItemDetails } from "@/interfaces/IOrder";
import PriceDetailsBar from "@/components/PriceDetailsBar";
import InvoiceCreateDialog from "@/components/InvoiceCreateDialog";
import DeliveryNoteUploadDialog from "@/components/DeliveryNoteUploadDialog";
import DownloadConfirmationDialog from "@/components/OrderValidateConfirmationDialog";
import { downloadBrv } from "@/services/DeliveryDetailsService";
import { toast } from "@/components/ui/use-toast";
import { handleDownload as handledownload_ } from "@/lib/utils";
import { IOrderDetailsDTO } from "@/interfaces/IInvoice";
import { checkImportDesBonsDeLivraisonCachetes } from "@/lib/roleHelper";
import { setRefreshing } from "@/store/reducers/refreshReducer";

const DeliveryDetailsPage: React.FC = () => {
  const { id, orderId } = useParams();
  const dispatch = useDispatch();
  const [deliveryFiles, setDeliveryFiles] = React.useState<File[]>([]);
  const [deliveryDetails, setDeliveryDetails] =
    React.useState<IDeliveryDetails>({} as IDeliveryDetails);

  const [checked] = React.useState(false);
  const [deliveredItems, setDeliveredItems] = React.useState<
    IOrderItemDetails[]
  >([] as IOrderItemDetails[]);
  const [orderDetails, setOrderDetails] = React.useState<IOrderDetailsDTO[]>(
    {} as IOrderDetailsDTO[]
  );
  const [selectedProducts, setSelectedProducts] = React.useState<
    IDeliveryItemDetails[]
  >([] as IDeliveryItemDetails[]);

  const [isLoading, setIsLoading] = React.useState(false);

  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );

  const fetchDeliveryDetails = async () => {
    const payload: IDeliveryDetailsRequest = {
      keyword: "",
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
    };
    if (id) {
      const response = await getDeliveryDetails(id, payload);
      if (response.status === 200) {
        setDeliveryDetails(response.data.data);
        setOrderDetails(response.data.data.orderDetailDto);
        setDeliveredItems(response.data.data.orderDetailDto);
      }
    } else {
      toast({
        title: "Erreur",
        description:
          "Une erreur s'est produite lors de la récupération des détails de la réception",
        variant: "destructive",
      });
    }
    setIsLoading(false);
  };

  /*   const handleEdit = () => {
    if (checked === true && deliveryDetails.id) {
      setChecked(false);
      setSelectedProducts(deliveredItems);
    } else {
      setChecked(true);
      setSelectedProducts(baseProducts);
    }
  }; */

  const handleDelete = () => {
    dispatch(openDialog(DELETE_ORDER_DIALOG));
  };

  const handleCreateInvoice = () => {
    dispatch(openDialog(INVOICE_CREATE_DIALOG));
  };

  const handleImportBL = () => {
    dispatch(openDialog(DELIVERY_NOTE_UPLOAD_DIALOG));
  };

  const handleDownloadBRV = async () => {
    try {
      // dispatch(openDialog(DOWNLOAD_CONFIRMATION_DIALOG));
      const response = await downloadBrv(id as string);

      if (response.data.success === true) {
        // TODO: type.. this file

        handledownload_(response.data.data, "BRV.pdf");
        toast({
          title: "Succès",
          description: response.data.message,
          variant: "success",
        });
      }

      dispatch(setRefreshing(!isRefreshing));
    } catch (error) {
      toast({
        title: "Erreur",
        description: "Erreur lors de l'importation du BL",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchDeliveryDetails();
  }, [isRefreshing]);

  return (
    <div className="flex-1 px-12 py-3">
      {isLoading ? (
        <OrderDetailsSkeleton />
      ) : (
        <div className="grid gap-6 md:gap-8">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold">
              Réception N°{deliveryDetails.receptionNumber}
            </h1>
            <ActionBar
              checked={checked}
              //handleEdit={handleEdit}
              // hasDelete
              handleDelete={handleDelete}
              hasDownload
              handleDownload={handleDownloadBRV}
              // hasInvoice
              handleInvoice={handleCreateInvoice}
              handleUpload={handleImportBL}
              hasUpload={checkImportDesBonsDeLivraisonCachetes()}
              hasUrl={deliveryDetails.pathBl}
              hasUrlBrv={deliveryDetails.pathBrv}
            />
          </div>
          <DeliveryDetailsBar deliveryDetails={deliveryDetails} />
          <div className="h-4">
            <h1 className="text-xl font-bold">Produits reçus</h1>
          </div>
          {checked ? (
            <DeliveryItemsEditTable
              orderId={parseInt(orderId as string)}
              receptionOrderId={parseInt(id as string)}
              edit
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              //handleEdit={handleEdit}
              orderDetails={orderDetails}
            />
          ) : (
            <>
              <ItemsTable
                items={deliveredItems}
                delivery
                orderDetails={orderDetails}
              />
              <div className="flex flex-1 justify-end">
                <div className="w-1/3">
                  <PriceDetailsBar totalPrice={deliveryDetails.totalPriceHt} />
                </div>
              </div>
            </>
          )}
        </div>
      )}
      <OrderDeleteDialog
        orderId={parseInt(orderId as string)}
        forDelivery
        deliveryId={parseInt(id as string)}
      />
      <InvoiceCreateDialog />
      <DeliveryNoteUploadDialog
        deliveryFiles={deliveryFiles}
        setDeliveryFiles={setDeliveryFiles}
      />
      <DownloadConfirmationDialog orderId={1} />
      <Toaster />
    </div>
  );
};

export default DeliveryDetailsPage;
