import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { Table } from "./ui/table";
import { TableHeader, TableRow, TableHead, TableBody } from "./ui/table";
import { IPriceHistoryItem, IProduct } from "@/interfaces/IProduct";
import { getProductPriceHistory } from "@/services/ProductPriceHistoryService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { IPageData } from "@/interfaces/IPageData";
import TablePagination from "./TablePagination";

import { PRODUCT_PRICE_HISTORY_DIALOG } from "@/constants/constants.ts";
import { closeDialog } from "@/store/reducers/dialogReducer.ts";
import { ISupplier } from "@/interfaces/ISupplier.ts";
import TableSkeleton from "./TableSkeleton.tsx";
import ProductPriceHistoryItem from "./ProductPriceHistoryItem.tsx";
import { sortPriceHistoryItems } from "@/lib/utils.ts";

interface ProductPriceHistoryDialogProps {
  supplier: ISupplier;
  product?: IProduct;
  setPriceHistoryItems_?: (items: IPriceHistoryItem[]) => void;
}

const ProductPriceHistoryDialog: React.FC<ProductPriceHistoryDialogProps> = ({
  supplier,
  product,
  setPriceHistoryItems_,
}) => {
  const dispatch = useDispatch();
  const [priceHistoryItems, setPriceHistoryItems] = React.useState<
    IPriceHistoryItem[]
  >([] as IPriceHistoryItem[]);
  const [pageData, setPageData] = useState<IPageData>({} as IPageData);
  const currentPage = useSelector(
    (state: RootState) => state.pageReducer.pageNumber
  );

  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialogReducer
  );
  const isOpened = isOpen && dialogType === PRODUCT_PRICE_HISTORY_DIALOG;
  const supplierProduct = useSelector(
    (state: RootState) => state.supplierProductReducer.product
  );
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    dispatch(closeDialog());
  };

  useEffect(() => {
    if (!isOpened) {
      return;
    }
    if (!product) {
      fetchProductPriceHistory_();
    }
  }, [currentPage, , isOpened]);

  useEffect(() => {
    fetchProductPriceHistory();
  }, [product]);
  const fetchProductPriceHistory = async () => {
    console.log("fetching product price history", supplierProduct);
    setLoading(true);
    if (product?.productId != undefined && supplierProduct != undefined) {
      console.log("provider id", supplier, "product id", product);
      const data = await getProductPriceHistory(
        currentPage,
        supplier.id,
        product?.productId ?? supplierProduct?.productId,
        1000
      );
      if (data && data.data) {
        const sortedItems = sortPriceHistoryItems(data.data);

        if (setPriceHistoryItems_) {
          setPriceHistoryItems_(sortedItems);
        }

        // Set all sorted items for the local state
        setPriceHistoryItems(sortedItems);

        setPageData({
          pageSize: data.pageSize,
          hasNextPage: data.hasNextPage,
          hasPreviousPage: data.hasPreviousPage,
          currentPage: data.currentPage,
          totalCount: data.totalCount,
          totalPages: data.totalPages,
        });
      }
    }
    setLoading(false);
  };

  const fetchProductPriceHistory_ = async () => {
    setLoading(true);
    try {
      const data = await getProductPriceHistory(
        currentPage,
        supplier.id,
        supplierProduct?.productId
      );
      if (data) {
        const sortedItems = sortPriceHistoryItems(data.data);

        setPriceHistoryItems(sortedItems);
        setPageData({
          pageSize: data.pageSize,
          hasNextPage: data.hasNextPage,
          hasPreviousPage: data.hasPreviousPage,
          currentPage: data.currentPage,
          totalCount: data.totalCount,
          totalPages: data.totalPages,
        });
      }
    } catch (error) {
      console.error("Error fetching product price history:", error);
      // You might want to set an error state here or show a toast notification
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={isOpened} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[800px] max-h-[calc(100vh_-_theme(spacing.16))] overflow-auto">
        <DialogHeader>
          <DialogTitle>Historique des prix</DialogTitle>
        </DialogHeader>
        <div className="relative w-full overflow-auto">
          {loading ? (
            <TableSkeleton rows={10} cols={4} />
          ) : (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>SKU</TableHead>
                  <TableHead>Prix d'achat</TableHead>
                  <TableHead className="text-center">Date début</TableHead>
                  <TableHead className="text-center">Date fin</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {priceHistoryItems &&
                  priceHistoryItems.map((item) => (
                    <ProductPriceHistoryItem
                      key={item.supplierProductPriceId}
                      productHistoryItem={item}
                      activePermamentPrice={
                        item.isPermanent && item.isActivated
                      }
                    />
                  ))}
              </TableBody>
            </Table>
          )}
        </div>
        {pageData && <TablePagination pageData={pageData} />}
      </DialogContent>
    </Dialog>
  );
};

export default ProductPriceHistoryDialog;
